import React, { useState, useEffect } from "react";
import Grid from '@mui/material/Grid2';
import { SwipeableDrawer, Typography, TextField, IconButton, Button, Box, FormControlLabel, Checkbox, InputAdornment } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { TenantModel } from "../../models/Tenant/Tenant";
import { TenantService } from "../../services/api";
import Divider from '@mui/material/Divider';
import { useTranslation } from "react-i18next";
import SessionExpire from "../Common/SessionExpire";
import { Visibility, VisibilityOff } from "@mui/icons-material";




interface TenantDetailProps {
    open: boolean;
    onClose: () => void;
    type: string;
    myTenant: TenantModel | null;
    allTenant: string[];
}

const TenantDetail: React.FC<TenantDetailProps> = ({ open, onClose, myTenant, type, allTenant }) => {

    const [tenant, setTenant] = useState<TenantModel | null>(null);
    const [name, setName] = useState<string>("");
    const [domain, setDomain] = useState<string>("");
    const [adminEmail, setAdminEmail] = useState<string>("");
    const [adminPassword, setAdminPassword] = useState<string>("");
    const [adminName, setAdminName] = useState<string>("");
    const [adminSurname, setAdminSurname] = useState<string>("");
    const [phone, setPhone] = useState<string>("");
    const [street, setStreet] = useState<string>("");
    const [city, setCity] = useState<string>("");
    const [state, setState] = useState<string>("");
    const [zipCode, setZipcode] = useState<string>("");
    const [country, setCounty] = useState<string>("");
    const [isActive, setIsActive] = useState<boolean>(true);
    const [tenantExist, setTenantExist] = useState<boolean>(false);
    const { t } = useTranslation('global');
    const [session, setSession] = useState<boolean>(false);
    const [company, setCompany] = useState<string>("");
    const [showPassword, setShowPassword] = useState<boolean>(false);


    useEffect(() => {
        setTenant(myTenant);
        setName(myTenant?.name || "");
        setDomain(myTenant?.domain || "");
        setAdminEmail(myTenant?.adminEmail || "");
        setPhone(myTenant?.phone || "");
        setStreet(myTenant?.address.street || "");
        setCity(myTenant?.address.city || "");
        setZipcode(myTenant?.address.zipCode || "");
        setCounty(myTenant?.address.country || "");
        setState(myTenant?.address.state || "");
        setIsActive(myTenant?.isActive ?? false);
        setCompany(myTenant?.company || "");

    }, [myTenant, type]);

    const createTenant = async () => {
        if (type === "Create") {
            const newTenant = {
                name: name,
                domain: domain,
                adminEmail: adminEmail,
                adminPassword: adminPassword,
                adminName: adminName,
                adminSurname: adminSurname,
                phone: phone,
                company: company,
                address: {
                    street: street,
                    city: city,
                    state: state,
                    zipCode: zipCode,
                    country: country
                },
                isActive: true
            };
            try {
                const response = await TenantService.create(newTenant);
                console.log("my tenant response -> ", response);
                onClose();
                clearFields();
            } catch (error) {
                console.log(error);
                setSession(true);
            }

        } else {
            const updatedTenant = { ...tenant };

            updatedTenant.name = name;
            updatedTenant.domain = domain;
            updatedTenant.adminEmail = adminEmail;
            updatedTenant.phone = phone;
            updatedTenant.company = company;
            updatedTenant.address = {
                street: street,
                city: city,
                state: state,
                zipCode: zipCode,
                country: country
            };
            updatedTenant.isActive = isActive
            if (tenant?._id) {
                try {
                    const response = await TenantService.update(tenant?._id, updatedTenant);
                    console.log("tenant update response -> ", response);
                    onClose();
                    clearFields();
                } catch (error) {
                    console.log(error);
                    setSession(true);
                }

            } else {
                console.log("tenant update error");
            }

        }
    };

    const clearFields = () => {
        setName("");
        setDomain("");
        setAdminEmail("");
        setPhone("");
        setStreet("");
        setCity("");
        setState("");
        setZipcode("");
        setCounty("");
        setIsActive(true);
        setTenantExist(false);
        setCompany("");
        setAdminName("");
        setAdminPassword("");
        setAdminSurname("");

    };

    const handleFieldChange = (fieldName: string, newValue: string) => {
        const setters: Record<string, (value: string) => void> = {
            name: setName,
            domain: setDomain,
            adminEmail: setAdminEmail,
            phone: setPhone,
            street: setStreet,
            country: setCounty,
            city: setCity,
            state: setState,
            zipCode: setZipcode,
            company: setCompany,
            adminPassword: setAdminPassword,
            adminName: setAdminName,
            adminSurname: setAdminSurname,
        };

        if (fieldName === "name" && allTenant.includes(newValue)) {
            setTenantExist(true);
        } else {
            setTenantExist(false)
        }

        const setter = setters[fieldName];
        if (setter) {
            setter(newValue);
        }
    };


    const handleCheckBoxChange = (value: string) => {
        if (value === "isActive") {
            setIsActive(!isActive);
        }
    };

    const handleSessionCheck = () => {
        setSession(false);
        window.location.href = '/login';
    };


    return (
        <SwipeableDrawer
            anchor="right"
            open={open}
            onClose={() => {
                clearFields();
                onClose();
            }}
            onOpen={() => { }}
            PaperProps={{
                style: {
                    width: "60vh",
                    padding: 20,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    overflowY: "auto",
                },
                sx: {
                    "&::-webkit-scrollbar": {
                        width: "8px",
                    },
                    "&::-webkit-scrollbar-track": {
                        backgroundColor: "#f0f0f0",
                    },
                    "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "#c0c0c0",
                        borderRadius: "4px",
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                        backgroundColor: "#a0a0a0",
                    },
                },
            }}
        >
            <div>
                <SessionExpire open={session} onClose={handleSessionCheck} />
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 15 }}>
                    <Typography variant="h5" sx={{ color: "#0b2343" }}>
                        {type === "Create" ? t('tenant.create_tenant') : t('tenant.edit_tenant')}
                    </Typography>
                    <IconButton onClick={() => {
                        clearFields();
                        onClose();
                    }}>
                        <CloseIcon />
                    </IconButton>
                </div>

                <div>
                    <Typography variant="body1" sx={{ color: "#0b2343" }}>
                        {t('tenant.tenant_details')}
                    </Typography>
                </div>

                <Grid container spacing={3} justifyContent="center" sx={{ marginTop: "5%" }}>
                    <Grid size={6}>
                        <TextField
                            label={t('tenant.name') + "*"}
                            value={name || ""}
                            fullWidth
                            onChange={(e) => { handleFieldChange("name", e.target.value) }}
                            error={tenantExist}
                            helperText={tenantExist ? "This is name used." : null}
                        />
                    </Grid>

                    <Grid size={6}>
                        <TextField
                            label={t('tenant.domain') + "*"}
                            value={domain || ""}
                            fullWidth
                            onChange={(e) => { handleFieldChange("domain", e.target.value) }}
                        />
                    </Grid>

                    {type === "Create" ? (
                        <Grid size={6}>
                            <TextField
                                label={t('tenant.admin_email') + "*"}
                                value={adminEmail || ""}
                                fullWidth
                                onChange={(e) => { handleFieldChange("adminEmail", e.target.value) }}
                            />
                        </Grid>
                    ) : null}

                    {type === "Create" ? (
                        <Grid size={6}>
                            <TextField
                                type={showPassword ? "text" : "password"}
                                label={t('tenant.admin_password') + "*"}
                                value={adminPassword || ""}
                                fullWidth
                                onChange={(e) => { handleFieldChange("adminPassword", e.target.value) }}
                                slotProps={{
                                    input: {
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => setShowPassword(!showPassword)}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    },
                                }}
                            />
                        </Grid>

                    ) : null}

                    {type === "Create" ? (
                        <Grid size={6}>
                            <TextField
                                label={t('tenant.admin_name') + "*"}
                                value={adminName || ""}
                                fullWidth
                                onChange={(e) => { handleFieldChange("adminName", e.target.value) }}
                            />
                        </Grid>

                    ) : null}

                    {type === "Create" ? (
                        <Grid size={6}>
                            <TextField
                                label={t('tenant.admin_surname') + "*"}
                                value={adminSurname || ""}
                                fullWidth
                                onChange={(e) => { handleFieldChange("adminSurname", e.target.value) }}
                            />
                        </Grid>

                    ) : null}

                    <Grid size={12}>
                        <TextField
                            label={t('tenant.phone') + "*"}
                            value={phone || ""}
                            fullWidth
                            onChange={(e) => { handleFieldChange("phone", e.target.value) }}
                        />
                    </Grid>

                    {type !== "Create" ? (
                        <Grid size={12}>
                            <FormControlLabel
                                checked={isActive}
                                onChange={(e) => handleCheckBoxChange("isActive")}
                                control={<Checkbox />}
                                label={t('tenant.is_active')}
                            />
                        </Grid>
                    ) : null}


                </Grid>

                <Divider sx={{ height: '1px', backgroundColor: 'gray', marginTop: 4 }} />

                <Grid container spacing={3} justifyContent="center" sx={{ marginTop: "5%" }}>
                    <Grid size={6}>
                        <TextField
                            label={t('tenant.country') + "*"}
                            value={country || ""}
                            fullWidth
                            onChange={(e) => { handleFieldChange("country", e.target.value) }}
                        />
                    </Grid>

                    <Grid size={6}>
                        <TextField
                            label={t('tenant.state') + "*"}
                            value={state || ""}
                            fullWidth
                            onChange={(e) => { handleFieldChange("state", e.target.value) }}
                        />
                    </Grid>

                    <Grid size={6}>
                        <TextField
                            label={t('tenant.city') + "*"}
                            value={city || ""}
                            fullWidth
                            onChange={(e) => { handleFieldChange("city", e.target.value) }}
                        />
                    </Grid>


                    <Grid size={6}>
                        <TextField
                            label={t('tenant.zip_code') + "*"}
                            value={zipCode || ""}
                            fullWidth
                            onChange={(e) => { handleFieldChange("zipCode", e.target.value) }}
                        />
                    </Grid>

                    <Grid size={6}>
                        <TextField
                            label={t('tenant.street') + "*"}
                            value={street || ""}
                            fullWidth
                            onChange={(e) => { handleFieldChange("street", e.target.value) }}
                        />
                    </Grid>

                    <Grid size={6}>
                        <TextField
                            label={t('users.company') + "*"}
                            value={company || ""}
                            fullWidth
                            onChange={(e) => { handleFieldChange("company", e.target.value) }}
                        />
                    </Grid>
                </Grid>
            </div>



            <Box mt={3} display="flex" justifyContent="space-between">
                <Button
                    variant="outlined"
                    size="medium"
                    onClick={() => {
                        clearFields();
                        onClose();
                    }}
                    style={{ backgroundColor: "#c10101", color: "white" }}
                >
                    {t('general.cancel')}
                </Button>

                <Button
                    variant="contained"
                    size="medium"
                    sx={{
                        backgroundColor: "#0b2343",
                        "&.Mui-disabled": {
                            backgroundColor: "grey",
                            color: "white",
                        },
                    }}
                    onClick={() => { createTenant() }}
                    disabled={name === "" || domain === "" || adminEmail === "" || phone === "" || country === "" || state === "" || city === "" || zipCode === "" || street === "" || tenantExist}
                >
                    {t('general.save')}
                </Button>
            </Box>

        </SwipeableDrawer>
    );
}

export default TenantDetail;
