import React, { useState, useEffect } from "react";
import Grid from '@mui/material/Grid2';
import { SwipeableDrawer, Typography, TextField, IconButton, Button, Box, InputAdornment, Select, MenuItem, InputLabel, FormControlLabel, Checkbox } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { User } from "../../../models/Accounts/User";
import { UserService } from "../../../services/api";
import { RoleService } from "../../../services/api";
import { Role } from "../../../models/Accounts/Role";
import { SubRole } from "../../../models/Accounts/SubRole";
import { SubRoleService } from "../../../services/api";
import UserAvatar from "../../Common/UserAvatar";
import { useTranslation } from "react-i18next";
import SessionExpire from "../../Common/SessionExpire";
import { useAuth } from "../../../context/AuthContext";


interface UserDetailProps {
    open: boolean;
    onClose: () => void;
    type: string;
    myUser: User | null;
    emailList: string[];
}

const UserDetail: React.FC<UserDetailProps> = ({ open, onClose, myUser, type, emailList }) => {

    const [currentUser, setUser] = useState<User | null>(null);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [allRoles, setAllRoles] = useState<Role[]>([]);
    const [allSubRoles, setAllSubRoles] = useState<SubRole[]>([]);
    const [firstname, setFirstname] = useState<string>("");
    const [lastname, setLastname] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [role, setRole] = useState<string>("");
    const [company, setCompany] = useState<string>("");
    const [initialsText, setInitialsText] = useState<string>("");
    const [initialsColor, setInitialsColor] = useState<string>("#0b2343");
    const [subrole, setSubrole] = useState<string[]>([]);
    const [emailExist, setEmailExist] = useState<boolean>(false);
    const { t } = useTranslation('global');
    const [session, setSession] = useState<boolean>(false);
    const { user, tenantCompany } = useAuth();

    const [createOwnPassword, setCreateOwnPassword] = useState<boolean>(true);



    const getAllRoles = async () => {
        try {
            const response = await RoleService.getAll();
            const roles: Role[] = response as Role[];
            setAllRoles(roles);
        } catch (error) {
            console.log(error);
            setSession(true);
        }

    };

    const getAllSubRoles = async () => {
        try {
            const response = await SubRoleService.getAll();
            const subroles: SubRole[] = response.data as SubRole[];
            setAllSubRoles(subroles);
        } catch (error) {
            console.log(error);
            setSession(true);
        }
    };

    useEffect(() => {
        if (open) {
            getAllRoles();
            getAllSubRoles();
        }
        setUser(myUser);
        setFirstname(myUser?.firstname || "");
        setLastname(myUser?.lastname || "");
        setEmail(myUser?.email || "");
        setPassword(myUser?.password || "");
        setCompany(type === "Create" ? tenantCompany : myUser?.company || "");
        setRole(myUser?.role._id || "");
        setSubrole(myUser?.subrole.map((sub) => sub._id) || []);
        setInitialsColor(myUser?.initialsColor || "#0b2343");
        setInitialsText(myUser?.initialsText || "");
    }, [myUser, type, open]);

    const createUser = async () => {
        if (type === "Create") {
            const newUser = {
                firstname: firstname,
                lastname: lastname,
                email: email,
                password: password,
                company: company,
                "role": role,
                subrole: subrole,
                initialsText: initialsText,
                initialsColor: initialsColor,
                createOwnPassword: createOwnPassword
            };
            try {
                const response = await UserService.create(newUser);
                console.log("my project create response -> ", response);
                onClose();
                clearFields();
            } catch (error) {
                console.log(error);
                //setSession(true);
            }

        } else {
            const updatedUser = {
                firstname: firstname,
                lastname: lastname,
                email: email,
                password: password,
                company: company,
                "role": role,
                subrole: subrole,
                initialsText: initialsText,
                initialsColor: initialsColor,
            }
            if (currentUser?._id) {
                try {
                    const response = await UserService.update(currentUser?._id, updatedUser);
                    console.log("project update response -> ", response);
                    onClose();
                    clearFields();
                } catch (error) {
                    console.log(error);
                    //setSession(true);
                }

            } else {
                console.log("currentUser update error");
            }

        }
    };

    const clearFields = () => {
        setFirstname("");
        setLastname("");
        setEmail("");
        setCompany("");
        setPassword("");
        setRole("");
        setInitialsColor("#0b2343");
        setInitialsText("");
        setEmailExist(false);
        setCreateOwnPassword(true);
    };

    const handleFieldChange = (fieldName: string, newValue: any) => {
        const setters: Record<string, (value: any) => void> = {
            firstname: setFirstname,
            lastname: setLastname,
            email: setEmail,
            company: setCompany,
            role: setRole,
            password: setPassword,
            subrole: setSubrole
        };

        if (initialsText === "" && firstname.length > 2 && lastname.length > 1) {
            setInitialsText(firstname.slice(0, 2).toLocaleUpperCase() + lastname.slice(0, 1).toLocaleUpperCase())
        }

        if (fieldName === "email" && emailList.includes(newValue)) {
            setEmailExist(true);
        } else {
            setEmailExist(false)
        }

        const setter = setters[fieldName];
        if (setter) {
            setter(newValue);
        }
    };

    useEffect(() => {
        if (user?.role === "admin") {
            const userID = allRoles.filter((rol) => rol.name === "user")[0]?._id;
            handleFieldChange("role", userID);
        }
    })

    const passwordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleSessionCheck = () => {
        setSession(false);
        window.location.href = '/login';
    };


    const handleCreateOwnPasswordChange = () => {
        setCreateOwnPassword(!createOwnPassword);
    }

    return (
        <SwipeableDrawer
            anchor="right"
            open={open}
            onClose={() => {
                clearFields();
                onClose();
            }}
            onOpen={() => { }}
            PaperProps={{
                style: {
                    width: "60vh",
                    padding: 20,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    overflowY: "auto",
                },
                sx: {
                    "&::-webkit-scrollbar": {
                        width: "8px",
                    },
                    "&::-webkit-scrollbar-track": {
                        backgroundColor: "#f0f0f0",
                    },
                    "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "#c0c0c0",
                        borderRadius: "4px",
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                        backgroundColor: "#a0a0a0",
                    },
                },
            }}
        >
            <SessionExpire open={session} onClose={handleSessionCheck} />
            <div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 15 }}>
                    <Typography variant="h5" sx={{ color: "#0b2343" }}>
                        {type === "Create" ? t('users.create_user') : t('users.edit_user')}
                    </Typography>
                    <IconButton onClick={() => {
                        clearFields();
                        onClose();
                    }}>
                        <CloseIcon />
                    </IconButton>
                </div>

                <div>
                    <Typography variant="body1" sx={{ color: "#0b2343" }}>
                        {t('users.user_details')}
                    </Typography>
                </div>

                <Grid container spacing={3} sx={{ marginTop: "5%" }}>

                    <Grid size={12}>
                        <UserAvatar initialsText={initialsText} initialsColor={initialsColor} editable={true} onTextChange={(text) => { setInitialsText(text) }} onColorChange={(color) => { setInitialsColor(color) }} />
                    </Grid>
                    <Grid size={6}>
                        <TextField
                            label={t('users.firstname') + "*"}
                            value={firstname || ""}
                            fullWidth
                            onChange={(e) => { handleFieldChange("firstname", e.target.value) }}
                        />
                    </Grid>

                    <Grid size={6}>
                        <TextField
                            label={t('users.lastname') + "*"}
                            value={lastname || ""}
                            fullWidth
                            onChange={(e) => { handleFieldChange("lastname", e.target.value) }}
                        />
                    </Grid>

                    <Grid size={12}>
                        <TextField
                            label={t('users.email') + "*"}
                            value={email || ""}
                            fullWidth
                            onChange={(e) => { handleFieldChange("email", e.target.value) }}
                            error={emailExist}
                            helperText={emailExist ? "This is email used." : null}
                        />
                    </Grid>


                    {type === "Create" ? (
                        <Grid size={12}>

                            <FormControlLabel
                                checked={createOwnPassword}
                                onChange={handleCreateOwnPasswordChange}
                                control={<Checkbox />}
                                label={t('users.user_create_own_password')}
                            />

                        </Grid>
                    ) : null}

                    {!createOwnPassword ? (
                        <Grid size={12}>

                            <TextField
                                value={password || ""}
                                fullWidth
                                onChange={(e) => { handleFieldChange("password", e.target.value) }}
                                label={t('users.password') + "*"}
                                size="medium"
                                type={showPassword ? "text" : "password"}
                                slotProps={{
                                    input: {
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={passwordVisibility}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    },
                                }}
                            />
                        </Grid>
                    ) : null}


                    <Grid size={12}>
                        <TextField
                            label={t('users.company') + "*"}
                            value={company || ""}
                            fullWidth
                            onChange={(e) => { handleFieldChange("company", e.target.value) }}
                        />
                    </Grid>

                    {user?.role !== "admin" ? (
                        <Grid size={6}>
                            <InputLabel>{t('users.user_type') + "*"}</InputLabel>
                            <Select
                                fullWidth
                                value={role}
                                onChange={(e) => handleFieldChange("role", e.target.value)}
                            >
                                {allRoles.map((mainRole) => (
                                    <MenuItem key={mainRole.name} value={mainRole._id}>
                                        {mainRole.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                    ) : null}

                    <Grid size={user?.role === "admin" ? 12 : 6}>
                        <InputLabel>{t('users.role') + "*"}</InputLabel>
                        <Select
                            value={subrole}
                            fullWidth
                            onChange={(e) => handleFieldChange("subrole", e.target.value)}
                            multiple
                        >
                            {allSubRoles
                                .filter((role) => ["Participant", "Independent Auditor"].includes(role.name))
                                .map((role) => {
                                    return (
                                        <MenuItem
                                            key={role._id}
                                            value={role._id}
                                            disabled={
                                                (role.name === "Participant" && subrole.includes((allSubRoles.filter((subs) => subs.name === "Independent Auditor"))[0]._id)) ||
                                                (role.name === "Independent Auditor" && subrole.includes((allSubRoles.filter((subs) => subs.name === "Participant"))[0]._id))
                                            }
                                        >
                                            {role.name === "Participant" ? "Participant (" + t('task.internal') + ")" : role.name === "Independent Auditor" ? "Independent Auditor (" + t('task.external') + ")" : role.name}
                                        </MenuItem>
                                    );
                                })}
                        </Select>
                    </Grid>
                </Grid>
            </div>



            <Box mt={3} display="flex" justifyContent="space-between">
                <Button
                    variant="outlined"
                    size="medium"
                    onClick={() => {
                        clearFields();
                        onClose();
                    }}
                    style={{ backgroundColor: "#c10101", color: "white" }}
                >
                    {t('general.cancel')}
                </Button>

                <Button
                    variant="contained"
                    size="medium"
                    sx={{
                        backgroundColor: "#0b2343",
                        "&.Mui-disabled": {
                            backgroundColor: "grey",
                            color: "white",
                        },
                    }}
                    onClick={() => { createUser() }}
                    disabled={firstname === "" || lastname === "" || email === "" || role === "" || company === "" || (!createOwnPassword && password === "") || subrole.length === 0 || initialsText === "" || emailExist}
                >
                    {t('general.save')}
                </Button>
            </Box>

        </SwipeableDrawer>
    );
}

export default UserDetail;
