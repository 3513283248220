import React, { useState } from "react";
import SmartToyIcon from '@mui/icons-material/SmartToy';
import { IconButton, TextField, Tooltip } from "@mui/material";
import Grid from "@mui/material/Grid2";
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import { useTranslation } from "react-i18next";

interface AIProps {
    task: string;
    onSaveResponse: (response: string) => void;
}

const AIResponseSuggestion: React.FC<AIProps> = ({ task, onSaveResponse }) => {
    const [num, setNum] = useState<number>(0);
    const { t } = useTranslation('global');

    const allResponse: Record<string, string> = {
        "BS politika, prosedür ve süreç dokümanlarının oluşturulmasına ilişkin prosedür":
            `
              Kurallara uyum durumu:

    Doküman Kodu: Her dokümanda, dokümanın içeriğini, kapsamını veya uygulama alanını belirten benzersiz bir doküman kodu bulunmalıdır.: Not Compliant

    Revizyon Tarihi: Her doküman, revizyon tarihini içermelidir. Revizyon tarihinin içinde, belgenin geçerli uygulama yılının (2025) yer alması gerekmektedir.: Not Compliant

    Doküman Envanterinin Güncellenmesi ve Klasör Yapısına Kopyalama: Tüm dokümanların, doküman envanterinde yer aldığı gibi, ilgili klasör altına kopyalanarak düzenli bir şekilde saklanması gerekmektedir.: Not Compliant

    Yönetim Kurulu Kararı ile Politikaların Gözden Geçirildiğine Dair Scan Copy: Politikaların gözden geçirildiğine dair, Yönetim Kurulu’nun onayının yer aldığı dokümana ait scan copy (taranmış kopya) belgelenmelidir.: Not Compliant

    Yönetim Kurulu Onay Sürecine Dair Prosedür ve Kılavuzların Durumu: Yönetim Kurulu tarafından, prosedür ve kılavuzların onayının ilgili iş birimine bırakıldığı veya onaylandığına dair kararın scan copy’si mevcut olmalıdır.: Not Compliant

        `,
        "BS dokümanlarının doküman kodu ve doküman gizlilik derecelerinin yer aldığı envanter": `
         Kurallara uyum durumu:

    Doküman Kodu: Her dokümanda, dokümanın içeriğini, kapsamını veya uygulama alanını belirten benzersiz bir doküman kodu bulunmalıdır.: Not Compliant

    Revizyon Tarihi: Her doküman, revizyon tarihini içermelidir. Revizyon tarihinin içinde, belgenin geçerli uygulama yılının (2025) yer alması gerekmektedir.: Not Compliant

    Gizlilik Seviyesi: Her dokümanda, belgenin gizlilik seviyesi net bir şekilde ifade edilmelidir.: Not Compliant

    Hazırlayan ve Kullanıcı Bilgileri: Dokümanın hazırlanmasında görev alan kişi veya departmanın yanı sıra, dokümanın kullanımındaki birimlerin (kullanıcıların) bilgileri de belirtilmelidir.: Not Compliant
    `,
        "Bilgi Sistemleri Kimlik, Erişim ve Yetkilendirme yönetimine ilişkin politika ve prosedürler": `
         Kurallara uyum durumu:

    Gizlilik Seviyesi: Her dokümanda, belgenin gizlilik seviyesi net bir şekilde ifade edilmelidir.: Not Compliant

    Hazırlayan ve Kullanıcı Bilgileri: Dokümanın hazırlanmasında görev alan kişi veya departmanın yanı sıra, dokümanın kullanımındaki birimlerin (kullanıcıların) bilgileri de belirtilmelidir.: Not Compliant

    Kimlik Erişim ve Yetkilendirme Kılavuzu Hazırlanmalıdır: Organizasyonda kimlik erişim ve yetkilendirme süreçlerini açıklayan bir kılavuz dokümanı hazırlanmalıdır. Bu kılavuz, erişim politikalarını, yetkilendirme süreçlerini ve ilgili prosedürleri içermelidir.: Not Compliant

    Doküman Kodu: Her dokümanda, dokümanın içeriğini, kapsamını veya uygulama alanını belirten benzersiz bir doküman kodu bulunmalıdır.: Not Compliant

    Revizyon Tarihi: Her doküman, revizyon tarihini içermelidir. Revizyon tarihinin içinde, belgenin geçerli uygulama yılının (2025) yer alması gerekmektedir.: Not Compliant

    Klasör Altında Bulunması Gereken Excel Dokümanı: İlgili klasör içerisinde, doküman envanteri, kimlik bilgileri ve risk yönetimi bilgilerinin yer aldığı tek bir Excel dosyası bulunmalıdır.: Not Compliant

    Excel Dosyasında Kapak Sayfası ve Güncel Revizyon Tarihi: Excel dosyası ilk açılışta kullanıcılara genel bilgi sağlayan bir kapak (cover) sheet içermelidir. Bu sayfada dokümanın revizyon tarihi açıkça belirtilmeli ve güncel olmalıdır.: Not Compliant

    Bilgi Sistemleri Kimlik Bilgileri Envanteri ve Risk Seviyeleri: Excel dosyası içerisinde, işletme veya sistem kimlik bilgilerini içeren bir envanter yer almalıdır. Bu envanterde, ilgili kimlik bilgilerinin risk seviyeleri de belirgin şekilde listelenmelidir.: Not Compliant

    Bilgi Sistemleri Kimlik Bilgileri Riskleri ve Mitigation Planları: Excel dokümanında, BS kimlik bilgilerine ilişkin riskler listelenmeli ve her risk için uygulanacak veya uygulanmış risk azaltıcı (mitigation) planları yer almalıdır.: Not Compliant

    Mitigation Planlarının İlgili Yıl İçinde Revize Edilmiş Olması: Risk azaltıcı planların etkinliğini sağlamak amacıyla, planların belirlenen ilgili yıl içerisinde gözden geçirilmiş (revize edilmiş) olması gerekmektedir.: Not Compliant

      Kurallara uyum durumu:

    Gizlilik Seviyesi: Her dokümanda, belgenin gizlilik seviyesi net bir şekilde ifade edilmelidir.: Not Compliant

    Hazırlayan ve Kullanıcı Bilgileri: Dokümanın hazırlanmasında görev alan kişi veya departmanın yanı sıra, dokümanın kullanımındaki birimlerin (kullanıcıların) bilgileri de belirtilmelidir.: Not Compliant

    Kimlik Erişim ve Yetkilendirme Kılavuzu Hazırlanmalıdır: Organizasyonda kimlik erişim ve yetkilendirme süreçlerini açıklayan bir kılavuz dokümanı hazırlanmalıdır. Bu kılavuz, erişim politikalarını, yetkilendirme süreçlerini ve ilgili prosedürleri içermelidir.: Not Compliant

    Matrisin Amacı ve Kapsamı: Bu matris, organizasyondaki uygulama (app.), işletim sistemi (OS) ve veritabanı (DB) varlıklarına, farklı roller ve profiller bazında erişim yetkilerini belgelemek ve yönetmek amacıyla oluşturulur.: Not Compliant

    Matrisin Temel Bileşenleri: Roller/Profiller: Organizasyondaki her bir rol ve profil (örneğin; Sistem Yöneticisi, Veri Tabanı Yöneticisi, Uygulama Geliştiricisi, Denetçi vb.) ayrı sütun veya satır olarak tanımlanmalı.: Not Compliant

    Matrisin Temel Bileşenleri: Varlık Tipleri: Uygulama (App): İlgili uygulamalara dair erişim detayları. İşletim Sistemi (OS): Sunucu, masaüstü veya mobil işletim sistemlerine ilişkin erişim bilgileri. Veritabanı (DB): Veritabanı sunucuları, veritabanı kullanıcıları ve erişim düzeyleri.: Not Compliant

    Matrisin Temel Bileşenleri: Erişim Seviyeleri ve Yetkiler: Her varlık için erişim düzeyleri (örneğin; Okuma, Yazma, Güncelleme, Yönetim, vs.) belirlenmelidir. Yetki dağılımı, hangi rolün hangi varlık üzerinde hangi erişim haklarına sahip olduğu açıkça gösterilmelidir.: Not Compliant

    Matris Hazırlama Adımları: Rol ve Profil Tanımlamalarının Belirlenmesi: Organizasyondaki tüm roller ve profiller net bir biçimde tanımlanmalı, açıklanmalı ve matris içinde yer almalıdır. Her rolün görev tanımı ve erişim ihtiyaçları belirlenerek, yetki gereksinimleri oluşturulmalıdır.: Not Compliant

    Matris Hazırlama Adımları: Varlık Envanterinin Oluşturulması: Uygulama, OS ve DB bazında tüm varlıkların envanteri çıkarılmalıdır. Her varlık için erişim noktaları, ilişkili sistemler ve veri bileşenleri listelenmelidir.: Not Compliant

    Matris Hazırlama Adımları: Erişim Seviyelerinin Belirlenmesi: Her varlık için kullanılacak erişim seviyeleri (örn. Görüntüleme, Ekleme, Düzenleme, Silme, Yönetim gibi) tanımlanmalıdır. Her erişim seviyesi için hangi rollerin hangi düzeyde yetkiye sahip olduğu belirlenmeli.: Not Compliant

    Matris Hazırlama Adımları: Matris Şablonunun Oluşturulması: Excel, tablo ya da özel bir erişim kontrol aracı kullanılarak matris oluşturulabilir. Matrisin satırlarında varlıklar (örn. uygulama isimleri, OS sunucuları, veritabanı örnekleri) yer alırken, sütunlarda roller/profiller ve karşılık gelen erişim yetkileri belirtilmelidir. Alternatif olarak, roller/profiller satırda, varlıklar sütunda yer alabilir.: Not Compliant

    Matris Hazırlama Adımları: Onay ve Revizyon Süreçleri: Matris oluşturulduktan sonra ilgili güvenlik, IT ve yöneticiler tarafından onaylanmalıdır. Yetki matrisi belirli aralıklarla (örneğin, yıllık veya yarıyıl) gözden geçirilmeli ve gerekli güncellemeler yapılmalıdır.: Not Compliant

      Kurallara uyum durumu:

    Gizlilik Seviyesi: Her dokümanda, belgenin gizlilik seviyesi net bir şekilde ifade edilmelidir.: Not Compliant

    Hazırlayan ve Kullanıcı Bilgileri: Dokümanın hazırlanmasında görev alan kişi veya departmanın yanı sıra, dokümanın kullanımındaki birimlerin (kullanıcıların) bilgileri de belirtilmelidir.: Not Compliant

    Tüm screenshotlarda windows ise sağ alt köşedeki tarih ve saat bilgisi macos ise sol üst köşedeki tarih ve saat bilgisi görünecek şekilde tam ekran olmalıdır.: Not Compliant

    Ekran görüntüsünde liste varsa ilk ve son kayıtların görünecek şekilde görüntü alınmalıdır.: Not Compliant

    Kimlik Erişim ve Yetkilendirme Kılavuzu Hazırlanmalıdır: Organizasyonda kimlik erişim ve yetkilendirme süreçlerini açıklayan bir kılavuz dokümanı hazırlanmalıdır. Bu kılavuz, erişim politikalarını, yetkilendirme süreçlerini ve ilgili prosedürleri içermelidir.: Not Compliant

      Kurallara uyum durumu:

    Gizlilik Seviyesi: Her dokümanda, belgenin gizlilik seviyesi net bir şekilde ifade edilmelidir.: Not Compliant

    Hazırlayan ve Kullanıcı Bilgileri: Dokümanın hazırlanmasında görev alan kişi veya departmanın yanı sıra, dokümanın kullanımındaki birimlerin (kullanıcıların) bilgileri de belirtilmelidir.: Not Compliant

    Tüm screenshotlarda windows ise sağ alt köşedeki tarih ve saat bilgisi macos ise sol üst köşedeki tarih ve saat bilgisi görünecek şekilde tam ekran olmalıdır.: Not Compliant

    Ekran görüntüsünde liste varsa ilk ve son kayıtların görünecek şekilde görüntü alınmalıdır.: Not Compliant

    Yönetim Kurulu tarihi denetim yapılan yıla ait olmalıdır.: Not Compliant

    Yönetim kurulu üyelerinin imzalarının tam olması gereklidir.: Not Compliant

    İlgili soruda belirtilen konuyu kapsaması gereklidir. Örneğin bu madde için 'BT Politikaları Yönetim kurulunda değerlendirilmiş ve onaylanmıştır.' gibi bir ifade aranır.: Not Compliant

    Doküman Kodu: Her dokümanda, dokümanın içeriğini, kapsamını veya uygulama alanını belirten benzersiz bir doküman kodu bulunmalıdır.: Not Compliant

    Kanıt Niteliğinde Belgeler: Prosedürün doğru ve eksiksiz uygulandığının belgelenebilmesi için, ilgili süreç boyunca gönderilen e-postalar, oluşturulan tutanaklar ve otomatik veya manuel olarak tutulan log kayıtları mevcut olmalıdır.: Not Compliant

    Kanıtların Tutarlılığı ve Erişilebilirliği: Süreç boyunca oluşturulan belgelerin, denetim ve değerlendirme aşamalarında kolayca erişilebilir olması ve güvenilir bilgi kaynağı sunması gerekmektedir.: Not Compliant

    Prosedür Uyumluluğunun Doğrulanması: İlgili e-posta, tutanak ve log kayıtları, örnek prosedüre uygun hareket edildiğini doğrulayan temel unsurlardır. Bu belgeler, süreç performansının analizi, hataların belirlenmesi ve iyileştirmelerin planlanması açısından kritik rol oynar.: Not Compliant

    Kimlik Erişim ve Yetkilendirme Kılavuzu Hazırlanmalıdır: Organizasyonda kimlik erişim ve yetkilendirme süreçlerini açıklayan bir kılavuz dokümanı hazırlanmalıdır. Bu kılavuz, erişim politikalarını, yetkilendirme süreçlerini ve ilgili prosedürleri içermelidir.: Not Compliant

    Revizyon Tarihi: Her doküman, revizyon tarihini içermelidir. Revizyon tarihinin içinde, belgenin geçerli uygulama yılının (2025) yer alması gerekmektedir.: Not Compliant

    Klasör Altında Bulunması Gereken Excel Dokümanı: İlgili klasör içerisinde, doküman envanteri, kimlik bilgileri ve risk yönetimi bilgilerinin yer aldığı tek bir Excel dosyası bulunmalıdır.: Not Compliant

    Excel Dosyasında Kapak Sayfası ve Güncel Revizyon Tarihi: Excel dosyası ilk açılışta kullanıcılara genel bilgi sağlayan bir kapak (cover) sheet içermelidir. Bu sayfada dokümanın revizyon tarihi açıkça belirtilmeli ve güncel olmalıdır.: Not Compliant

    Bilgi Sistemleri Kimlik Bilgileri Envanteri ve Risk Seviyeleri: Excel dosyası içerisinde, işletme veya sistem kimlik bilgilerini içeren bir envanter yer almalıdır. Bu envanterde, ilgili kimlik bilgilerinin risk seviyeleri de belirgin şekilde listelenmelidir.: Not Compliant

    Bilgi Sistemleri Kimlik Bilgileri Riskleri ve Mitigation Planları: Excel dokümanında, BS kimlik bilgilerine ilişkin riskler listelenmeli ve her risk için uygulanacak veya uygulanmış risk azaltıcı (mitigation) planları yer almalıdır.: Not Compliant

    Mitigation Planlarının İlgili Yıl İçinde Revize Edilmiş Olması: Risk azaltıcı planların etkinliğini sağlamak amacıyla, planların belirlenen ilgili yıl içerisinde gözden geçirilmiş (revize edilmiş) olması gerekmektedir.: Not Compliant

    Matrisin Amacı ve Kapsamı: Bu matris, organizasyondaki uygulama (app.), işletim sistemi (OS) ve veritabanı (DB) varlıklarına, farklı roller ve profiller bazında erişim yetkilerini belgelemek ve yönetmek amacıyla oluşturulur.: Not Compliant

    Matrisin Temel Bileşenleri: Roller/Profiller: Organizasyondaki her bir rol ve profil (örneğin; Sistem Yöneticisi, Veri Tabanı Yöneticisi, Uygulama Geliştiricisi, Denetçi vb.) ayrı sütun veya satır olarak tanımlanmalı.: Not Compliant

    Matrisin Temel Bileşenleri: Varlık Tipleri: Uygulama (App): İlgili uygulamalara dair erişim detayları. İşletim Sistemi (OS): Sunucu, masaüstü veya mobil işletim sistemlerine ilişkin erişim bilgileri. Veritabanı (DB): Veritabanı sunucuları, veritabanı kullanıcıları ve erişim düzeyleri.: Not Compliant

    Matrisin Temel Bileşenleri: Erişim Seviyeleri ve Yetkiler: Her varlık için erişim düzeyleri (örneğin; Okuma, Yazma, Güncelleme, Yönetim, vs.) belirlenmelidir. Yetki dağılımı, hangi rolün hangi varlık üzerinde hangi erişim haklarına sahip olduğu açıkça gösterilmelidir.: Not Compliant

    Matris Hazırlama Adımları: Rol ve Profil Tanımlamalarının Belirlenmesi: Organizasyondaki tüm roller ve profiller net bir biçimde tanımlanmalı, açıklanmalı ve matris içinde yer almalıdır. Her rolün görev tanımı ve erişim ihtiyaçları belirlenerek, yetki gereksinimleri oluşturulmalıdır.: Not Compliant

    Matris Hazırlama Adımları: Varlık Envanterinin Oluşturulması: Uygulama, OS ve DB bazında tüm varlıkların envanteri çıkarılmalıdır. Her varlık için erişim noktaları, ilişkili sistemler ve veri bileşenleri listelenmelidir.: Not Compliant

    Matris Hazırlama Adımları: Erişim Seviyelerinin Belirlenmesi: Her varlık için kullanılacak erişim seviyeleri (örn. Görüntüleme, Ekleme, Düzenleme, Silme, Yönetim gibi) tanımlanmalıdır. Her erişim seviyesi için hangi rollerin hangi düzeyde yetkiye sahip olduğu belirlenmeli.: Not Compliant

    Matris Hazırlama Adımları: Matris Şablonunun Oluşturulması: Excel, tablo ya da özel bir erişim kontrol aracı kullanılarak matris oluşturulabilir. Matrisin satırlarında varlıklar (örn. uygulama isimleri, OS sunucuları, veritabanı örnekleri) yer alırken, sütunlarda roller/profiller ve karşılık gelen erişim yetkileri belirtilmelidir. Alternatif olarak, roller/profiller satırda, varlıklar sütunda yer alabilir.: Not Compliant

    Matris Hazırlama Adımları: Onay ve Revizyon Süreçleri: Matris oluşturulduktan sonra ilgili güvenlik, IT ve yöneticiler tarafından onaylanmalıdır. Yetki matrisi belirli aralıklarla (örneğin, yıllık veya yarıyıl) gözden geçirilmeli ve gerekli güncellemeler yapılmalıdır.: Not Compliant
    `,
        "İlgili envanter ile ilişkili olarak erişimlere ilişkin test çalışması":
            `
          Kurallara uyum durumu:

    Gizlilik Seviyesi: Her dokümanda, belgenin gizlilik seviyesi net bir şekilde ifade edilmelidir.: Not Compliant

    Hazırlayan ve Kullanıcı Bilgileri: Dokümanın hazırlanmasında görev alan kişi veya departmanın yanı sıra, dokümanın kullanımındaki birimlerin (kullanıcıların) bilgileri de belirtilmelidir.: Not Compliant

    Tüm screenshotlarda windows ise sağ alt köşedeki tarih ve saat bilgisi macos ise sol üst köşedeki tarih ve saat bilgisi görünecek şekilde tam ekran olmalıdır.: Not Compliant

    Ekran görüntüsünde liste varsa ilk ve son kayıtların görünecek şekilde görüntü alınmalıdır.: Not Compliant

    Kimlik Erişim ve Yetkilendirme Kılavuzu Hazırlanmalıdır: Organizasyonda kimlik erişim ve yetkilendirme süreçlerini açıklayan bir kılavuz dokümanı hazırlanmalıdır. Bu kılavuz, erişim politikalarını, yetkilendirme süreçlerini ve ilgili prosedürleri içermelidir.: Not Compliant
  Kurallara uyum durumu:

    Gizlilik Seviyesi: Her dokümanda, belgenin gizlilik seviyesi net bir şekilde ifade edilmelidir.: Not Compliant

    Hazırlayan ve Kullanıcı Bilgileri: Dokümanın hazırlanmasında görev alan kişi veya departmanın yanı sıra, dokümanın kullanımındaki birimlerin (kullanıcıların) bilgileri de belirtilmelidir.: Not Compliant

    Tüm screenshotlarda windows ise sağ alt köşedeki tarih ve saat bilgisi macos ise sol üst köşedeki tarih ve saat bilgisi görünecek şekilde tam ekran olmalıdır.: Not Compliant

    Ekran görüntüsünde liste varsa ilk ve son kayıtların görünecek şekilde görüntü alınmalıdır.: Not Compliant

    Yönetim Kurulu tarihi denetim yapılan yıla ait olmalıdır.: Not Compliant

    Yönetim kurulu üyelerinin imzalarının tam olması gereklidir.: Not Compliant

    İlgili soruda belirtilen konuyu kapsaması gereklidir. Örneğin bu madde için 'BT Politikaları Yönetim kurulunda değerlendirilmiş ve onaylanmıştır.' gibi bir ifade aranır.: Not Compliant

    Doküman Kodu: Her dokümanda, dokümanın içeriğini, kapsamını veya uygulama alanını belirten benzersiz bir doküman kodu bulunmalıdır.: Not Compliant

    Kanıt Niteliğinde Belgeler: Prosedürün doğru ve eksiksiz uygulandığının belgelenebilmesi için, ilgili süreç boyunca gönderilen e-postalar, oluşturulan tutanaklar ve otomatik veya manuel olarak tutulan log kayıtları mevcut olmalıdır.: Not Compliant

    Kanıtların Tutarlılığı ve Erişilebilirliği: Süreç boyunca oluşturulan belgelerin, denetim ve değerlendirme aşamalarında kolayca erişilebilir olması ve güvenilir bilgi kaynağı sunması gerekmektedir.: Not Compliant

    Prosedür Uyumluluğunun Doğrulanması: İlgili e-posta, tutanak ve log kayıtları, örnek prosedüre uygun hareket edildiğini doğrulayan temel unsurlardır. Bu belgeler, süreç performansının analizi, hataların belirlenmesi ve iyileştirmelerin planlanması açısından kritik rol oynar.: Not Compliant

    Kimlik Erişim ve Yetkilendirme Kılavuzu Hazırlanmalıdır: Organizasyonda kimlik erişim ve yetkilendirme süreçlerini açıklayan bir kılavuz dokümanı hazırlanmalıdır. Bu kılavuz, erişim politikalarını, yetkilendirme süreçlerini ve ilgili prosedürleri içermelidir.: Not Compliant

    Revizyon Tarihi: Her doküman, revizyon tarihini içermelidir. Revizyon tarihinin içinde, belgenin geçerli uygulama yılının (2025) yer alması gerekmektedir.: Not Compliant

    Klasör Altında Bulunması Gereken Excel Dokümanı: İlgili klasör içerisinde, doküman envanteri, kimlik bilgileri ve risk yönetimi bilgilerinin yer aldığı tek bir Excel dosyası bulunmalıdır.: Not Compliant

    Excel Dosyasında Kapak Sayfası ve Güncel Revizyon Tarihi: Excel dosyası ilk açılışta kullanıcılara genel bilgi sağlayan bir kapak (cover) sheet içermelidir. Bu sayfada dokümanın revizyon tarihi açıkça belirtilmeli ve güncel olmalıdır.: Not Compliant

    Bilgi Sistemleri Kimlik Bilgileri Envanteri ve Risk Seviyeleri: Excel dosyası içerisinde, işletme veya sistem kimlik bilgilerini içeren bir envanter yer almalıdır. Bu envanterde, ilgili kimlik bilgilerinin risk seviyeleri de belirgin şekilde listelenmelidir.: Not Compliant

    Bilgi Sistemleri Kimlik Bilgileri Riskleri ve Mitigation Planları: Excel dokümanında, BS kimlik bilgilerine ilişkin riskler listelenmeli ve her risk için uygulanacak veya uygulanmış risk azaltıcı (mitigation) planları yer almalıdır.: Not Compliant

    Mitigation Planlarının İlgili Yıl İçinde Revize Edilmiş Olması: Risk azaltıcı planların etkinliğini sağlamak amacıyla, planların belirlenen ilgili yıl içerisinde gözden geçirilmiş (revize edilmiş) olması gerekmektedir.: Not Compliant

    Matrisin Amacı ve Kapsamı: Bu matris, organizasyondaki uygulama (app.), işletim sistemi (OS) ve veritabanı (DB) varlıklarına, farklı roller ve profiller bazında erişim yetkilerini belgelemek ve yönetmek amacıyla oluşturulur.: Not Compliant

    Matrisin Temel Bileşenleri: Roller/Profiller: Organizasyondaki her bir rol ve profil (örneğin; Sistem Yöneticisi, Veri Tabanı Yöneticisi, Uygulama Geliştiricisi, Denetçi vb.) ayrı sütun veya satır olarak tanımlanmalı.: Not Compliant

    Matrisin Temel Bileşenleri: Varlık Tipleri: Uygulama (App): İlgili uygulamalara dair erişim detayları. İşletim Sistemi (OS): Sunucu, masaüstü veya mobil işletim sistemlerine ilişkin erişim bilgileri. Veritabanı (DB): Veritabanı sunucuları, veritabanı kullanıcıları ve erişim düzeyleri.: Not Compliant

    Matrisin Temel Bileşenleri: Erişim Seviyeleri ve Yetkiler: Her varlık için erişim düzeyleri (örneğin; Okuma, Yazma, Güncelleme, Yönetim, vs.) belirlenmelidir. Yetki dağılımı, hangi rolün hangi varlık üzerinde hangi erişim haklarına sahip olduğu açıkça gösterilmelidir.: Not Compliant

    Matris Hazırlama Adımları: Rol ve Profil Tanımlamalarının Belirlenmesi: Organizasyondaki tüm roller ve profiller net bir biçimde tanımlanmalı, açıklanmalı ve matris içinde yer almalıdır. Her rolün görev tanımı ve erişim ihtiyaçları belirlenerek, yetki gereksinimleri oluşturulmalıdır.: Not Compliant

    Matris Hazırlama Adımları: Varlık Envanterinin Oluşturulması: Uygulama, OS ve DB bazında tüm varlıkların envanteri çıkarılmalıdır. Her varlık için erişim noktaları, ilişkili sistemler ve veri bileşenleri listelenmelidir.: Not Compliant

    Matris Hazırlama Adımları: Erişim Seviyelerinin Belirlenmesi: Her varlık için kullanılacak erişim seviyeleri (örn. Görüntüleme, Ekleme, Düzenleme, Silme, Yönetim gibi) tanımlanmalıdır. Her erişim seviyesi için hangi rollerin hangi düzeyde yetkiye sahip olduğu belirlenmeli.: Not Compliant

    Matris Hazırlama Adımları: Matris Şablonunun Oluşturulması: Excel, tablo ya da özel bir erişim kontrol aracı kullanılarak matris oluşturulabilir. Matrisin satırlarında varlıklar (örn. uygulama isimleri, OS sunucuları, veritabanı örnekleri) yer alırken, sütunlarda roller/profiller ve karşılık gelen erişim yetkileri belirtilmelidir. Alternatif olarak, roller/profiller satırda, varlıklar sütunda yer alabilir.: Not Compliant

    Matris Hazırlama Adımları: Onay ve Revizyon Süreçleri: Matris oluşturulduktan sonra ilgili güvenlik, IT ve yöneticiler tarafından onaylanmalıdır. Yetki matrisi belirli aralıklarla (örneğin, yıllık veya yarıyıl) gözden geçirilmeli ve gerekli güncellemeler yapılmalıdır.: Not Compliant
            
        `
        ,
        "BT politikaları yönetim kurulu tarafından onaylandığına ilişkin doküman": `

          Kurallara uyum durumu:

    Gizlilik Seviyesi: Her dokümanda, belgenin gizlilik seviyesi net bir şekilde ifade edilmelidir.: Not Compliant

    Hazırlayan ve Kullanıcı Bilgileri: Dokümanın hazırlanmasında görev alan kişi veya departmanın yanı sıra, dokümanın kullanımındaki birimlerin (kullanıcıların) bilgileri de belirtilmelidir.: Not Compliant

    Tüm screenshotlarda windows ise sağ alt köşedeki tarih ve saat bilgisi macos ise sol üst köşedeki tarih ve saat bilgisi görünecek şekilde tam ekran olmalıdır.: Not Compliant

    Ekran görüntüsünde liste varsa ilk ve son kayıtların görünecek şekilde görüntü alınmalıdır.: Not Compliant

    Yönetim Kurulu tarihi denetim yapılan yıla ait olmalıdır.: Not Compliant

    Yönetim kurulu üyelerinin imzalarının tam olması gereklidir.: Not Compliant

    İlgili soruda belirtilen konuyu kapsaması gereklidir. Örneğin bu madde için 'BT Politikaları Yönetim kurulunda değerlendirilmiş ve onaylanmıştır.' gibi bir ifade aranır.: Not Compliant

    Doküman Kodu: Her dokümanda, dokümanın içeriğini, kapsamını veya uygulama alanını belirten benzersiz bir doküman kodu bulunmalıdır.: Not Compliant

    Kanıt Niteliğinde Belgeler: Prosedürün doğru ve eksiksiz uygulandığının belgelenebilmesi için, ilgili süreç boyunca gönderilen e-postalar, oluşturulan tutanaklar ve otomatik veya manuel olarak tutulan log kayıtları mevcut olmalıdır.: Not Compliant

    Kanıtların Tutarlılığı ve Erişilebilirliği: Süreç boyunca oluşturulan belgelerin, denetim ve değerlendirme aşamalarında kolayca erişilebilir olması ve güvenilir bilgi kaynağı sunması gerekmektedir.: Not Compliant

    Prosedür Uyumluluğunun Doğrulanması: İlgili e-posta, tutanak ve log kayıtları, örnek prosedüre uygun hareket edildiğini doğrulayan temel unsurlardır. Bu belgeler, süreç performansının analizi, hataların belirlenmesi ve iyileştirmelerin planlanması açısından kritik rol oynar.: Not Compliant

    Kimlik Erişim ve Yetkilendirme Kılavuzu Hazırlanmalıdır: Organizasyonda kimlik erişim ve yetkilendirme süreçlerini açıklayan bir kılavuz dokümanı hazırlanmalıdır. Bu kılavuz, erişim politikalarını, yetkilendirme süreçlerini ve ilgili prosedürleri içermelidir.: Not Compliant

    Revizyon Tarihi: Her doküman, revizyon tarihini içermelidir. Revizyon tarihinin içinde, belgenin geçerli uygulama yılının (2025) yer alması gerekmektedir.: Not Compliant

    Klasör Altında Bulunması Gereken Excel Dokümanı: İlgili klasör içerisinde, doküman envanteri, kimlik bilgileri ve risk yönetimi bilgilerinin yer aldığı tek bir Excel dosyası bulunmalıdır.: Not Compliant

    Excel Dosyasında Kapak Sayfası ve Güncel Revizyon Tarihi: Excel dosyası ilk açılışta kullanıcılara genel bilgi sağlayan bir kapak (cover) sheet içermelidir. Bu sayfada dokümanın revizyon tarihi açıkça belirtilmeli ve güncel olmalıdır.: Not Compliant

    Bilgi Sistemleri Kimlik Bilgileri Envanteri ve Risk Seviyeleri: Excel dosyası içerisinde, işletme veya sistem kimlik bilgilerini içeren bir envanter yer almalıdır. Bu envanterde, ilgili kimlik bilgilerinin risk seviyeleri de belirgin şekilde listelenmelidir.: Not Compliant

    Bilgi Sistemleri Kimlik Bilgileri Riskleri ve Mitigation Planları: Excel dokümanında, BS kimlik bilgilerine ilişkin riskler listelenmeli ve her risk için uygulanacak veya uygulanmış risk azaltıcı (mitigation) planları yer almalıdır.: Not Compliant

    Mitigation Planlarının İlgili Yıl İçinde Revize Edilmiş Olması: Risk azaltıcı planların etkinliğini sağlamak amacıyla, planların belirlenen ilgili yıl içerisinde gözden geçirilmiş (revize edilmiş) olması gerekmektedir.: Not Compliant

    Matrisin Amacı ve Kapsamı: Bu matris, organizasyondaki uygulama (app.), işletim sistemi (OS) ve veritabanı (DB) varlıklarına, farklı roller ve profiller bazında erişim yetkilerini belgelemek ve yönetmek amacıyla oluşturulur.: Not Compliant

    Matrisin Temel Bileşenleri: Roller/Profiller: Organizasyondaki her bir rol ve profil (örneğin; Sistem Yöneticisi, Veri Tabanı Yöneticisi, Uygulama Geliştiricisi, Denetçi vb.) ayrı sütun veya satır olarak tanımlanmalı.: Not Compliant

    Matrisin Temel Bileşenleri: Varlık Tipleri: Uygulama (App): İlgili uygulamalara dair erişim detayları. İşletim Sistemi (OS): Sunucu, masaüstü veya mobil işletim sistemlerine ilişkin erişim bilgileri. Veritabanı (DB): Veritabanı sunucuları, veritabanı kullanıcıları ve erişim düzeyleri.: Not Compliant

    Matrisin Temel Bileşenleri: Erişim Seviyeleri ve Yetkiler: Her varlık için erişim düzeyleri (örneğin; Okuma, Yazma, Güncelleme, Yönetim, vs.) belirlenmelidir. Yetki dağılımı, hangi rolün hangi varlık üzerinde hangi erişim haklarına sahip olduğu açıkça gösterilmelidir.: Not Compliant

    Matris Hazırlama Adımları: Rol ve Profil Tanımlamalarının Belirlenmesi: Organizasyondaki tüm roller ve profiller net bir biçimde tanımlanmalı, açıklanmalı ve matris içinde yer almalıdır. Her rolün görev tanımı ve erişim ihtiyaçları belirlenerek, yetki gereksinimleri oluşturulmalıdır.: Not Compliant

    Matris Hazırlama Adımları: Varlık Envanterinin Oluşturulması: Uygulama, OS ve DB bazında tüm varlıkların envanteri çıkarılmalıdır. Her varlık için erişim noktaları, ilişkili sistemler ve veri bileşenleri listelenmelidir.: Not Compliant

    Matris Hazırlama Adımları: Erişim Seviyelerinin Belirlenmesi: Her varlık için kullanılacak erişim seviyeleri (örn. Görüntüleme, Ekleme, Düzenleme, Silme, Yönetim gibi) tanımlanmalıdır. Her erişim seviyesi için hangi rollerin hangi düzeyde yetkiye sahip olduğu belirlenmeli.: Not Compliant

    Matris Hazırlama Adımları: Matris Şablonunun Oluşturulması: Excel, tablo ya da özel bir erişim kontrol aracı kullanılarak matris oluşturulabilir. Matrisin satırlarında varlıklar (örn. uygulama isimleri, OS sunucuları, veritabanı örnekleri) yer alırken, sütunlarda roller/profiller ve karşılık gelen erişim yetkileri belirtilmelidir. Alternatif olarak, roller/profiller satırda, varlıklar sütunda yer alabilir.: Not Compliant

    Matris Hazırlama Adımları: Onay ve Revizyon Süreçleri: Matris oluşturulduktan sonra ilgili güvenlik, IT ve yöneticiler tarafından onaylanmalıdır. Yetki matrisi belirli aralıklarla (örneğin, yıllık veya yarıyıl) gözden geçirilmeli ve gerekli güncellemeler yapılmalıdır.: Not Compliant

            `,
        "BS prosedürleri ve süreç dokümanları yönetim kurulu ya da yönetim kurulunun bu yönde yetkisini devrettiği yöneticilerce onaylandığına ilişkin doküman":
            `
  Kurallara uyum durumu:

    Yönetim Kurulu tarihi denetim yapılan yıla ait olmalıdır.: Not Compliant

    Yönetim kurulu üyelerinin imzalarının tam olması gereklidir.: Not Compliant

    İlgili soruda belirtilen konuyu kapsaması gereklidir. Örneğin bu madde için 'BT Politikaları Yönetim kurulunda değerlendirilmiş ve onaylanmıştır.' gibi bir ifade aranır.: Not Compliant

      Kurallara uyum durumu:

    Gizlilik Seviyesi: Her dokümanda, belgenin gizlilik seviyesi net bir şekilde ifade edilmelidir.: Not Compliant

    Hazırlayan ve Kullanıcı Bilgileri: Dokümanın hazırlanmasında görev alan kişi veya departmanın yanı sıra, dokümanın kullanımındaki birimlerin (kullanıcıların) bilgileri de belirtilmelidir.: Not Compliant

    Tüm screenshotlarda windows ise sağ alt köşedeki tarih ve saat bilgisi macos ise sol üst köşedeki tarih ve saat bilgisi görünecek şekilde tam ekran olmalıdır.: Not Compliant

    Ekran görüntüsünde liste varsa ilk ve son kayıtların görünecek şekilde görüntü alınmalıdır.: Not Compliant

    Yönetim Kurulu tarihi denetim yapılan yıla ait olmalıdır.: Not Compliant

    Yönetim kurulu üyelerinin imzalarının tam olması gereklidir.: Not Compliant

    İlgili soruda belirtilen konuyu kapsaması gereklidir. Örneğin bu madde için 'BT Politikaları Yönetim kurulunda değerlendirilmiş ve onaylanmıştır.' gibi bir ifade aranır.: Not Compliant

    Doküman Kodu: Her dokümanda, dokümanın içeriğini, kapsamını veya uygulama alanını belirten benzersiz bir doküman kodu bulunmalıdır.: Not Compliant

    Kanıt Niteliğinde Belgeler: Prosedürün doğru ve eksiksiz uygulandığının belgelenebilmesi için, ilgili süreç boyunca gönderilen e-postalar, oluşturulan tutanaklar ve otomatik veya manuel olarak tutulan log kayıtları mevcut olmalıdır.: Not Compliant

    Kanıtların Tutarlılığı ve Erişilebilirliği: Süreç boyunca oluşturulan belgelerin, denetim ve değerlendirme aşamalarında kolayca erişilebilir olması ve güvenilir bilgi kaynağı sunması gerekmektedir.: Not Compliant

    Prosedür Uyumluluğunun Doğrulanması: İlgili e-posta, tutanak ve log kayıtları, örnek prosedüre uygun hareket edildiğini doğrulayan temel unsurlardır. Bu belgeler, süreç performansının analizi, hataların belirlenmesi ve iyileştirmelerin planlanması açısından kritik rol oynar.: Not Compliant

    Kimlik Erişim ve Yetkilendirme Kılavuzu Hazırlanmalıdır: Organizasyonda kimlik erişim ve yetkilendirme süreçlerini açıklayan bir kılavuz dokümanı hazırlanmalıdır. Bu kılavuz, erişim politikalarını, yetkilendirme süreçlerini ve ilgili prosedürleri içermelidir.: Not Compliant

    Revizyon Tarihi: Her doküman, revizyon tarihini içermelidir. Revizyon tarihinin içinde, belgenin geçerli uygulama yılının (2025) yer alması gerekmektedir.: Not Compliant

    Klasör Altında Bulunması Gereken Excel Dokümanı: İlgili klasör içerisinde, doküman envanteri, kimlik bilgileri ve risk yönetimi bilgilerinin yer aldığı tek bir Excel dosyası bulunmalıdır.: Not Compliant

    Excel Dosyasında Kapak Sayfası ve Güncel Revizyon Tarihi: Excel dosyası ilk açılışta kullanıcılara genel bilgi sağlayan bir kapak (cover) sheet içermelidir. Bu sayfada dokümanın revizyon tarihi açıkça belirtilmeli ve güncel olmalıdır.: Not Compliant

    Bilgi Sistemleri Kimlik Bilgileri Envanteri ve Risk Seviyeleri: Excel dosyası içerisinde, işletme veya sistem kimlik bilgilerini içeren bir envanter yer almalıdır. Bu envanterde, ilgili kimlik bilgilerinin risk seviyeleri de belirgin şekilde listelenmelidir.: Not Compliant

    Bilgi Sistemleri Kimlik Bilgileri Riskleri ve Mitigation Planları: Excel dokümanında, BS kimlik bilgilerine ilişkin riskler listelenmeli ve her risk için uygulanacak veya uygulanmış risk azaltıcı (mitigation) planları yer almalıdır.: Not Compliant

    Mitigation Planlarının İlgili Yıl İçinde Revize Edilmiş Olması: Risk azaltıcı planların etkinliğini sağlamak amacıyla, planların belirlenen ilgili yıl içerisinde gözden geçirilmiş (revize edilmiş) olması gerekmektedir.: Not Compliant

    Matrisin Amacı ve Kapsamı: Bu matris, organizasyondaki uygulama (app.), işletim sistemi (OS) ve veritabanı (DB) varlıklarına, farklı roller ve profiller bazında erişim yetkilerini belgelemek ve yönetmek amacıyla oluşturulur.: Not Compliant

    Matrisin Temel Bileşenleri: Roller/Profiller: Organizasyondaki her bir rol ve profil (örneğin; Sistem Yöneticisi, Veri Tabanı Yöneticisi, Uygulama Geliştiricisi, Denetçi vb.) ayrı sütun veya satır olarak tanımlanmalı.: Not Compliant

    Matrisin Temel Bileşenleri: Varlık Tipleri: Uygulama (App): İlgili uygulamalara dair erişim detayları. İşletim Sistemi (OS): Sunucu, masaüstü veya mobil işletim sistemlerine ilişkin erişim bilgileri. Veritabanı (DB): Veritabanı sunucuları, veritabanı kullanıcıları ve erişim düzeyleri.: Not Compliant

    Matrisin Temel Bileşenleri: Erişim Seviyeleri ve Yetkiler: Her varlık için erişim düzeyleri (örneğin; Okuma, Yazma, Güncelleme, Yönetim, vs.) belirlenmelidir. Yetki dağılımı, hangi rolün hangi varlık üzerinde hangi erişim haklarına sahip olduğu açıkça gösterilmelidir.: Not Compliant

    Matris Hazırlama Adımları: Rol ve Profil Tanımlamalarının Belirlenmesi: Organizasyondaki tüm roller ve profiller net bir biçimde tanımlanmalı, açıklanmalı ve matris içinde yer almalıdır. Her rolün görev tanımı ve erişim ihtiyaçları belirlenerek, yetki gereksinimleri oluşturulmalıdır.: Not Compliant

    Matris Hazırlama Adımları: Varlık Envanterinin Oluşturulması: Uygulama, OS ve DB bazında tüm varlıkların envanteri çıkarılmalıdır. Her varlık için erişim noktaları, ilişkili sistemler ve veri bileşenleri listelenmelidir.: Not Compliant

    Matris Hazırlama Adımları: Erişim Seviyelerinin Belirlenmesi: Her varlık için kullanılacak erişim seviyeleri (örn. Görüntüleme, Ekleme, Düzenleme, Silme, Yönetim gibi) tanımlanmalıdır. Her erişim seviyesi için hangi rollerin hangi düzeyde yetkiye sahip olduğu belirlenmeli.: Not Compliant

    Matris Hazırlama Adımları: Matris Şablonunun Oluşturulması: Excel, tablo ya da özel bir erişim kontrol aracı kullanılarak matris oluşturulabilir. Matrisin satırlarında varlıklar (örn. uygulama isimleri, OS sunucuları, veritabanı örnekleri) yer alırken, sütunlarda roller/profiller ve karşılık gelen erişim yetkileri belirtilmelidir. Alternatif olarak, roller/profiller satırda, varlıklar sütunda yer alabilir.: Not Compliant

    Matris Hazırlama Adımları: Onay ve Revizyon Süreçleri: Matris oluşturulduktan sonra ilgili güvenlik, IT ve yöneticiler tarafından onaylanmalıdır. Yetki matrisi belirli aralıklarla (örneğin, yıllık veya yarıyıl) gözden geçirilmeli ve gerekli güncellemeler yapılmalıdır.: Not Compliant


        `
        ,
        "BS politika, prosedür ve süreç dokümanlarının işlerliğine ilişkin gözetim ve takip çalışmalarına ait kanıt": `
          Kurallara uyum durumu:

    Gizlilik Seviyesi: Her dokümanda, belgenin gizlilik seviyesi net bir şekilde ifade edilmelidir.: Not Compliant

    Hazırlayan ve Kullanıcı Bilgileri: Dokümanın hazırlanmasında görev alan kişi veya departmanın yanı sıra, dokümanın kullanımındaki birimlerin (kullanıcıların) bilgileri de belirtilmelidir.: Not Compliant

    Tüm screenshotlarda windows ise sağ alt köşedeki tarih ve saat bilgisi macos ise sol üst köşedeki tarih ve saat bilgisi görünecek şekilde tam ekran olmalıdır.: Not Compliant

    Ekran görüntüsünde liste varsa ilk ve son kayıtların görünecek şekilde görüntü alınmalıdır.: Not Compliant

    Yönetim Kurulu tarihi denetim yapılan yıla ait olmalıdır.: Not Compliant

    Yönetim kurulu üyelerinin imzalarının tam olması gereklidir.: Not Compliant

    İlgili soruda belirtilen konuyu kapsaması gereklidir. Örneğin bu madde için 'BT Politikaları Yönetim kurulunda değerlendirilmiş ve onaylanmıştır.' gibi bir ifade aranır.: Not Compliant

    Doküman Kodu: Her dokümanda, dokümanın içeriğini, kapsamını veya uygulama alanını belirten benzersiz bir doküman kodu bulunmalıdır.: Not Compliant

    Kanıt Niteliğinde Belgeler: Prosedürün doğru ve eksiksiz uygulandığının belgelenebilmesi için, ilgili süreç boyunca gönderilen e-postalar, oluşturulan tutanaklar ve otomatik veya manuel olarak tutulan log kayıtları mevcut olmalıdır.: Not Compliant

    Kanıtların Tutarlılığı ve Erişilebilirliği: Süreç boyunca oluşturulan belgelerin, denetim ve değerlendirme aşamalarında kolayca erişilebilir olması ve güvenilir bilgi kaynağı sunması gerekmektedir.: Not Compliant

    Prosedür Uyumluluğunun Doğrulanması: İlgili e-posta, tutanak ve log kayıtları, örnek prosedüre uygun hareket edildiğini doğrulayan temel unsurlardır. Bu belgeler, süreç performansının analizi, hataların belirlenmesi ve iyileştirmelerin planlanması açısından kritik rol oynar.: Not Compliant

    Kimlik Erişim ve Yetkilendirme Kılavuzu Hazırlanmalıdır: Organizasyonda kimlik erişim ve yetkilendirme süreçlerini açıklayan bir kılavuz dokümanı hazırlanmalıdır. Bu kılavuz, erişim politikalarını, yetkilendirme süreçlerini ve ilgili prosedürleri içermelidir.: Not Compliant

    Revizyon Tarihi: Her doküman, revizyon tarihini içermelidir. Revizyon tarihinin içinde, belgenin geçerli uygulama yılının (2025) yer alması gerekmektedir.: Not Compliant

    Klasör Altında Bulunması Gereken Excel Dokümanı: İlgili klasör içerisinde, doküman envanteri, kimlik bilgileri ve risk yönetimi bilgilerinin yer aldığı tek bir Excel dosyası bulunmalıdır.: Not Compliant

    Excel Dosyasında Kapak Sayfası ve Güncel Revizyon Tarihi: Excel dosyası ilk açılışta kullanıcılara genel bilgi sağlayan bir kapak (cover) sheet içermelidir. Bu sayfada dokümanın revizyon tarihi açıkça belirtilmeli ve güncel olmalıdır.: Not Compliant

    Bilgi Sistemleri Kimlik Bilgileri Envanteri ve Risk Seviyeleri: Excel dosyası içerisinde, işletme veya sistem kimlik bilgilerini içeren bir envanter yer almalıdır. Bu envanterde, ilgili kimlik bilgilerinin risk seviyeleri de belirgin şekilde listelenmelidir.: Not Compliant

    Bilgi Sistemleri Kimlik Bilgileri Riskleri ve Mitigation Planları: Excel dokümanında, BS kimlik bilgilerine ilişkin riskler listelenmeli ve her risk için uygulanacak veya uygulanmış risk azaltıcı (mitigation) planları yer almalıdır.: Not Compliant

    Mitigation Planlarının İlgili Yıl İçinde Revize Edilmiş Olması: Risk azaltıcı planların etkinliğini sağlamak amacıyla, planların belirlenen ilgili yıl içerisinde gözden geçirilmiş (revize edilmiş) olması gerekmektedir.: Not Compliant

    Matrisin Amacı ve Kapsamı: Bu matris, organizasyondaki uygulama (app.), işletim sistemi (OS) ve veritabanı (DB) varlıklarına, farklı roller ve profiller bazında erişim yetkilerini belgelemek ve yönetmek amacıyla oluşturulur.: Not Compliant

    Matrisin Temel Bileşenleri: Roller/Profiller: Organizasyondaki her bir rol ve profil (örneğin; Sistem Yöneticisi, Veri Tabanı Yöneticisi, Uygulama Geliştiricisi, Denetçi vb.) ayrı sütun veya satır olarak tanımlanmalı.: Not Compliant

    Matrisin Temel Bileşenleri: Varlık Tipleri: Uygulama (App): İlgili uygulamalara dair erişim detayları. İşletim Sistemi (OS): Sunucu, masaüstü veya mobil işletim sistemlerine ilişkin erişim bilgileri. Veritabanı (DB): Veritabanı sunucuları, veritabanı kullanıcıları ve erişim düzeyleri.: Not Compliant

    Matrisin Temel Bileşenleri: Erişim Seviyeleri ve Yetkiler: Her varlık için erişim düzeyleri (örneğin; Okuma, Yazma, Güncelleme, Yönetim, vs.) belirlenmelidir. Yetki dağılımı, hangi rolün hangi varlık üzerinde hangi erişim haklarına sahip olduğu açıkça gösterilmelidir.: Not Compliant

    Matris Hazırlama Adımları: Rol ve Profil Tanımlamalarının Belirlenmesi: Organizasyondaki tüm roller ve profiller net bir biçimde tanımlanmalı, açıklanmalı ve matris içinde yer almalıdır. Her rolün görev tanımı ve erişim ihtiyaçları belirlenerek, yetki gereksinimleri oluşturulmalıdır.: Not Compliant

    Matris Hazırlama Adımları: Varlık Envanterinin Oluşturulması: Uygulama, OS ve DB bazında tüm varlıkların envanteri çıkarılmalıdır. Her varlık için erişim noktaları, ilişkili sistemler ve veri bileşenleri listelenmelidir.: Not Compliant

    Matris Hazırlama Adımları: Erişim Seviyelerinin Belirlenmesi: Her varlık için kullanılacak erişim seviyeleri (örn. Görüntüleme, Ekleme, Düzenleme, Silme, Yönetim gibi) tanımlanmalıdır. Her erişim seviyesi için hangi rollerin hangi düzeyde yetkiye sahip olduğu belirlenmeli.: Not Compliant

    Matris Hazırlama Adımları: Matris Şablonunun Oluşturulması: Excel, tablo ya da özel bir erişim kontrol aracı kullanılarak matris oluşturulabilir. Matrisin satırlarında varlıklar (örn. uygulama isimleri, OS sunucuları, veritabanı örnekleri) yer alırken, sütunlarda roller/profiller ve karşılık gelen erişim yetkileri belirtilmelidir. Alternatif olarak, roller/profiller satırda, varlıklar sütunda yer alabilir.: Not Compliant

    Matris Hazırlama Adımları: Onay ve Revizyon Süreçleri: Matris oluşturulduktan sonra ilgili güvenlik, IT ve yöneticiler tarafından onaylanmalıdır. Yetki matrisi belirli aralıklarla (örneğin, yıllık veya yarıyıl) gözden geçirilmeli ve gerekli güncellemeler yapılmalıdır.: Not Compliant

                `
        ,
        "BS politika, prosedür ve süreç dokümanlarının yılda en az bir defa gözden geçirildiğine dair doküman":
            `
          Kurallara uyum durumu:

    Doküman Kodu: Her dokümanda, dokümanın içeriğini, kapsamını veya uygulama alanını belirten benzersiz bir doküman kodu bulunmalıdır.: Not Compliant

    Revizyon Tarihi: Her doküman, revizyon tarihini içermelidir. Revizyon tarihinin içinde, belgenin geçerli uygulama yılının (2025) yer alması gerekmektedir.: Not Compliant

    Doküman Envanterinin Güncellenmesi ve Klasör Yapısına Kopyalama: Tüm dokümanların, doküman envanterinde yer aldığı gibi, ilgili klasör altına kopyalanarak düzenli bir şekilde saklanması gerekmektedir.: Not Compliant

    Yönetim Kurulu Kararı ile Politikaların Gözden Geçirildiğine Dair Scan Copy: Politikaların gözden geçirildiğine dair, Yönetim Kurulu’nun onayının yer aldığı dokümana ait scan copy (taranmış kopya) belgelenmelidir.: Not Compliant

    Yönetim Kurulu Onay Sürecine Dair Prosedür ve Kılavuzların Durumu: Yönetim Kurulu tarafından, prosedür ve kılavuzların onayının ilgili iş birimine bırakıldığı veya onaylandığına dair kararın scan copy’si mevcut olmalıdır.: Not Compliant

   
        `
        ,
        "Bilgi Sistemleri Kimlik Doğrulama Yöntemleri Risk Değerlendirme Çalışması": `
          Kurallara uyum durumu:

    Gizlilik Seviyesi: Her dokümanda, belgenin gizlilik seviyesi net bir şekilde ifade edilmelidir.: Not Compliant

    Hazırlayan ve Kullanıcı Bilgileri: Dokümanın hazırlanmasında görev alan kişi veya departmanın yanı sıra, dokümanın kullanımındaki birimlerin (kullanıcıların) bilgileri de belirtilmelidir.: Not Compliant

    Kimlik Erişim ve Yetkilendirme Kılavuzu Hazırlanmalıdır: Organizasyonda kimlik erişim ve yetkilendirme süreçlerini açıklayan bir kılavuz dokümanı hazırlanmalıdır. Bu kılavuz, erişim politikalarını, yetkilendirme süreçlerini ve ilgili prosedürleri içermelidir.: Not Compliant

    Doküman Kodu: Her dokümanda, dokümanın içeriğini, kapsamını veya uygulama alanını belirten benzersiz bir doküman kodu bulunmalıdır.: Not Compliant

    Revizyon Tarihi: Her doküman, revizyon tarihini içermelidir. Revizyon tarihinin içinde, belgenin geçerli uygulama yılının (2025) yer alması gerekmektedir.: Not Compliant

    Klasör Altında Bulunması Gereken Excel Dokümanı: İlgili klasör içerisinde, doküman envanteri, kimlik bilgileri ve risk yönetimi bilgilerinin yer aldığı tek bir Excel dosyası bulunmalıdır.: Not Compliant

    Excel Dosyasında Kapak Sayfası ve Güncel Revizyon Tarihi: Excel dosyası ilk açılışta kullanıcılara genel bilgi sağlayan bir kapak (cover) sheet içermelidir. Bu sayfada dokümanın revizyon tarihi açıkça belirtilmeli ve güncel olmalıdır.: Not Compliant

    Bilgi Sistemleri Kimlik Bilgileri Envanteri ve Risk Seviyeleri: Excel dosyası içerisinde, işletme veya sistem kimlik bilgilerini içeren bir envanter yer almalıdır. Bu envanterde, ilgili kimlik bilgilerinin risk seviyeleri de belirgin şekilde listelenmelidir.: Not Compliant

    Bilgi Sistemleri Kimlik Bilgileri Riskleri ve Mitigation Planları: Excel dokümanında, BS kimlik bilgilerine ilişkin riskler listelenmeli ve her risk için uygulanacak veya uygulanmış risk azaltıcı (mitigation) planları yer almalıdır.: Not Compliant

    Mitigation Planlarının İlgili Yıl İçinde Revize Edilmiş Olması: Risk azaltıcı planların etkinliğini sağlamak amacıyla, planların belirlenen ilgili yıl içerisinde gözden geçirilmiş (revize edilmiş) olması gerekmektedir.: Not Compliant
      Kurallara uyum durumu:

    Gizlilik Seviyesi: Her dokümanda, belgenin gizlilik seviyesi net bir şekilde ifade edilmelidir.: Not Compliant

    Hazırlayan ve Kullanıcı Bilgileri: Dokümanın hazırlanmasında görev alan kişi veya departmanın yanı sıra, dokümanın kullanımındaki birimlerin (kullanıcıların) bilgileri de belirtilmelidir.: Not Compliant

    Tüm screenshotlarda windows ise sağ alt köşedeki tarih ve saat bilgisi macos ise sol üst köşedeki tarih ve saat bilgisi görünecek şekilde tam ekran olmalıdır.: Not Compliant

    Ekran görüntüsünde liste varsa ilk ve son kayıtların görünecek şekilde görüntü alınmalıdır.: Not Compliant

    Yönetim Kurulu tarihi denetim yapılan yıla ait olmalıdır.: Not Compliant

    Yönetim kurulu üyelerinin imzalarının tam olması gereklidir.: Not Compliant

    İlgili soruda belirtilen konuyu kapsaması gereklidir. Örneğin bu madde için 'BT Politikaları Yönetim kurulunda değerlendirilmiş ve onaylanmıştır.' gibi bir ifade aranır.: Not Compliant

    Doküman Kodu: Her dokümanda, dokümanın içeriğini, kapsamını veya uygulama alanını belirten benzersiz bir doküman kodu bulunmalıdır.: Not Compliant

    Kanıt Niteliğinde Belgeler: Prosedürün doğru ve eksiksiz uygulandığının belgelenebilmesi için, ilgili süreç boyunca gönderilen e-postalar, oluşturulan tutanaklar ve otomatik veya manuel olarak tutulan log kayıtları mevcut olmalıdır.: Not Compliant

    Kanıtların Tutarlılığı ve Erişilebilirliği: Süreç boyunca oluşturulan belgelerin, denetim ve değerlendirme aşamalarında kolayca erişilebilir olması ve güvenilir bilgi kaynağı sunması gerekmektedir.: Not Compliant

    Prosedür Uyumluluğunun Doğrulanması: İlgili e-posta, tutanak ve log kayıtları, örnek prosedüre uygun hareket edildiğini doğrulayan temel unsurlardır. Bu belgeler, süreç performansının analizi, hataların belirlenmesi ve iyileştirmelerin planlanması açısından kritik rol oynar.: Not Compliant

    Kimlik Erişim ve Yetkilendirme Kılavuzu Hazırlanmalıdır: Organizasyonda kimlik erişim ve yetkilendirme süreçlerini açıklayan bir kılavuz dokümanı hazırlanmalıdır. Bu kılavuz, erişim politikalarını, yetkilendirme süreçlerini ve ilgili prosedürleri içermelidir.: Not Compliant

    Revizyon Tarihi: Her doküman, revizyon tarihini içermelidir. Revizyon tarihinin içinde, belgenin geçerli uygulama yılının (2025) yer alması gerekmektedir.: Not Compliant

    Klasör Altında Bulunması Gereken Excel Dokümanı: İlgili klasör içerisinde, doküman envanteri, kimlik bilgileri ve risk yönetimi bilgilerinin yer aldığı tek bir Excel dosyası bulunmalıdır.: Not Compliant

    Excel Dosyasında Kapak Sayfası ve Güncel Revizyon Tarihi: Excel dosyası ilk açılışta kullanıcılara genel bilgi sağlayan bir kapak (cover) sheet içermelidir. Bu sayfada dokümanın revizyon tarihi açıkça belirtilmeli ve güncel olmalıdır.: Not Compliant

    Bilgi Sistemleri Kimlik Bilgileri Envanteri ve Risk Seviyeleri: Excel dosyası içerisinde, işletme veya sistem kimlik bilgilerini içeren bir envanter yer almalıdır. Bu envanterde, ilgili kimlik bilgilerinin risk seviyeleri de belirgin şekilde listelenmelidir.: Not Compliant

    Bilgi Sistemleri Kimlik Bilgileri Riskleri ve Mitigation Planları: Excel dokümanında, BS kimlik bilgilerine ilişkin riskler listelenmeli ve her risk için uygulanacak veya uygulanmış risk azaltıcı (mitigation) planları yer almalıdır.: Not Compliant

    Mitigation Planlarının İlgili Yıl İçinde Revize Edilmiş Olması: Risk azaltıcı planların etkinliğini sağlamak amacıyla, planların belirlenen ilgili yıl içerisinde gözden geçirilmiş (revize edilmiş) olması gerekmektedir.: Not Compliant

    Matrisin Amacı ve Kapsamı: Bu matris, organizasyondaki uygulama (app.), işletim sistemi (OS) ve veritabanı (DB) varlıklarına, farklı roller ve profiller bazında erişim yetkilerini belgelemek ve yönetmek amacıyla oluşturulur.: Not Compliant

    Matrisin Temel Bileşenleri: Roller/Profiller: Organizasyondaki her bir rol ve profil (örneğin; Sistem Yöneticisi, Veri Tabanı Yöneticisi, Uygulama Geliştiricisi, Denetçi vb.) ayrı sütun veya satır olarak tanımlanmalı.: Not Compliant

    Matrisin Temel Bileşenleri: Varlık Tipleri: Uygulama (App): İlgili uygulamalara dair erişim detayları. İşletim Sistemi (OS): Sunucu, masaüstü veya mobil işletim sistemlerine ilişkin erişim bilgileri. Veritabanı (DB): Veritabanı sunucuları, veritabanı kullanıcıları ve erişim düzeyleri.: Not Compliant

    Matrisin Temel Bileşenleri: Erişim Seviyeleri ve Yetkiler: Her varlık için erişim düzeyleri (örneğin; Okuma, Yazma, Güncelleme, Yönetim, vs.) belirlenmelidir. Yetki dağılımı, hangi rolün hangi varlık üzerinde hangi erişim haklarına sahip olduğu açıkça gösterilmelidir.: Not Compliant

    Matris Hazırlama Adımları: Rol ve Profil Tanımlamalarının Belirlenmesi: Organizasyondaki tüm roller ve profiller net bir biçimde tanımlanmalı, açıklanmalı ve matris içinde yer almalıdır. Her rolün görev tanımı ve erişim ihtiyaçları belirlenerek, yetki gereksinimleri oluşturulmalıdır.: Not Compliant

    Matris Hazırlama Adımları: Varlık Envanterinin Oluşturulması: Uygulama, OS ve DB bazında tüm varlıkların envanteri çıkarılmalıdır. Her varlık için erişim noktaları, ilişkili sistemler ve veri bileşenleri listelenmelidir.: Not Compliant

    Matris Hazırlama Adımları: Erişim Seviyelerinin Belirlenmesi: Her varlık için kullanılacak erişim seviyeleri (örn. Görüntüleme, Ekleme, Düzenleme, Silme, Yönetim gibi) tanımlanmalıdır. Her erişim seviyesi için hangi rollerin hangi düzeyde yetkiye sahip olduğu belirlenmeli.: Not Compliant

    Matris Hazırlama Adımları: Matris Şablonunun Oluşturulması: Excel, tablo ya da özel bir erişim kontrol aracı kullanılarak matris oluşturulabilir. Matrisin satırlarında varlıklar (örn. uygulama isimleri, OS sunucuları, veritabanı örnekleri) yer alırken, sütunlarda roller/profiller ve karşılık gelen erişim yetkileri belirtilmelidir. Alternatif olarak, roller/profiller satırda, varlıklar sütunda yer alabilir.: Not Compliant

    Matris Hazırlama Adımları: Onay ve Revizyon Süreçleri: Matris oluşturulduktan sonra ilgili güvenlik, IT ve yöneticiler tarafından onaylanmalıdır. Yetki matrisi belirli aralıklarla (örneğin, yıllık veya yarıyıl) gözden geçirilmeli ve gerekli güncellemeler yapılmalıdır.: Not Compliant
   `
        ,
        "Rol ve profil bazlı varlıklara (app., os ve db) erişim yetki matrisi":
            `
          Kurallara uyum durumu:

    Gizlilik Seviyesi: Her dokümanda, belgenin gizlilik seviyesi net bir şekilde ifade edilmelidir.: Not Compliant

    Hazırlayan ve Kullanıcı Bilgileri: Dokümanın hazırlanmasında görev alan kişi veya departmanın yanı sıra, dokümanın kullanımındaki birimlerin (kullanıcıların) bilgileri de belirtilmelidir.: Not Compliant

    Kimlik Erişim ve Yetkilendirme Kılavuzu Hazırlanmalıdır: Organizasyonda kimlik erişim ve yetkilendirme süreçlerini açıklayan bir kılavuz dokümanı hazırlanmalıdır. Bu kılavuz, erişim politikalarını, yetkilendirme süreçlerini ve ilgili prosedürleri içermelidir.: Not Compliant

    Matrisin Amacı ve Kapsamı: Bu matris, organizasyondaki uygulama (app.), işletim sistemi (OS) ve veritabanı (DB) varlıklarına, farklı roller ve profiller bazında erişim yetkilerini belgelemek ve yönetmek amacıyla oluşturulur.: Not Compliant

    Matrisin Temel Bileşenleri: Roller/Profiller: Organizasyondaki her bir rol ve profil (örneğin; Sistem Yöneticisi, Veri Tabanı Yöneticisi, Uygulama Geliştiricisi, Denetçi vb.) ayrı sütun veya satır olarak tanımlanmalı.: Not Compliant

    Matrisin Temel Bileşenleri: Varlık Tipleri: Uygulama (App): İlgili uygulamalara dair erişim detayları. İşletim Sistemi (OS): Sunucu, masaüstü veya mobil işletim sistemlerine ilişkin erişim bilgileri. Veritabanı (DB): Veritabanı sunucuları, veritabanı kullanıcıları ve erişim düzeyleri.: Not Compliant

    Matrisin Temel Bileşenleri: Erişim Seviyeleri ve Yetkiler: Her varlık için erişim düzeyleri (örneğin; Okuma, Yazma, Güncelleme, Yönetim, vs.) belirlenmelidir. Yetki dağılımı, hangi rolün hangi varlık üzerinde hangi erişim haklarına sahip olduğu açıkça gösterilmelidir.: Not Compliant

    Matris Hazırlama Adımları: Rol ve Profil Tanımlamalarının Belirlenmesi: Organizasyondaki tüm roller ve profiller net bir biçimde tanımlanmalı, açıklanmalı ve matris içinde yer almalıdır. Her rolün görev tanımı ve erişim ihtiyaçları belirlenerek, yetki gereksinimleri oluşturulmalıdır.: Not Compliant

    Matris Hazırlama Adımları: Varlık Envanterinin Oluşturulması: Uygulama, OS ve DB bazında tüm varlıkların envanteri çıkarılmalıdır. Her varlık için erişim noktaları, ilişkili sistemler ve veri bileşenleri listelenmelidir.: Not Compliant

    Matris Hazırlama Adımları: Erişim Seviyelerinin Belirlenmesi: Her varlık için kullanılacak erişim seviyeleri (örn. Görüntüleme, Ekleme, Düzenleme, Silme, Yönetim gibi) tanımlanmalıdır. Her erişim seviyesi için hangi rollerin hangi düzeyde yetkiye sahip olduğu belirlenmeli.: Not Compliant

    Matris Hazırlama Adımları: Matris Şablonunun Oluşturulması: Excel, tablo ya da özel bir erişim kontrol aracı kullanılarak matris oluşturulabilir. Matrisin satırlarında varlıklar (örn. uygulama isimleri, OS sunucuları, veritabanı örnekleri) yer alırken, sütunlarda roller/profiller ve karşılık gelen erişim yetkileri belirtilmelidir. Alternatif olarak, roller/profiller satırda, varlıklar sütunda yer alabilir.: Not Compliant

    Matris Hazırlama Adımları: Onay ve Revizyon Süreçleri: Matris oluşturulduktan sonra ilgili güvenlik, IT ve yöneticiler tarafından onaylanmalıdır. Yetki matrisi belirli aralıklarla (örneğin, yıllık veya yarıyıl) gözden geçirilmeli ve gerekli güncellemeler yapılmalıdır.: Not Compliant

  Kurallara uyum durumu:

    Gizlilik Seviyesi: Her dokümanda, belgenin gizlilik seviyesi net bir şekilde ifade edilmelidir.: Not Compliant

    Hazırlayan ve Kullanıcı Bilgileri: Dokümanın hazırlanmasında görev alan kişi veya departmanın yanı sıra, dokümanın kullanımındaki birimlerin (kullanıcıların) bilgileri de belirtilmelidir.: Not Compliant

    Tüm screenshotlarda windows ise sağ alt köşedeki tarih ve saat bilgisi macos ise sol üst köşedeki tarih ve saat bilgisi görünecek şekilde tam ekran olmalıdır.: Not Compliant

    Ekran görüntüsünde liste varsa ilk ve son kayıtların görünecek şekilde görüntü alınmalıdır.: Not Compliant

    Kimlik Erişim ve Yetkilendirme Kılavuzu Hazırlanmalıdır: Organizasyonda kimlik erişim ve yetkilendirme süreçlerini açıklayan bir kılavuz dokümanı hazırlanmalıdır. Bu kılavuz, erişim politikalarını, yetkilendirme süreçlerini ve ilgili prosedürleri içermelidir.: Not Compliant

      Kurallara uyum durumu:

    Gizlilik Seviyesi: Her dokümanda, belgenin gizlilik seviyesi net bir şekilde ifade edilmelidir.: Not Compliant

    Hazırlayan ve Kullanıcı Bilgileri: Dokümanın hazırlanmasında görev alan kişi veya departmanın yanı sıra, dokümanın kullanımındaki birimlerin (kullanıcıların) bilgileri de belirtilmelidir.: Not Compliant

    Tüm screenshotlarda windows ise sağ alt köşedeki tarih ve saat bilgisi macos ise sol üst köşedeki tarih ve saat bilgisi görünecek şekilde tam ekran olmalıdır.: Not Compliant

    Ekran görüntüsünde liste varsa ilk ve son kayıtların görünecek şekilde görüntü alınmalıdır.: Not Compliant

    Yönetim Kurulu tarihi denetim yapılan yıla ait olmalıdır.: Not Compliant

    Yönetim kurulu üyelerinin imzalarının tam olması gereklidir.: Not Compliant

    İlgili soruda belirtilen konuyu kapsaması gereklidir. Örneğin bu madde için 'BT Politikaları Yönetim kurulunda değerlendirilmiş ve onaylanmıştır.' gibi bir ifade aranır.: Not Compliant

    Doküman Kodu: Her dokümanda, dokümanın içeriğini, kapsamını veya uygulama alanını belirten benzersiz bir doküman kodu bulunmalıdır.: Not Compliant

    Kanıt Niteliğinde Belgeler: Prosedürün doğru ve eksiksiz uygulandığının belgelenebilmesi için, ilgili süreç boyunca gönderilen e-postalar, oluşturulan tutanaklar ve otomatik veya manuel olarak tutulan log kayıtları mevcut olmalıdır.: Not Compliant

    Kanıtların Tutarlılığı ve Erişilebilirliği: Süreç boyunca oluşturulan belgelerin, denetim ve değerlendirme aşamalarında kolayca erişilebilir olması ve güvenilir bilgi kaynağı sunması gerekmektedir.: Not Compliant

    Prosedür Uyumluluğunun Doğrulanması: İlgili e-posta, tutanak ve log kayıtları, örnek prosedüre uygun hareket edildiğini doğrulayan temel unsurlardır. Bu belgeler, süreç performansının analizi, hataların belirlenmesi ve iyileştirmelerin planlanması açısından kritik rol oynar.: Not Compliant

    Kimlik Erişim ve Yetkilendirme Kılavuzu Hazırlanmalıdır: Organizasyonda kimlik erişim ve yetkilendirme süreçlerini açıklayan bir kılavuz dokümanı hazırlanmalıdır. Bu kılavuz, erişim politikalarını, yetkilendirme süreçlerini ve ilgili prosedürleri içermelidir.: Not Compliant

    Revizyon Tarihi: Her doküman, revizyon tarihini içermelidir. Revizyon tarihinin içinde, belgenin geçerli uygulama yılının (2025) yer alması gerekmektedir.: Not Compliant

    Klasör Altında Bulunması Gereken Excel Dokümanı: İlgili klasör içerisinde, doküman envanteri, kimlik bilgileri ve risk yönetimi bilgilerinin yer aldığı tek bir Excel dosyası bulunmalıdır.: Not Compliant

    Excel Dosyasında Kapak Sayfası ve Güncel Revizyon Tarihi: Excel dosyası ilk açılışta kullanıcılara genel bilgi sağlayan bir kapak (cover) sheet içermelidir. Bu sayfada dokümanın revizyon tarihi açıkça belirtilmeli ve güncel olmalıdır.: Not Compliant

    Bilgi Sistemleri Kimlik Bilgileri Envanteri ve Risk Seviyeleri: Excel dosyası içerisinde, işletme veya sistem kimlik bilgilerini içeren bir envanter yer almalıdır. Bu envanterde, ilgili kimlik bilgilerinin risk seviyeleri de belirgin şekilde listelenmelidir.: Not Compliant

    Bilgi Sistemleri Kimlik Bilgileri Riskleri ve Mitigation Planları: Excel dokümanında, BS kimlik bilgilerine ilişkin riskler listelenmeli ve her risk için uygulanacak veya uygulanmış risk azaltıcı (mitigation) planları yer almalıdır.: Not Compliant

    Mitigation Planlarının İlgili Yıl İçinde Revize Edilmiş Olması: Risk azaltıcı planların etkinliğini sağlamak amacıyla, planların belirlenen ilgili yıl içerisinde gözden geçirilmiş (revize edilmiş) olması gerekmektedir.: Not Compliant

    Matrisin Amacı ve Kapsamı: Bu matris, organizasyondaki uygulama (app.), işletim sistemi (OS) ve veritabanı (DB) varlıklarına, farklı roller ve profiller bazında erişim yetkilerini belgelemek ve yönetmek amacıyla oluşturulur.: Not Compliant

    Matrisin Temel Bileşenleri: Roller/Profiller: Organizasyondaki her bir rol ve profil (örneğin; Sistem Yöneticisi, Veri Tabanı Yöneticisi, Uygulama Geliştiricisi, Denetçi vb.) ayrı sütun veya satır olarak tanımlanmalı.: Not Compliant

    Matrisin Temel Bileşenleri: Varlık Tipleri: Uygulama (App): İlgili uygulamalara dair erişim detayları. İşletim Sistemi (OS): Sunucu, masaüstü veya mobil işletim sistemlerine ilişkin erişim bilgileri. Veritabanı (DB): Veritabanı sunucuları, veritabanı kullanıcıları ve erişim düzeyleri.: Not Compliant

    Matrisin Temel Bileşenleri: Erişim Seviyeleri ve Yetkiler: Her varlık için erişim düzeyleri (örneğin; Okuma, Yazma, Güncelleme, Yönetim, vs.) belirlenmelidir. Yetki dağılımı, hangi rolün hangi varlık üzerinde hangi erişim haklarına sahip olduğu açıkça gösterilmelidir.: Not Compliant

    Matris Hazırlama Adımları: Rol ve Profil Tanımlamalarının Belirlenmesi: Organizasyondaki tüm roller ve profiller net bir biçimde tanımlanmalı, açıklanmalı ve matris içinde yer almalıdır. Her rolün görev tanımı ve erişim ihtiyaçları belirlenerek, yetki gereksinimleri oluşturulmalıdır.: Not Compliant

    Matris Hazırlama Adımları: Varlık Envanterinin Oluşturulması: Uygulama, OS ve DB bazında tüm varlıkların envanteri çıkarılmalıdır. Her varlık için erişim noktaları, ilişkili sistemler ve veri bileşenleri listelenmelidir.: Not Compliant

    Matris Hazırlama Adımları: Erişim Seviyelerinin Belirlenmesi: Her varlık için kullanılacak erişim seviyeleri (örn. Görüntüleme, Ekleme, Düzenleme, Silme, Yönetim gibi) tanımlanmalıdır. Her erişim seviyesi için hangi rollerin hangi düzeyde yetkiye sahip olduğu belirlenmeli.: Not Compliant

    Matris Hazırlama Adımları: Matris Şablonunun Oluşturulması: Excel, tablo ya da özel bir erişim kontrol aracı kullanılarak matris oluşturulabilir. Matrisin satırlarında varlıklar (örn. uygulama isimleri, OS sunucuları, veritabanı örnekleri) yer alırken, sütunlarda roller/profiller ve karşılık gelen erişim yetkileri belirtilmelidir. Alternatif olarak, roller/profiller satırda, varlıklar sütunda yer alabilir.: Not Compliant

    Matris Hazırlama Adımları: Onay ve Revizyon Süreçleri: Matris oluşturulduktan sonra ilgili güvenlik, IT ve yöneticiler tarafından onaylanmalıdır. Yetki matrisi belirli aralıklarla (örneğin, yıllık veya yarıyıl) gözden geçirilmeli ve gerekli güncellemeler yapılmalıdır.: Not Compliant

        `
    };



    return (
        <Grid container spacing={2} size={12}>
            <Grid size={12}>
                <div style={{ display: "flex" }}>
                    <Tooltip title={t("task.copy_ai_suggestion")}>
                        <IconButton onClick={() => { onSaveResponse(allResponse[task] ?? "") }}>
                            <ReplyAllIcon />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title={t("task.new_suggestion")}>
                        <IconButton onClick={() => { setNum(num + 1) }}>
                            <SmartToyIcon fontSize="small" style={{ color: "darkslateblue" }} />
                        </IconButton>
                    </Tooltip>
                </div>
            </Grid>
            <Grid size={12}>
                <TextField
                    label={t("task.ai_suggestion_response")}
                    value={allResponse[task] ?? ""}
                    fullWidth
                    multiline
                    minRows={3}
                    maxRows={20} />
            </Grid>
        </Grid>
    )
}

export default AIResponseSuggestion;