import { Paper, Typography, Table, TableBody, TableHead, TableRow, TableCell, Button, Box, IconButton, TextField, InputAdornment } from "@mui/material";
import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid2";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import DocumentDrawer from "./DocumentDrawer";
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from "react-i18next";
import { TaskManagementService } from "../../../services/api";
import { PathItem } from "../../Settings/PathsList";
import { WizebuddyService } from "../../../services/api";
import { WDocument } from "../../../models/Wizebuddy/Document";

const DocumentComponent: React.FC = () => {

    const [openDocumentAdd, setOpenDocumentAdd] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');
    const { t } = useTranslation('global');
    const [allDocument, setAllDocument] = useState<WDocument[]>([]);


    const getAllDocument = async () => {

        try {

            const response = await WizebuddyService.get('document', "json");
            setAllDocument(response as WDocument[]);

        } catch (error) {
            console.log("get document error -> ", error);
        }

    }

    useEffect(() => {
        getAllDocument();
    }, [])

    return (
        <Paper>
            <Grid size={12} container>

                <DocumentDrawer
                    open={openDocumentAdd}
                    onClose={() => { setOpenDocumentAdd(!openDocumentAdd); getAllDocument(); }}
                />

            </Grid>

            <Grid container size={12}>

                <Grid container size={12} sx={{ margin: 5 }}>
                    <Typography variant="h4" sx={{ color: "#0b2343", marginTop: "15px" }}>
                        {t('wizebuddy_document.document_management')}
                    </Typography>
                </Grid>


                <Grid size={12} sx={{ marginRight: 5, marginLeft: 5 }} container>

                    <Grid size={6}>

                        <TextField
                            label={t('task.search')}
                            fullWidth
                            value={search}
                            onChange={(e) => { setSearch(e.target.value) }}
                            slotProps={{
                                input: {
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                edge="end"
                                            >
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                },
                            }}
                        />
                    </Grid>

                    <Grid size={6} display="flex" justifyContent="flex-end">
                        <Button size="small" onClick={() => { setOpenDocumentAdd(!openDocumentAdd) }} variant="contained" style={{ height: "5vh", backgroundColor: "#0b2343", color: "white", textTransform: "none" }}>{t('wizebuddy_document.add_document')}</Button>
                    </Grid>

                </Grid>

                <Grid size={12} sx={{ margin: 5 }}>
                    <Table sx={{ border: "10px solid whitesmoke", marginBottom: 5 }}>
                        <TableHead style={{ backgroundColor: "whitesmoke" }}>
                            <TableCell sx={{ fontWeight: "bold" }}>
                                {t('wizebuddy_document.name')}
                            </TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>
                                {t('wizebuddy_document.content')}
                            </TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>
                                {t('wizebuddy_document.version')}
                            </TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>
                                {t('wizebuddy_document.upload_date')}
                            </TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>
                                {t('wizebuddy_categories.actions')}
                            </TableCell>
                        </TableHead>
                        <TableBody>
                            {allDocument.map((doc, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell>{doc.name}</TableCell>
                                        <TableCell>{doc.content}</TableCell>
                                        <TableCell>{doc.version}</TableCell>
                                        <TableCell>{doc.createdAt.split('T')[0]}</TableCell>
                                        <TableCell>
                                            <Box>
                                                <IconButton onClick={() => { }}>
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton onClick={() => { }}>
                                                    <DeleteIcon style={{ color: "red" }} />
                                                </IconButton>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}

                        </TableBody>
                    </Table>

                </Grid>

            </Grid>

        </Paper>
    )
}

export default DocumentComponent;
