import React, { useState, useEffect } from 'react';
import { Typography, TextField } from '@mui/material';
import Grid from '@mui/material/Grid2';
import TaskDatePicker from './TaskDatePicker';
import TaskUserSelect from './TaskUserSelect';
import { useTranslation } from 'react-i18next';
import TaskLabelDetail from './TaskLabelDetail';
import { TaskLabelModel } from '../../../models/Task/Pano';

// Define the interface for props
interface TaskUserDetailProps {
  selectedTicketOwner: string;
  selectedTicketOwnerManager: string;
  selectedInternalAuditor: string;
  selectedIndependentAuditor: string;
  targetDate: Date | null;
  requestDate: Date | null;
  creationDate: Date | null;
  lastUpdate: Date | null;
  onTicketOwnerChange: (newTicketOwner: string) => void;
  onTicketOwnerManagerChange: (newTicketOwner: string) => void;
  onInternalAuditorChange: (newInternalAuditor: string) => void;
  onIndependentAuditorChange: (newIndependentAuditor: string) => void;
  onTargetDateChange: (newTargetDate: Date | null) => void;
  disabled: boolean;
  responsiblePermission: boolean;
  approverPermission: boolean;
  internalAuditorPermission: boolean;
  independentAuditorPermission: boolean;
  targetDatePermission: boolean;
  visibleResponsible: boolean;
  visibleApprover: boolean;
  visibleInternalAuditor: boolean;
  visibleIndependentAuditor: boolean;
  visibleTargetDate: boolean;
  visibleRequestDate: boolean;
  visibleCreationDate: boolean;
  visibleLastUpdate: boolean;
  taskLabels: string[];
  projectLabels: TaskLabelModel[];
  includeIDA: boolean;
}

const TaskUserDetail: React.FC<TaskUserDetailProps> = ({
  selectedTicketOwner,
  selectedTicketOwnerManager,
  selectedInternalAuditor,
  selectedIndependentAuditor,
  targetDate,
  requestDate,
  creationDate,
  lastUpdate,
  disabled,
  onTicketOwnerChange,
  onTicketOwnerManagerChange,
  onInternalAuditorChange,
  onIndependentAuditorChange,
  onTargetDateChange,
  responsiblePermission,
  approverPermission,
  internalAuditorPermission,
  independentAuditorPermission,
  targetDatePermission,
  visibleResponsible,
  visibleApprover,
  visibleInternalAuditor,
  visibleIndependentAuditor,
  visibleTargetDate,
  visibleRequestDate,
  visibleCreationDate,
  visibleLastUpdate,
  taskLabels,
  projectLabels,
  includeIDA,
}) => {
  const [ticketOwner, setTicketOwner] = useState<string>('');
  const [ticketOwnerManager, setTicketOwnerManager] = useState<string>('');
  const [internalAuditor, setInternalAuditor] = useState<string>('');
  const [independentAuditor, setIndependentAuditor] = useState<string>('');
  const [targDate, setTargDate] = useState<Date | null>();
  const [reqtDate, setRequestDate] = useState<Date | null>();
  const [creaDate, setCreationDate] = useState<Date | null>();
  const [laUpdate, setLastUpdate] = useState<Date | null>();
  const { t } = useTranslation('global');

  useEffect(() => {
    setTicketOwner(selectedTicketOwner || '');
    setTicketOwnerManager(selectedTicketOwnerManager || '');
    setInternalAuditor(selectedInternalAuditor || '');
    setIndependentAuditor(selectedIndependentAuditor || '');
    setTargDate(targetDate || null);
    setRequestDate(requestDate || null);
    setCreationDate(creationDate || null);
    setLastUpdate(lastUpdate || null);
  }, [
    selectedTicketOwner,
    selectedTicketOwnerManager,
    selectedInternalAuditor,
    selectedIndependentAuditor,
    targetDate,
    requestDate,
    creationDate,
    lastUpdate,
  ]);

  const handleTicketOwnerChange = (newTicketOwner: string) => {
    setTicketOwner(newTicketOwner);
    onTicketOwnerChange(newTicketOwner);
  };

  const handleTicketOwnerManagerChange = (newTicketOwnerManager: string) => {
    setTicketOwnerManager(newTicketOwnerManager);
    onTicketOwnerManagerChange(newTicketOwnerManager);
  };

  const handleInternalAuditorChange = (newInternalAuditor: string) => {
    setInternalAuditor(newInternalAuditor);
    onInternalAuditorChange(newInternalAuditor);
  };

  const handleIndependentAuditorChange = (newIndependentAuditor: string) => {
    setIndependentAuditor(newIndependentAuditor);
    onIndependentAuditorChange(newIndependentAuditor);
  };

  const handleTargetDate = (newTargetDate: Date | null) => {
    setTargDate(newTargetDate);
    onTargetDateChange(newTargetDate);
  };

  return (
    <Grid container columns={12}>
      <Grid container columnSpacing={2} size={12}>
        <Grid container size={6} alignItems="center">
          {!visibleResponsible ? (
            <Typography variant="body1">{t('task.responsible')}</Typography>
          ) : null}
        </Grid>

        <Grid size={6}>
          {!visibleResponsible ? (
            <TaskUserSelect
              user={ticketOwner}
              onTaskUserChange={(newUser) => {
                handleTicketOwnerChange(newUser);
              }}
              permission={responsiblePermission}
              show={true}
              filter='Participant'
            />
          ) : null}
        </Grid>
      </Grid>

      {disabled ? null : (
        <>
          <Grid container columnSpacing={2} size={12}>
            <Grid container size={6} alignItems="center">
              {!visibleApprover ? (
                <Typography variant="body1">{t('task.approver')}</Typography>
              ) : null}
            </Grid>

            <Grid size={6}>
              {!visibleApprover ? (
                <TaskUserSelect
                  user={ticketOwnerManager}
                  onTaskUserChange={(newUser) => {
                    handleTicketOwnerManagerChange(newUser);
                  }}
                  permission={approverPermission}
                  show={true}
                  filter='Participant'
                />
              ) : null}
            </Grid>
          </Grid>

          <Grid container columnSpacing={2} size={12}>
            <Grid container size={6} alignItems="center">
              {!visibleInternalAuditor ? (
                <Typography variant="body1">{t('task.internal_auditor')}</Typography>
              ) : null}
            </Grid>

            <Grid size={6}>
              {!visibleInternalAuditor ? (
                <TaskUserSelect
                  user={internalAuditor}
                  onTaskUserChange={(newUser) => {
                    handleInternalAuditorChange(newUser);
                  }}
                  permission={internalAuditorPermission}
                  show={true}
                  filter='Participant'
                />
              ) : null}
            </Grid>
          </Grid>

          {includeIDA ? (
            <Grid container columnSpacing={2} size={12}>
              <Grid container size={6} alignItems="center">
                {!visibleIndependentAuditor ? (
                  <Typography variant="body1">{t('project.independent_auditor')}</Typography>
                ) : null}
              </Grid>

              <Grid size={6}>
                {!visibleIndependentAuditor ? (
                  <TaskUserSelect
                    user={independentAuditor}
                    onTaskUserChange={(newUser) => {
                      handleIndependentAuditorChange(newUser);
                    }}
                    permission={independentAuditorPermission}
                    show={true}
                    filter='Independent Auditor'
                  />
                ) : null}
              </Grid>
            </Grid>
          ) : null}


        </>
      )}

      <Grid container columnSpacing={2} size={12} sx={{ marginTop: '10px' }}>
        <Grid container size={6} alignItems="center">
          {!visibleRequestDate ? (
            <Typography variant="body1">{t('task.request_date')}</Typography>
          ) : null}
        </Grid>

        <Grid size={6}>
          {!visibleRequestDate ? (
            <Typography variant="body1">
              <TextField
                type="date"
                disabled={true}
                value={reqtDate ? new Date(reqtDate).toISOString().slice(0, 10) : ""}
                variant="standard"
                slotProps={{
                  input: {
                    disableUnderline: true,
                  },
                }}
              />
            </Typography>
          ) : null}
        </Grid>
      </Grid>

      <Grid container columnSpacing={2} size={12}>
        <Grid container size={6} alignItems="center">
          {!visibleCreationDate ? (
            <Typography variant="body1">{t('task.creation_date')}</Typography>
          ) : null}
        </Grid>

        <Grid size={6}>
          {!visibleCreationDate ? (
            <Typography variant="body1">
              <TextField
                type="date"
                disabled={true}
                value={creaDate ? new Date(creaDate).toISOString().slice(0, 10) : ""}
                variant="standard"
                slotProps={{
                  input: {
                    disableUnderline: true,
                  },
                }}
              />
            </Typography>
          ) : null}
        </Grid>
      </Grid>

      <Grid container columnSpacing={2} size={12}>
        <Grid container size={6} alignItems="center">
          {!visibleTargetDate ? (
            <Typography variant="body1">{t('task.target_date')}</Typography>
          ) : null}
        </Grid>

        <Grid size={6}>
          {!visibleTargetDate ? (
            <TaskDatePicker
              date={targDate || null}
              onTaskDateChange={(newDate) => {
                handleTargetDate(newDate);
              }}
              permission={targetDatePermission}
              minDate={creaDate || new Date()}
            />) : null}
        </Grid>
      </Grid>

      <Grid container columnSpacing={2} size={12}>
        <Grid container size={6} alignItems="center">
          {!visibleLastUpdate ? (
            <Typography variant="body1">{t('task.last_update_date')}</Typography>
          ) : null}
        </Grid>

        <Grid size={6}>
          {!visibleLastUpdate ? (
            <Typography variant="body1">
              <TextField
                type="date"
                disabled={true}
                value={laUpdate ? new Date(laUpdate).toISOString().slice(0, 10) : ""}
                variant="standard"
                slotProps={{
                  input: {
                    disableUnderline: true
                  },
                }}
              />
            </Typography>
          ) : null}
        </Grid>
      </Grid>

      <Grid container columnSpacing={2} size={12}>
        <Grid container size={6} alignItems="center">
          <Typography variant="body1">Task Label</Typography>
        </Grid>

        <Grid size={6}>
          {taskLabels.length > 0 ? (
          <TaskLabelDetail taskLabels={taskLabels} projectLabels={projectLabels} />

          ):<span>-</span>}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TaskUserDetail;
