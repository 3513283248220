import React, { useState, useEffect } from 'react';
import { Paper, Button, Table, TableBody, TableHead, TableCell, TableRow, IconButton, Box, Typography } from "@mui/material";
import Grid from '@mui/material/Grid2';
import { TenantService } from '../../services/api';
import { TenantModel } from '../../models/Tenant/Tenant';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import TenantDetail from './TenantDetail';
import { useTranslation } from 'react-i18next';
import SessionExpire from '../Common/SessionExpire';

const TenantComponent: React.FC = () => {
  const [allTenants, setAllTenants] = useState<TenantModel[]>([]);
  const [tenantType, setTenantType] = useState<string>('Create');
  const [tenantDetailOpen, setTenantDetailOpen] = useState<boolean>(false);
  const [selectedTenat, setSelectedTenant] = useState<TenantModel | null>(null);
  const [allTenantName, setAllTenantName] = useState<string[]>([]);
  const { t } = useTranslation('global');
  const [session, setSession] = useState<boolean>(false);

  const getAllTenant = async () => {
    try {
      const response = await TenantService.getAll();
      const tenants: TenantModel[] = response as TenantModel[];
      setAllTenants(tenants);
      setAllTenantName(tenants.map((tenat) => tenat.name));
    } catch (error) {
      console.log(error);
      setSession(true);
    }
  };

  useEffect(() => {
    getAllTenant();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getActive = (value: boolean) => {
    if (value) {
      return "Active";
    }
    return "Deactive"
  };

  const getDate = (date: Date | null) => {
    return date
      ? `${new Date(date).getUTCDate().toString().padStart(2, '0')}.${(new Date(date).getUTCMonth() + 1).toString().padStart(2, '0')}.${new Date(date).getUTCFullYear()}`
      : "";
  };

  const getLocation = (country: string, city: string) => {
    if (country !== "" && city !== "") {
      return country + " / " + city;
    }
    return "-";
  };

  const handleSessionCheck = () => {
    setSession(false);
    window.location.href = '/login';
  };

  return (

    <Paper>

      <TenantDetail
        open={tenantDetailOpen}
        onClose={() => {
          setTenantDetailOpen(!tenantDetailOpen);
          getAllTenant();
        }}
        myTenant={selectedTenat || null}
        type={tenantType}
        allTenant={allTenantName}
      />

      <SessionExpire open={session} onClose={handleSessionCheck} />

      <Grid container size={12} sx={{ margin: 5 }}>
        <Typography variant="h4" sx={{ color: "#0b2343", marginTop: "15px" }}>
          {t('tenant.tenant_management')}
        </Typography>
      </Grid>


      <Grid container sx={{ margin: 5 }} display="flex" direction="column">

        <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
          <Button variant="contained" size="small" style={{ backgroundColor: "#0b2343" }} onClick={() => {
            setTenantType("Create");
            setTenantDetailOpen(!tenantDetailOpen);
            setSelectedTenant(null);
          }
          }>
            {t('tenant.create_tenant')}
          </Button>
        </Box>

        <Table sx={{ border: "10px solid whitesmoke", marginBottom: 5 }}>
          <TableHead style={{ backgroundColor: "whitesmoke" }}>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold" }}>
                {t('tenant.name')}
              </TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>
                {t('tenant.domain')}
              </TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>
                {t('tenant.admin_email')}
              </TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>
                {t('tenant.phone')}
              </TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>
                {t('users.company')}
              </TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>
                {t('tenant.creation_date')}
              </TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>
                {t('tenant.location')}
              </TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>
                {t('tenant.is_active')}
              </TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>
                {t('tenant.actions')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allTenants && allTenants.length > 0 ? (
              allTenants.map((tenant, index) => (
                <TableRow key={index}>
                  <TableCell>{tenant.name}</TableCell>
                  <TableCell>{tenant.domain}</TableCell>
                  <TableCell>{tenant.adminEmail}</TableCell>
                  <TableCell>{tenant.phone}</TableCell>
                  <TableCell>{tenant.company}</TableCell>
                  <TableCell>{tenant.createdAt ? getDate(tenant.createdAt) : "-"}</TableCell>
                  <TableCell>{getLocation(tenant.address.country, tenant.address.city)}</TableCell>
                  <TableCell>{getActive(tenant.isActive)}</TableCell>
                  <TableCell>
                    <Box>
                      <IconButton onClick={() => {
                        setSelectedTenant(tenant);
                        setTenantDetailOpen(!tenantDetailOpen);
                        setTenantType("Update");
                      }}>
                        <EditIcon />
                      </IconButton>
                      <IconButton onClick={() => { }}>
                        <DeleteIcon style={{ color: "red" }} />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5}>
                  <Typography align="center">{t('tenant.no_tenant')}</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Grid>

    </Paper>

  )


};

export default TenantComponent;
