import React, {useEffect, useState} from 'react';

import { Routes, Route } from 'react-router-dom';
import SettingsLayout from '../components/Settings/SettingsLayout';
import FilePathSettings from "../components/Settings/FilePathSettings";
import GeneralSettings from "../components/Settings/GeneralSettings";
import EmailConfiguration from "../components/Settings/EmailConfiguration";



const Settings: React.FC = () => {
  return (
      <>
        <SettingsLayout>
          <Routes>
            {/*<Route path="/settings" element={<GeneralSettings />} />*/}
            <Route path="/settings/file-paths" element={<FilePathSettings />} />
              <Route path="/settings/email-settings" element={<EmailConfiguration />} />
          </Routes>
        </SettingsLayout>
      </>
  );
};

export default Settings;

