import React, { useEffect, useState } from "react";
import { Dialog, DialogActions, IconButton, DialogContent, Card, CardContent, TextField, DialogTitle, Button, Alert, AlertColor } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Grid from "@mui/material/Grid2";
import { TaskManagementService } from "../../../services/api";
import { useTranslation } from "react-i18next";


interface RegulationRefDetailProps {
    open: boolean;
    onClose: () => void;
    content: string;
    taskID: string;
}

const RegulationRefDetail: React.FC<RegulationRefDetailProps> = ({ open, onClose, content, taskID }) => {

    const [contentTaskId, setContentTaskId] = useState<string>("");
    const [regulationRefDetail, setRegulationRefDetail] = useState<string>("");
    const [error, setError] = useState<AlertColor>('success');
    const [message, setMessage] = useState<string>("");
    const {t} = useTranslation('global');

    useEffect(() => {
        setContentTaskId(taskID);
        setRegulationRefDetail(content);
    }, [taskID, content])


    const saveDetail = async () => {

        try {
            const response = await TaskManagementService.update(contentTaskId, { regulationRefDetail: regulationRefDetail }, "task");
            console.log("my response -> ", response);
            setMessage(t('task.regulation_saved'));
            setError('success');

        }catch(error){
            console.log(error);
            setMessage(t('task.regulation_failed'));
            setError('error');
        }

        setTimeout(()=>{
            setMessage('');
            setError('success');
        },3000)


    }

    return (

        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{ style: { width: "100vw", margin: 10 } }}
        >
            <DialogActions style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <DialogTitle sx={{ color: "#0b2343" }}>{t('task.regulation_reference')}</DialogTitle>
                <IconButton onClick={() => { onClose(); }}>
                    <CloseIcon />
                </IconButton>

            </DialogActions>
            <DialogContent>
                <Card square style={{ boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.5)' }}>
                    <CardContent>
                        <Grid spacing={3} container>
                            <Grid size={12}>
                                {message !== "" ? (<Alert sx={{width: "30vw"}} content={error}>{message}</Alert>) : null}
                            </Grid>
                            <Grid size={12}>
                                <TextField
                                    value={regulationRefDetail}
                                    multiline
                                    fullWidth
                                    size="medium"
                                    label={t('task.regulation_reference')}
                                    minRows={3}
                                    maxRows={15}
                                    variant="outlined"
                                    onChange={(e) => { setRegulationRefDetail(e.target.value) }}
                                />
                            </Grid>
                            <Grid size={12} display="flex" justifyContent="flex-end">
                                <Button variant="contained" sx={{ backgroundColor: "#0b2343" }} onClick={() => { saveDetail(); }}>{t('general.save')}</Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </DialogContent>
        </Dialog>
    )
}

export default RegulationRefDetail;