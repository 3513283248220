import React, { useEffect, useState } from "react";
import LabelIcon from '@mui/icons-material/Label';
import LabelOffIcon from '@mui/icons-material/LabelOff';
import { IconButton, Box, Typography, TextField, Tooltip, Button, Badge } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { TaskLabelModel } from "../../../models/Task/Pano";
import Grid from "@mui/material/Grid2";
import { HexColorPicker } from "react-colorful";
import { TaskManagementService } from "../../../services/api";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';


interface TaskLabelProps {
    projectLabel: TaskLabelModel[];
    projectCode: string;
    onRefresh: (refresh: boolean) => void;
    taskID: string;
    taskLabels: string[];
    onRefreshTask: (refresh: boolean) => void;
}

const TaskLabelDashboard: React.FC<TaskLabelProps> = ({ projectLabel, projectCode, onRefresh, taskID, taskLabels, onRefreshTask }) => {

    const [dashboardShow, setDashBoardShow] = useState<boolean>(false);
    const [projectLabels, setProjectLabels] = useState<TaskLabelModel[]>([]);
    const [labelName, setLabelName] = useState<string>("");
    const [labelColor, setLabelColor] = useState<string>("#abcdef");
    const [manageLabel, setManageLabel] = useState<boolean>(false);
    const [taskLabel, setTaskLabel] = useState<string[]>([]);
    const [createLabel, setCreateLabel] = useState<boolean>(false);
    const [selectedLabel, setSelectedLabel] = useState<string>('');
    const [currentTaskID, setCurrentTaskID] = useState<string>('');

    useEffect(() => {
        setProjectLabels(projectLabel);
        setCurrentTaskID(taskID);
        setTaskLabel(taskLabels)
    }, [projectLabel, taskID, taskLabels])

    const editLabel = (myLabel: TaskLabelModel) => {
        setLabelColor(myLabel.color);
        setLabelName(myLabel.name);
        setSelectedLabel(myLabel._id);
    };

    const selectedTaskLabel = async (label: TaskLabelModel) => {

        const include = taskLabel.find((lbl) => lbl === label._id);
        let newLabels = [] as string[];

        if (include) {
            newLabels = taskLabel.filter((lbl) => lbl !== label._id);
            setTaskLabel(newLabels);
        } else {
            newLabels = [...taskLabel, label._id];
            setTaskLabel(newLabels);
        }

        try {
            const response = await TaskManagementService.update(currentTaskID, { taskLabels: newLabels }, "task");
            console.log("task label update response -> ", response);
            onRefreshTask(true);

        } catch (error) {
            console.log("Task label update error -> ", error)
        }


    };

    const createProjectLabel = async () => {
        try {
            const response = await TaskManagementService.create({ name: labelName, color: labelColor, projectCode }, '/label')
            console.log("label create response -> ", response);
            onRefresh(true);
            setSelectedLabel('');
            setLabelColor('#abcdef');
            setLabelName('');
        } catch (error) {
            console.log("label create error -> ", error);
        }

    };

    const deleteLabel = async () => {
        try {

            const response = await TaskManagementService.delete(selectedLabel, 'label/delete-label');
            console.log("delete label response -> ", response);
            onRefresh(true);
            setSelectedLabel('');
            setLabelColor('#abcdef');
            setLabelName('');

        } catch (error) {
            console.log("label delete error -> ", error);
        }
    };


    const updateLabel = async () => {
        try {

            const response = await TaskManagementService.update(selectedLabel, { color: labelColor, name: labelName }, 'label/update-label');
            console.log("label update response ->", response);
            setSelectedLabel('');
            setLabelColor('#abcdef');
            setLabelName('');
            onRefresh(true);
        } catch (error) {
            console.log('label update error -> ', error);
        }
    }

    return (
        <div style={{ position: "absolute" }}>
            <div style={{ position: "relative", borderBottom: "none" }}>

                <IconButton onClick={() => { setDashBoardShow(!dashboardShow) }}>
                    {!dashboardShow ? (

                        <div>
                            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                <Tooltip title={projectLabel.find((label) => label._id === taskLabel[0])?.name ?? ""}>
                                    <LabelIcon sx={{ color: projectLabel.find((label) => label._id === taskLabel[0])?.color ?? 'grey', fontSize: "30px" }} />
                                </Tooltip>

                                {taskLabel.length > 1 && (
                                    <Tooltip
                                        title={
                                            <ul style={{ margin: 0, padding: '5px' }}>
                                                {taskLabel.slice(1).map((label, index) => (
                                                    <li key={index} style={{ listStyleType: 'none' }}>
                                                        {projectLabel.find((lbl) => lbl._id === label)?.name}
                                                    </li>
                                                ))}
                                            </ul>
                                        }
                                    >
                                        <MoreHorizIcon />
                                    </Tooltip>
                                )}
                            </Box>

                        </div>


                    ) : (
                        <LabelOffIcon />
                    )}
                </IconButton>
            </div>

            {dashboardShow && (
                <Box
                    sx={{
                        width: "25vw",
                        minHeight: '20vw',
                        maxHeight: '35vw',
                        backgroundColor: 'white',
                        display: 'flex',
                        position: 'absolute',
                        top: '35px',
                        zIndex: 10,
                        padding: '16px',
                        borderRadius: "10px",
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
                        overflow: "auto"
                    }}
                >
                    <Typography variant="h6" sx={{ fontWeight: "bold", position: "absolute", top: 10, left: 30, color: "#0b2343" }}>{manageLabel ? "Project Labels" : "Task Labels"}</Typography>

                    <IconButton
                        onClick={() => { setDashBoardShow(!dashboardShow) }}
                        sx={{
                            position: 'absolute',
                            top: 0,
                            right: 10,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>


                    {manageLabel ? (

                        <Grid container spacing={1} size={12} sx={{ margin: 5 }}>

                            {projectLabels.length > 0 && projectLabels ? (
                                projectLabels.map((label) => {
                                    return (
                                        <Grid size={3}>

                                            <Tooltip title={label.name.length > 10 ? label.name : ""}>
                                                <Badge
                                                    badgeContent={label.name.length > 3 ? label.name.slice(0, 3) + "…" : label.name}
                                                    color="primary"
                                                    sx={{
                                                        "& .MuiBadge-badge": {
                                                            backgroundColor: "whitesmoke",
                                                            color: "black",
                                                            fontSize: "10px",
                                                            top: "20px",
                                                            right: "5px"
                                                        }
                                                    }}
                                                >
                                                    <Tooltip title={label.name.length < 3 ? "" : label.name}>
                                                        <IconButton key={label._id} onClick={() => editLabel(label)}>
                                                            <LabelIcon sx={{ color: label.color, fontSize: "36px" }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Badge>
                                            </Tooltip>


                                        </Grid>
                                    )
                                })

                            ) : null}

                            <Grid size={12} spacing={2} container sx={{ marginTop: 2 }}>

                                {!createLabel ? (

                                    <Grid size={12} display="flex" justifyContent="center" alignItems="center">
                                        <Typography
                                            variant="body1"
                                            style={{ color: "darkblue", cursor: "pointer" }}
                                            onClick={() => { setCreateLabel(!createLabel) }}
                                        >
                                            Create Label
                                        </Typography>
                                    </Grid>

                                ) : (

                                    <Grid container size={12}>
                                        <Grid container spacing={3} size={6}>
                                            <Grid size={12} display="flex" alignItems="center">
                                                <TextField
                                                    label="Label Name"
                                                    value={labelName}
                                                    onChange={(e) => { setLabelName(e.target.value) }}
                                                />

                                            </Grid>

                                            <Grid size={12}>

                                                <Button disabled={labelName === ""}
                                                    fullWidth
                                                    variant="contained"
                                                    sx={{
                                                        backgroundColor: labelName === "" ? "grey.500" : "#0b2343",
                                                        color: "white",
                                                        textTransform: "none",
                                                        "&.Mui-disabled": {
                                                            backgroundColor: "grey.500",
                                                            color: "white",
                                                        },
                                                    }}
                                                    onClick={() => { createProjectLabel(); }}>
                                                    Add Label
                                                </Button>

                                            </Grid>

                                            <Grid size={12}>

                                                <Button
                                                    disabled={selectedLabel === ""}
                                                    sx={{
                                                        backgroundColor: selectedLabel === "" ? "grey.500" : "#0b2343",
                                                        color: "white",
                                                        textTransform: "none",
                                                        "&.Mui-disabled": {
                                                            backgroundColor: "grey.500",
                                                            color: "white",
                                                        },
                                                    }}
                                                    fullWidth
                                                    variant="contained"
                                                    onClick={updateLabel}
                                                >
                                                    Update Label
                                                </Button>
                                            </Grid>

                                            <Grid size={12}>
                                                <Button
                                                    disabled={selectedLabel === ""}
                                                    sx={{
                                                        backgroundColor: selectedLabel === "" ? "grey.500" : "#0b2343",
                                                        color: "white",
                                                        textTransform: "none",
                                                        "&.Mui-disabled": {
                                                            backgroundColor: "grey.500",
                                                            color: "white",
                                                        },
                                                    }}
                                                    fullWidth
                                                    variant="contained"
                                                    onClick={deleteLabel}
                                                >
                                                    Delete Label
                                                </Button>
                                            </Grid>
                                        </Grid>


                                        <Grid container size={6} display="flex" justifyContent="center" alignItems="center">

                                            <HexColorPicker color={labelColor} onChange={(newColor) => { setLabelColor(newColor) }} />

                                        </Grid>

                                        <Grid size={12} display="flex" justifyContent="center" alignItems="center">
                                            <Typography
                                                variant="body1"
                                                style={{ color: "darkblue", cursor: "pointer" }}
                                                onClick={() => { setCreateLabel(!createLabel) }}
                                            >
                                                Hide
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                )}




                                <Grid size={12} display="flex" justifyContent="center" alignItems="center">
                                    <Typography
                                        variant="body1"
                                        style={{ color: "darkblue", cursor: "pointer" }}
                                        onClick={() => { setManageLabel(!manageLabel) }}
                                    >
                                        Show Task Labels
                                    </Typography>
                                </Grid>



                            </Grid>



                        </Grid>
                    ) : (
                        <Grid container size={12} sx={{ margin: 5 }}>

                            {projectLabels.length > 0 && projectLabels ? (
                                projectLabels.map((label) => {
                                    return (
                                        <Grid size={3}>

                                            <Tooltip title={label.name.length > 10 ? label.name : ""}>
                                                <Badge
                                                    badgeContent={label.name.length > 4 ? label.name.slice(0, 4) + ".." : label.name}
                                                    color="primary"
                                                    sx={{
                                                        "& .MuiBadge-badge": {
                                                            backgroundColor: "whitesmoke",
                                                            color: "black",
                                                            fontSize: "10px",
                                                            top: "20px",
                                                            right: "5px"
                                                        }
                                                    }}
                                                >
                                                    <Tooltip title={label.name.length < 4 ? "" : label.name}>
                                                        <IconButton
                                                            sx={{
                                                                ":hover": !taskLabel.find((lbl) => lbl === label._id) ? 'transparent' : '',
                                                                boxShadow: taskLabel.find((lbl) => lbl === label._id) ? '0px 0px 10px rgba(0, 0, 0, 0.5)' : 'none'
                                                            }}
                                                            key={label._id}
                                                            onClick={() => selectedTaskLabel(label)}
                                                        >
                                                            <LabelIcon sx={{ color: label.color, fontSize: "36px" }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Badge>
                                            </Tooltip>


                                        </Grid>
                                    )
                                })

                            ) : null}




                            <Grid size={12} display="flex" justifyContent="center" alignItems="center">
                                <Typography
                                    onClick={() => { setManageLabel(!manageLabel) }}
                                    style={{ color: "darkblue", cursor: "pointer" }}>
                                    Manage All Labels
                                </Typography>

                            </Grid>
                        </Grid>
                    )}

                </Box>
            )}
        </div>
    );
}

export default TaskLabelDashboard;
