import { Paper, Typography, Table, TableBody, TableHead, TableRow, TableCell, Button, Box, IconButton, TextField, InputAdornment } from "@mui/material";
import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid2";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PromptsDrawer from "./PromptsDrawer";
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from "react-i18next";
import { Prompt } from "../../../models/Wizebuddy/Prompt";
import { WizebuddyService } from "../../../services/api";


const PromptsComponent: React.FC = () => {
    const [open, setOpen] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');
    const { t } = useTranslation('global');
    const [allPrompt, setAllPrompt] = useState<Prompt[]>([]);


    const getAllPrompt = async () => {
        try {

            const response = await WizebuddyService.get('prompt', "json");
            setAllPrompt(response.rows as Prompt[]);

        } catch (error) {
            console.log("get all prompt -> ", error);
        }
    };


    useEffect(() => {
        getAllPrompt();
    }, [])


    return (
        <Paper>

            <Grid container size={12}>

                <Grid size={12}>

                    <PromptsDrawer open={open} onClose={() => {
                         setOpen(!open); 
                         getAllPrompt();
                         }} />

                </Grid>

                <Grid container size={12} sx={{ margin: 5 }}>
                    <Typography variant="h4" sx={{ color: "#0b2343", marginTop: "15px" }}>
                        {t('wizebuddy_prompts.prompts')}
                    </Typography>
                </Grid>

                <Grid size={12} sx={{ marginRight: 5, marginLeft: 5 }} container>

                    <Grid size={6}>

                        <TextField
                            label={t('task.search')}
                            fullWidth
                            value={search}
                            onChange={(e) => { setSearch(e.target.value) }}
                            slotProps={{
                                input: {
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                edge="end"
                                            >
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                },
                            }}
                        />
                    </Grid>

                    <Grid size={6} display="flex" justifyContent="flex-end">
                        <Button onClick={() => { setOpen(!open) }} variant="contained" style={{ backgroundColor: "#0b2343", color: "white", textTransform: "none", height: "5vh" }}>{t('wizebuddy_prompts.create_prompts')}</Button>
                    </Grid>

                </Grid>

                <Grid size={12} sx={{ margin: 5 }}>
                    <Table sx={{ border: "10px solid whitesmoke", marginBottom: 5 }}>
                        <TableHead style={{ backgroundColor: "whitesmoke" }}>
                            <TableCell sx={{ fontWeight: "bold" }}>
                                {t('wizebuddy_prompts.prompts_name')}
                            </TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>
                                {t('wizebuddy_prompts.create_date')}
                            </TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>
                                {t('wizebuddy_categories.actions')}
                            </TableCell>
                        </TableHead>
                        <TableBody>
                            {allPrompt.map((prompt, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell>{prompt.title}</TableCell>
                                        <TableCell>{prompt.createdAt.split('T')[0]}</TableCell>
                                        <TableCell>
                                            <Box>
                                                <IconButton onClick={() => { }}>
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton onClick={() => { }}>
                                                    <DeleteIcon style={{ color: "red" }} />
                                                </IconButton>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}

                        </TableBody>
                    </Table>

                </Grid>

            </Grid>

        </Paper>
    )
}

export default PromptsComponent;