import React, {useEffect, useState} from "react";
import PathsList, {PathItem} from "./PathsList";
import {TaskManagementService} from "../../services/api";
import FilePath from "./FilePath";

const FilePathSettings: React.FC = () => {
    const [paths, setPaths] = useState<PathItem[]>([]);

    const getPaths = async () => {
        console.log("getPaths");
        // Load saved paths from localStorage
        try {
            const response = await TaskManagementService.get("document-paths", "json");
            const savedPaths = response as PathItem[];
            if (Array.isArray(savedPaths)) {
                console.log("Get Paths Response", savedPaths);
                setPaths(savedPaths);
            } else {
                console.log('No saved paths found or response is not an array.');
                const newArray: PathItem[] = [];
                newArray.push(savedPaths)
                setPaths(newArray); // Ensure paths is set to an empty array
            }
        } catch (error) {
            console.error('Failed to parse saved paths:', error);
            setPaths([]); // Ensure paths is set to an empty array on error
        }
    };

    useEffect(() => {
        getPaths();
    }, []);

    const submitPaths = async (path: PathItem) => {
        console.log("submitPaths");
        try {
            const response = await TaskManagementService.create(path, "document-paths");
            console.log(response);
        } catch (error) {
            console.error('Failed to save paths:', error);
        }
    };

    const handleSavePath = (path: string, name: string) => {
        const newPath: PathItem = {
            id: '',
            name,
            path,
        };

        submitPaths(newPath);
        setPaths((prevPaths) => [...prevPaths, newPath]);
    };

    const handleDeletePath = (id: string) => {
        setPaths((prevPaths) => prevPaths.filter((path) => path.id !== id));
    };

    return (
        <div className="space-y-6">
            <div>
                <h1 className="text-3xl font-semibold tracking-tight">File Path Settings</h1>
                <p className="text-muted-foreground mt-2">
                    Manage paths where documents will be uploaded from
                </p>
            </div>

            <FilePath onSavePath={handleSavePath} />
            <PathsList paths={paths} onDeletePath={handleDeletePath} />
        </div>
    );
};

export default FilePathSettings;
