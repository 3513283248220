import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid2";
import { Card, CardContent, TextField, Typography, Button, Alert, InputAdornment, IconButton, AlertColor } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import axios from 'axios';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ErrorIcon from '@mui/icons-material/Error';


type linkStatusType = "error" | "link-expired" | "link-available"

const ForgotPasswordCreateNewPassword: React.FC = () => {
    const { tenantID, uuid } = useParams();
    const [newPassword, setNewPassword] = useState<string>("");
    const [newPasswordAgain, setNewPasswordAgain] = useState<string>("");
    const [showMessage, setShowMessage] = useState<boolean>(false);
    const [showNPS, setShowNPS] = useState<boolean>(false);
    const [showNPSA, setShowNPSA] = useState<boolean>(false);
    const navigate = useNavigate();
    const [message, setMessage] = useState<string>("");
    const [messageType, setMessageType] = useState<AlertColor>("success");

    const [linkStatus, setLinkStatus] = useState<linkStatusType>("link-available");

    useEffect(() => {
        const verifyLink = async () => {
            try {
                const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/api/verify-forgot-password`, { uuid, tenantID });
                console.log("verify response forg -< ", response.data.result);
                setLinkStatus(response.data.result);
            } catch (error) {
                console.log(error);
                setLinkStatus("error");
            }
        };

        verifyLink();
    }, []);




    const handleFieldChange = (field: string, value: any) => {
        if (field === "newPassword") {
            setNewPassword(value);
            if (value !== newPasswordAgain) {
                setShowMessage(true)
                setMessage("Passwords is should be a match.");
                setMessageType("error");
            } else {
                setShowMessage(false);
                setMessage("");
                setMessageType("success");
            }
        } else if (field === "newPasswordAgain") {
            setNewPasswordAgain(value);
            if (value !== newPassword) {
                setShowMessage(true)
                setMessage("Passwords is should be a match.");
                setMessageType("error");
            } else {
                setShowMessage(false);
                setMessage("");
                setMessageType("success");
            }
        }
    }

    const handleCreatePassword = async () => {
        const createNewPasswordBody = {
            uuid,
            tenantID,
            newPassword,
            newPasswordAgain
        };
        try {
            setMessage("New password is set it.");
            setShowMessage(true);
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/api/forgot-password`, createNewPasswordBody);
            console.log("forgot password response -> ", response);

            setTimeout(() => {
                navigate('/login');
                setMessageType("success");
                setMessage("");
            }, 2000);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100vh" }}>
            <Card sx={{ width: "45vw", height: "45vh", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)" }}>
                <CardContent>
                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        sx={{ display: "flex", flexDirection: "column", gap: 2 }}
                    >
                        {linkStatus === "link-available" ? (
                            <Grid
                                container
                                justifyContent="center"
                                alignItems="center"
                                sx={{ display: "flex", flexDirection: "column", gap: 2 }}
                            >

                                <Typography variant="h5" style={{ color: "#0b2343", fontWeight: "bold" }}>Reset Your Password</Typography>
                                {showMessage ? (
                                    <div>
                                        <Alert severity={messageType}>{message}</Alert>
                                    </div>
                                ) : null}

                                <TextField
                                    value={newPassword}
                                    onChange={(e) => handleFieldChange("newPassword", e.target.value)}
                                    label="New Password"
                                    sx={{ width: "30vw" }}
                                    type={showNPS ? "text" : "password"}
                                    slotProps={{
                                        input: {
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => { setShowNPS(!showNPS) }}
                                                        edge="end"
                                                    >
                                                        {showNPS ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        },
                                    }}
                                />

                                <TextField
                                    value={newPasswordAgain}
                                    onChange={(e) => handleFieldChange("newPasswordAgain", e.target.value)}
                                    label="New Password Again"
                                    sx={{ width: "30vw" }}
                                    type={showNPSA ? "text" : "password"}
                                    slotProps={{
                                        input: {
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => { setShowNPSA(!showNPSA) }}
                                                        edge="end"
                                                    >
                                                        {showNPSA ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        },
                                    }}
                                />


                                <Button variant="contained" sx={{
                                    backgroundColor: "#0b2343",
                                    "&.Mui-disabled": {
                                        color: "white",
                                        backgroundColor: "lightgray",
                                    },
                                }} disabled={showMessage || newPassword === "" || newPasswordAgain === ""}
                                    onClick={() => { handleCreatePassword(); }}
                                >
                                    Reset Password
                                </Button>

                            </Grid>
                        ) : linkStatus === "link-expired" ? (
                            <Grid
                                container
                                justifyContent="center"
                                alignItems="center"
                                sx={{ display: "flex", flexDirection: "column", gap: 2 }}
                            >

                                <Grid size={12} sx={{ display: "flex", flexDirection: "row", gap: 2, mt: 10 }}>
                                    <AccessTimeIcon fontSize="large" />
                                    <Typography variant="h4">Link Expired</Typography>
                                </Grid>

                                <Grid sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                    <Button
                                        variant="contained"
                                        sx={{ backgroundColor: "#0b2343" }}
                                        onClick={() => { navigate('/login'); }}
                                    >
                                        Back To Login Page
                                    </Button>
                                </Grid>
                            </Grid>
                        ) : linkStatus === "error" ? (
                            <Grid
                                container
                                justifyContent="center"
                                alignItems="center"
                                sx={{ display: "flex", flexDirection: "column", gap: 2 }}
                            >

                                <Grid size={12} sx={{ display: "flex", flexDirection: "row", gap: 2, mt: 10 }}>
                                    <ErrorIcon fontSize="large" />
                                    <Typography variant="h4">Error</Typography>
                                </Grid>

                                <Grid sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                    <Button
                                        variant="contained"
                                        sx={{ backgroundColor: "#0b2343" }}
                                        onClick={() => { navigate('/login'); }}
                                    >
                                        Back To Login Page
                                    </Button>
                                </Grid>
                            </Grid>
                        ) : null}

                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    );
};

export default ForgotPasswordCreateNewPassword;
