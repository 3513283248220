import { Paper, Typography, Table, TableBody, TableHead, TableRow, TableCell, Box, IconButton, TextField, InputAdornment } from "@mui/material";
import React, { useState } from "react";
import Grid from "@mui/material/Grid2";
import VisibilityIcon from '@mui/icons-material/Visibility';
import SearchIcon from '@mui/icons-material/Search';
import ConversationHistoryModel from "./ConversationHistoryModel";
import { useTranslation } from "react-i18next";
import { ConversationHistory } from "../../../models/Wizebuddy/ConversationHistory";

const ConversationComponent: React.FC = () => {

    const [search, setSearch] = useState<string>('');
    const [open, setOpen] = useState<boolean>(false);
    const { t } = useTranslation('global');
    const [selectedHistory,setSelectedHistory] = useState<ConversationHistory[]>([]);
    const conversations = [
        {
            title: "test.docx hakkında",
            createDate: "12-03-2025",
            type: "Conversation",
            history: [
                { role: "user", message: "test.docx hakkında bana bilgi ver" },
                { role: "ai", message: "test.docx 05-03-2025 tarihinde yüklendi" },
                { role: "user", message: "performans harf notları nelerdir" },
                { role: "ai", message: "Performans harf notları şunlardır: A; Çok Yüksek Performans, B; Yüksek Performans, C1; Hedefin Üzerinde Performans, C2; Hedeflenen Performans, C3; Hedefin Altında Performans, D; Düşük Performans, E; Kötü Performans anlamına gelir" },
                { role: "user", message: "Yazıcıya bağlanmak isityorum ne yapmalıyım?" },
                { role: "ai", message: "Yazıcıya bağlanmak için, öncelikle yazıcınızın bilgisayarınıza veya ağa bağlı olduğundan emin olun. Eğer yazıcınız kablosuz ise, Wi-Fi ağınıza bağlanmanız gerekebilir. Yazıcının kurulum kılavuzunu takip ederek gerekli adımları gerçekleştirebilirsiniz. Ayrıca, yazıcı sürücülerini bilgisayarınıza yüklemeniz de gerekebilir. Herhangi bir problemle karşılaşırsanız, yazıcının modeline özel destek alabilirsiniz." },
                { role: "user", message: "Sağlık raporum var mı?" },
                { role: "ai", message: "Verdiğiniz bilgiye göre sağlık raporunuzla ilgili bir bilgi bulunmamaktadır. Başka bir konuda yardımcı olabilir miyim?" },
                { role: "user", message: "Dünkü galatasaray fenerbahçe maçı kaçkaç birti" },
                { role: "ai", message: "Üzgünüm. Başka bir soruda yardımcı olmamı ister misiniz?" },
                { role: "user", message: "test.docx içeriği hakkında bilgi verir misin" },
                { role: "ai", message: "test.docx sistemdeki kullanıcıların kişisel bilgileri ver şirket içindeki durumları hakkında bilgiler içermektedir." },
            ]
        },
        {
            title: "proje_plan.xlsx hakkında",
            createDate: "12-03-2025",
            type: "Conversation",
            history: [
                { "role": "user", "message": "proje_plan.xlsx hakkında bilgi ver" },
                { "role": "ai", "message": "proje_plan.xlsx 12-03-2025 tarihinde yüklendi" },
                { "role": "user", "message": "Çalışma saatleri nelerdir?" },
                { "role": "ai", "message": "Standart çalışma saatleri hafta içi 09:00 - 18:00 arasındadır. Resmi tatiller ve hafta sonları çalışma saatleri değişiklik gösterebilir." },
                { "role": "user", "message": "E-posta şifremi unuttum, ne yapmalıyım?" },
                { "role": "ai", "message": "E-posta şifrenizi sıfırlamak için giriş ekranındaki 'Şifremi Unuttum' bağlantısına tıklayın. Gelen yönlendirmeleri takip ederek yeni bir şifre oluşturabilirsiniz. Eğer erişim sorunu yaşarsanız, sistem yöneticinize başvurabilirsiniz." },
                { "role": "user", "message": "Aylık performans raporum var mı?" },
                { "role": "ai", "message": "Veritabanında aylık performans raporunuza dair bir kayıt bulunmamaktadır. Daha fazla bilgi almak için yöneticinizle iletişime geçebilirsiniz." },
                { "role": "user", "message": "Dünkü Beşiktaş Trabzonspor maçı kaç kaç bitti?" },
                { "role": "ai", "message": "Üzgünüm, bu konuda elimde güncel bir bilgi bulunmamaktadır. Başka bir konuda yardımcı olabilir miyim?" },
                { "role": "user", "message": "proje_plan.xlsx içeriği hakkında bilgi verir misin?" },
                { "role": "ai", "message": "proje_plan.xlsx dosyası, proje zaman çizelgesi, görev dağılımı ve sorumlu ekip üyelerinin bilgilerini içermektedir." }
            ]
        },
        {
            title: "rapor.pdf hakkında",
            createDate: "13-03-2025",
            type: "Conversation",
            history: [
                { role: "user", message: "rapor.pdf hakkında bana bilgi ver" },
                { role: "ai", message: "rapor.pdf 10-03-2025 tarihinde yüklendi" },
                { role: "user", message: "Bütçe durumu nedir?" },
                { role: "ai", message: "Bütçe durumu, geçtiğimiz yıl için hedeflenen bütçeyi aşmadı. Detaylar raporda yer almaktadır." },
                { role: "user", message: "Yatırımcı toplantısının tarihi nedir?" },
                { role: "ai", message: "Yatırımcı toplantısı 15-03-2025 tarihinde yapılacaktır." },
                { role: "user", message: "Raporun son güncelleme tarihi nedir?" },
                { role: "ai", message: "Raporun son güncelleme tarihi 12-03-2025'tir." },
                { role: "user", message: "Yönetim kurulu üyeleri kimlerdir?" },
                { role: "ai", message: "Yönetim kurulu üyeleri, CEO, CFO, COO ve diğer üst düzey yöneticilerdir." },
                { role: "user", message: "rapor.pdf içeriği hakkında bilgi verir misin?" },
                { role: "ai", message: "rapor.pdf, şirketin mali durumu, yıllık hedefler ve stratejilerle ilgili bilgiler içermektedir." },
            ]
        },
        {
            title: "finansal_veriler.xlsx hakkında",
            createDate: "14-03-2025",
            type: "Conversation",
            history: [
                { role: "user", message: "finansal_veriler.xlsx hakkında bilgi ver" },
                { role: "ai", message: "finansal_veriler.xlsx 14-03-2025 tarihinde yüklendi" },
                { role: "user", message: "Şirketin kar-zarar durumu nedir?" },
                { role: "ai", message: "Şirket, geçtiğimiz çeyrekte %5 kar elde etti." },
                { role: "user", message: "Bilanço ne zaman güncellendi?" },
                { role: "ai", message: "Bilanço 10-03-2025 tarihinde güncellenmiştir." },
                { role: "user", message: "Yatırım projeleri hakkında bilgi alabilir miyim?" },
                { role: "ai", message: "Şirketin yatırım projeleri, enerji sektörü üzerine yoğunlaşmaktadır. Detaylı bilgi raporda yer almaktadır." },
                { role: "user", message: "finansal_veriler.xlsx içeriği hakkında bilgi verir misin?" },
                { role: "ai", message: "finansal_veriler.xlsx dosyası, gelir tablosu, bilanço ve nakit akışını içermektedir." },
            ]
        },
        {
            title: "strateji_dokumani.pdf hakkında",
            createDate: "15-03-2025",
            type: "Conversation",
            history: [
                { role: "user", message: "strateji_dokumani.pdf hakkında bilgi ver" },
                { role: "ai", message: "strateji_dokumani.pdf 14-03-2025 tarihinde yüklendi" },
                { role: "user", message: "Şirketin stratejik hedefleri nelerdir?" },
                { role: "ai", message: "Şirketin stratejik hedefleri, sürdürülebilir büyüme, dijital dönüşüm ve yeni pazarlar keşfetmektir." },
                { role: "user", message: "Yönetim stratejisindeki değişiklikler nelerdir?" },
                { role: "ai", message: "Yönetim stratejisinde, liderlik yapısının güçlendirilmesi ve şirket içindeki yetkinliklerin artırılması hedeflenmiştir." },
                { role: "user", message: "Dijital dönüşüm stratejisi hakkında bilgi verir misiniz?" },
                { role: "ai", message: "Dijital dönüşüm stratejisi, iş süreçlerini otomatikleştirerek verimliliği artırmayı amaçlamaktadır." },
                { role: "user", message: "strateji_dokumani.pdf içeriği hakkında bilgi verir misin?" },
                { role: "ai", message: "strateji_dokumani.pdf, şirketin uzun vadeli hedefleri, stratejik planları ve dijital dönüşüm yol haritası hakkında bilgiler içermektedir." },
            ]
        },
        {
            title: "toplantı_notları.docx hakkında",
            createDate: "16-03-2025",
            type: "Conversation",
            history: [
                { role: "user", message: "toplantı_notları.docx hakkında bilgi ver" },
                { role: "ai", message: "toplantı_notları.docx 16-03-2025 tarihinde yüklendi" },
                { role: "user", message: "Toplantıda alınan kararlar nelerdir?" },
                { role: "ai", message: "Toplantıda, proje bütçesinin onaylanması ve yeni ekip üyelerinin işe alınması kararlaştırılmıştır." },
                { role: "user", message: "Yeni proje önerileri hakkında bilgi verir misiniz?" },
                { role: "ai", message: "Yeni proje önerileri, yapay zeka ve veri analitiği alanlarında yapılacak araştırmalardır." },
                { role: "user", message: "Toplantı tarihini hatırlayabilir misiniz?" },
                { role: "ai", message: "Toplantı tarihi 15-03-2025'tir." },
                { role: "user", message: "toplantı_notları.docx içeriği hakkında bilgi verir misin?" },
                { role: "ai", message: "toplantı_notları.docx dosyası, yapılan toplantının detaylı notlarını ve alınan kararları içermektedir." },
            ]
        }
    ];

    return (
        <Paper>

            <Grid container size={12}>

                <Grid container size={12} sx={{ margin: 5 }}>
                    <Typography variant="h4" sx={{ color: "#0b2343", marginTop: "15px" }}>
                        {t('wizebuddy_conversation.conversations')}
                    </Typography>
                </Grid>

                <Grid size={12}>

                    <ConversationHistoryModel selectedHistory={selectedHistory} open={open} onClose={() => { setOpen(!open) }} />

                </Grid>


                <Grid size={12} sx={{ marginRight: 5, marginLeft: 5 }} container>

                    <Grid size={6}>

                        <TextField
                            label={t('task.search')}
                            fullWidth
                            value={search}
                            onChange={(e) => { setSearch(e.target.value) }}
                            slotProps={{
                                input: {
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                edge="end"
                                            >
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                },
                            }}
                        />
                    </Grid>

                </Grid>

                <Grid size={12} sx={{ margin: 5 }}>
                    <Table sx={{ border: "10px solid whitesmoke", marginBottom: 5 }}>
                        <TableHead style={{ backgroundColor: "whitesmoke" }}>
                            <TableCell sx={{ fontWeight: "bold" }}>
                                {t('wizebuddy_conversation.title')}
                            </TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>
                                {t('wizebuddy_conversation.type')}
                            </TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>
                                {t('wizebuddy_conversation.create_date')}
                            </TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>
                                {t('wizebuddy_categories.actions')}
                            </TableCell>
                        </TableHead>
                        <TableBody>
                            {conversations.map((conversation, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell>{conversation.title}</TableCell>
                                        <TableCell>{conversation.type}</TableCell>
                                        <TableCell>{conversation.createDate}</TableCell>
                                        <TableCell>
                                            <Box>
                                                <IconButton onClick={() => { setOpen(!open); setSelectedHistory(conversation.history as ConversationHistory[]) }}>
                                                    <VisibilityIcon />
                                                </IconButton>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                )
                            })

                            }

                        </TableBody>
                    </Table>

                </Grid>

            </Grid>

        </Paper>
    )
}

export default ConversationComponent;