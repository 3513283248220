import React, { useEffect, useState } from "react";
import { Dialog, DialogActions, DialogTitle, IconButton, DialogContent, Button, TextField, Alert, AlertColor, Typography, Box, Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { TaskManagementService } from "../../services/api";
import { useTranslation } from "react-i18next";

interface EmailPreviewProps {
    open: boolean;
    onClose: () => void;
    mail: string;
}

const EmailPreview: React.FC<EmailPreviewProps> = ({ open, onClose, mail }) => {
    const [from, setFrom] = useState<string>("");
    const [to, setTo] = useState<string>("");
    const [text, setText] = useState<string>("Test Mail");

    const [message, setMessage] = useState<string>("");
    const [showMessage, setShowMessage] = useState(false);
    const [messageType, setMessageType] = useState<AlertColor>('success');

    const { t } = useTranslation('global');

    useEffect(() => {
        setFrom(mail);
    }, [mail]);

    const clear = () => {
        setTo("");
        setText("Test Mail");
    };

    const sendMail = async () => {
        try {
            let myMail = {
                from,
                to,
                text
            };
            const resp = await TaskManagementService.create(myMail, "smtp-config/send-test-mail");
            console.log("mail response -> ", resp);

            setMessage(t('settings.mail_sent'));
            setMessageType('success');
        } catch {
            setMessage(t('settings.mail_failed'));
            setMessageType('error');
        }

        setShowMessage(true);

        setTimeout(() => {
            setShowMessage(false);
        }, 4000);
    };

    const getMessage = () => {
        return (
            <Alert severity={messageType} sx={{ width: '100%', mb: 2 }}>
                {message}
            </Alert>
        );
    };

    return (
        <Dialog
            open={open}
            onClose={() => { onClose(); clear(); }}
            maxWidth="lg"
            fullWidth
        >
            <DialogActions style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <DialogTitle style={{ margin: 0 }}>
                    {t('settings.email_conf_preview')}
                </DialogTitle>
                <IconButton onClick={() => { onClose(); clear(); }}>
                    <CloseIcon />
                </IconButton>
            </DialogActions>

            <DialogContent>
                {showMessage && getMessage()}

                <Grid container spacing={2}>
                    <Grid item xs={12} sm={3} display="flex" justifyContent="center" alignItems="center">
                        <Typography style={{ color: "#0b2343", fontWeight: "bold" }}>
                            {t('settings.from')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                        <TextField
                            fullWidth
                            value={from}
                            onChange={(e) => setFrom(e.target.value)}
                            size="small"
                        />
                    </Grid>

                    <Grid item xs={12} sm={3} display="flex" justifyContent="center" alignItems="center">
                        <Typography style={{ color: "#0b2343", fontWeight: "bold" }}>
                            {t('settings.to')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                        <TextField
                            fullWidth
                            value={to}
                            onChange={(e) => setTo(e.target.value)}
                            size="small"
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            value={text}
                            onChange={(e) => setText(e.target.value)}
                            label={t('settings.mail_text')}
                            size="small"
                            multiline
                            rows={3}
                        />
                    </Grid>

                    <Grid item xs={12} display="flex" justifyContent="center">
                        <Button
                            variant="contained"
                            sx={{ backgroundColor: "#006400" }}
                            disabled={!from || !to || !text}
                            onClick={sendMail}
                        >
                            {t('settings.send_test_mail')}
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default EmailPreview;
