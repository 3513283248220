import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { TaskManagementService } from "../../services/api";
import { Project } from "../../models/Task/Pano";
import { Card, CardContent, Typography, Tab, Tabs } from "@mui/material";
import Grid from '@mui/material/Grid2';
import ProjectNotification from "./component/ProjectNotification";
import { useTranslation } from "react-i18next";
import ProjectDetail from "./component/ProjectDetail";
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../../context/AuthContext";
import ProjectRole from "./component/ProjectRole";


const TaskProjectSettings: React.FC = () => {
    const { code } = useParams();
    const [project, setProject] = useState<Project>();
    const [tabValue, setTabvalue] = useState<string>("detail")
    const { t } = useTranslation('global');
    const navigate = useNavigate();
    const {user} = useAuth();

    const redirectProjectPage = () => {
        navigate("/task-management/project");
    };

    const getProject = async () => {
        try {
            const response = await TaskManagementService.get('project/' + code, "json");
            const myProject: Project = response as Project;
            if(myProject.coordinator !== user?._id){
                redirectProjectPage();
                return;
            }else{
                setProject(myProject);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getProject();
    }, []);

    const handleHistoryTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabvalue(newValue);
    };

    const redirectTaskPage = () => {
        navigate("/task-management/project-tasks/" + code);
    };
    

    return (
        <Card sx={{ boxShadow: 3 }}>
            <CardContent>
                <Grid container>
                    <Grid container size={12} sx={{ margin: "15px" }}>
                        <Typography variant="h4" sx={{ color: "#0b2343" }}>
                            {t('project.project_settings')}
                        </Typography>
                    </Grid>

                    <Grid size={3} sx={{ mb: "5px", mt: "5px" }} style={{ width: 'fit-content', textAlign: 'left', padding: '8px 16px', backgroundColor: '#f5f5f5', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}>
                        <p style={{ margin: 0, fontSize: 18, color: "#0b2343", fontWeight: 'bold', lineHeight: '1.2' }}>
                            <span style={{ cursor: "pointer" }} onClick={() => { redirectProjectPage() }}>
                                {t('sidebar.project')}
                            </span> / <span style={{ cursor: "pointer" }} onClick={() => { redirectTaskPage() }}>{project?.name}</span>
                        </p>
                    </Grid>

                    <Grid size={12} sx={{ marginTop: 1 }}>
                        <Tabs value={tabValue} onChange={handleHistoryTabChange} aria-label="simple tabs example">
                            <Tab style={{textTransform: "none"}} label={t('project.detail')} value="detail" />
                            <Tab style={{textTransform: "none"}} label={t('project.notification')} value="notification" />
                            <Tab style={{textTransform: "none"}} label={t('project.project_roles')} value="project-role" />
                        </Tabs>
                    </Grid>

                    <Grid size={12} sx={{ ml: 3 }}>

                        {tabValue === "detail" ? (
                            <Grid size={4}>
                                <ProjectDetail project={project} />
                            </Grid>
                        ) : tabValue === "notification" ? (
                            <Grid size={12}>
                                <ProjectNotification project={project} />
                            </Grid>
                        ) : tabValue === "project-role" ? (
                            <Grid size={12}>
                                <ProjectRole project={project}/>
                            </Grid>
                        ) : null}

                    </Grid>

                </Grid>

            </CardContent>
        </Card>
    )
}


export default TaskProjectSettings;