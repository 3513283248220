import { Paper, Typography, Table, TableBody, TableHead, TableRow, TableCell, IconButton, TextField, InputAdornment } from "@mui/material";
import React, { useState } from "react";
import Grid from "@mui/material/Grid2";
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from "react-i18next";


const UnknownConversationComponent: React.FC = () => {

    const [search, setSearch] = useState<string>('');
    const { t } = useTranslation('global');

    const exampleConversation = [
        {
            message: "Unknown Conversation 1",
            createDate: '04-03-2025'
        },
        {
            message: "Unknown Conversation 2",
            createDate: '06-03-2025'
        },        {
            message: "Unknown Conversation 3",
            createDate: '10-03-2025'
        },        {
            message: "Unknown Conversation 4",
            createDate: '13-03-2025'
        },        {
            message: "Unknown Conversation 5",
            createDate: '15-03-2025'
        },        {
            message: "Unknown Conversation 6",
            createDate: '17-03-2025'
        },
    ]

    return (
        <Paper>

            <Grid container size={12}>

                <Grid container size={12} sx={{ margin: 5 }}>
                    <Typography variant="h4" sx={{ color: "#0b2343", marginTop: "15px" }}>
                        {t('wizebuddy_unknown_conversation.unknown_conversation')}
                    </Typography>
                </Grid>

                <Grid size={12} sx={{ marginRight: 5, marginLeft: 5 }} container>

                    <Grid size={6}>

                        <TextField
                            label={t('task.search')}
                            fullWidth
                            value={search}
                            onChange={(e) => { setSearch(e.target.value) }}
                            slotProps={{
                                input: {
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                edge="end"
                                            >
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                },
                            }}
                        />
                    </Grid>

                </Grid>

                <Grid size={12} sx={{ margin: 5 }}>
                    <Table sx={{ border: "10px solid whitesmoke", marginBottom: 5 }}>
                        <TableHead style={{ backgroundColor: "whitesmoke" }}>
                            <TableCell sx={{ fontWeight: "bold" }}>
                                {t('wizebuddy_unknown_conversation.message')}
                            </TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>
                                {t('wizebuddy_unknown_conversation.create_date')}
                            </TableCell>
                        </TableHead>
                        <TableBody>
                            {exampleConversation.map((conversation,index) =>{
                                return(
                                    <TableRow key={index}>
                                    <TableCell>{conversation.message}</TableCell>
                                    <TableCell>{conversation.createDate}</TableCell>
                                </TableRow>
                                )
                            })}

                        </TableBody>
                    </Table>

                </Grid>

            </Grid>

        </Paper>
    )
}

export default UnknownConversationComponent;