import React, { useState, useCallback, useEffect } from 'react';
import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';


interface TaskNameFieldProps {
  item: string;
  multiline: boolean;
  rows: number;
  onTaskNameChange: (newTaskName: string) => void;
  onBlurCallBack: (isBlurred: boolean) => void;
  permission: boolean;
  label: boolean;
}

const TaskNameField: React.FC<TaskNameFieldProps> = ({ item, multiline, rows, onTaskNameChange, onBlurCallBack, permission, label }) => {
  const [anyChange, setAnyChange] = useState<boolean>(false);
  const [value, setValue] = useState<string>("");
  const {t} = useTranslation('global');


  useEffect(()=>{
    setValue(item);
  },[item])

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;
      setValue(newValue);
      onTaskNameChange(newValue);
      setAnyChange(true);
    },
    [onTaskNameChange]
  );


  const handleBlur = useCallback(() => {
    if (anyChange) {
      onBlurCallBack(true);
      setAnyChange(false);
    }
  }, [anyChange, onBlurCallBack]);

  return (
    <TextField
      fullWidth
      label={label ? null : t('task.task')}
      value={value}
      onChange={handleChange}
      multiline={multiline}
      rows={rows}
      disabled={permission}
      onBlur={handleBlur}
    />
  );
};

export default TaskNameField;