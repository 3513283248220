import React, { useEffect, useState, useCallback, SyntheticEvent } from 'react';
import { Dialog, DialogActions, DialogTitle, IconButton, DialogContent, Card, CardContent, InputLabel } from '@mui/material';
import Grid from '@mui/material/Grid2';
import CloseIcon from '@mui/icons-material/Close';

import TaskNameField from './TaskNameField';
import TaskStatusField from './TaskStatusField';
import TaskCombinedField from './TaskCombinedField';
import TaskAnswerField from './TaskAnswerField';
import TaskUserDetail from './TaskUserDetail';
import TaskCommentAndHistory from './TaskCommentAndHistory';
import ParentTaskDetailSubtaskTable from './ParentTaskDetailSubtaskTable';
import TaskPriorityField from './TaskPriorityField';
import TaskFileTable from './TaskFileTable';
import TaskFileUploadField from './TaskFileUploadField';
import { Task } from '../../../models/Task/Pano';
import { TaskManagementService } from '../../../services/api';
import { History } from '../../../models/Task/Pano';
import { useTranslation } from 'react-i18next';
import AIResponseSuggestion from './AIResponseSuggestion';
import AIDocumentSuggestion from './AIDocumentSuggestion';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import { TaskLabelModel } from '../../../models/Task/Pano';


// Define the interface for props

interface ParentTaskDetailProps {
    open: boolean;
    onClose: () => void;
    taskID: string;
    currentProjectIndependentAuditor: string;
    projectLabels: TaskLabelModel[];
    includeIDA: boolean;
}

const ParentTaskDetail: React.FC<ParentTaskDetailProps> = ({ open, onClose, taskID, currentProjectIndependentAuditor, projectLabels, includeIDA }) => {

    const [myTask, setMyTask] = useState<Task | null>();
    const [allHistory, setAllHistory] = useState<History[]>([]);
    const [historyNum, setHistoryNum] = useState<number>(1);
    const [hist, setHist] = useState<History[]>([]);
    const [currentTab, setCurrentTab] = useState<string>("");
    const [isScrolledToBottom, setIsScrolledToBottom] = useState<boolean>(false);
    const { t } = useTranslation('global');
    const [refresh, setRefresh] = useState<boolean>(false);


    const getMyTask = useCallback(async () => {
        if (taskID !== "") {
            const response = await TaskManagementService.get("task/" + taskID, "json");
            console.log("get my task response->", response);
            const taskData: Task = response as Task;
            console.log("taskdata label -< " ,taskData.taskLabels)
            setMyTask(taskData);
        } else {
            console.log("task id not found parent task id");
        }

    }, [taskID]);

    const getAllHistory = useCallback(async () => {
        if (taskID !== "" && currentTab === "history") {
            const response = await TaskManagementService.get("history/" + taskID, "json");
            const history: History[] = response[0]?.history as History[];
            setAllHistory(history);
            setHist(history.slice(0, 10 * historyNum));
        }
    }, [taskID, historyNum, currentTab])

    useEffect(() => {
        if (open && taskID) {
            getMyTask();
            getAllHistory();
        }
    }, [taskID, open, getMyTask, getAllHistory]);


    const handleFieldChange = (value: any, field: string): void => {
        if (myTask) {
            if (field === "aiResponse") {
                setRefresh(true);
                const updatedTask = { ...myTask, "response": value };
                setMyTask(updatedTask);
                updateTaskIsBlur(true, updatedTask);
                setTimeout(() => {
                    setRefresh(false)
                }, 1);
            } else {
                const updatedTask = { ...myTask, [field]: value };
                setMyTask(updatedTask);
                if (["priority", "status", "responsible", "targetDate", "approver", "internalAuditor", "independentAuditor", "comments", "documents"].includes(field)) {
                    updateTaskOnchangeEvent(updatedTask);
                }
            }

        }
    };

    const updateTaskOnchangeEvent = async (task: Task) => {
        console.log("giden taskim -> ", task);
        task.lastUpdate = new Date();
        const response = await TaskManagementService.update(task.taskID, task, "task");
        console.log("my response updateTaskOnchangeEvent -> ", response);
        getMyTask();
        getAllHistory();
    };

    const updateTaskIsBlur = async (isBlur: boolean, task: Task) => {
        if (isBlur) {
            task.lastUpdate = new Date();
            const response = await TaskManagementService.update(task.taskID, task, "task");
            console.log("my response is blur -> ", response);
            getMyTask();
            getAllHistory();
        } else {
            console.log("update yapamazsın");
        }
    };

    const fileUpload = async (file: File) => {
        const parent = { ...myTask }
        if (parent._id) {
            const formData = new FormData();
            formData.append("files", file);
            const fileUrl = "file/" + parent.taskID;
            const response = await TaskManagementService.create(formData, fileUrl);
            console.log("my parent task file up response -> ", response);
            getMyTask();
            getAllHistory();
        }
    }


    const handleScroll = (e: SyntheticEvent<HTMLElement>) => {
        const target = e.target as HTMLElement;
        const bottom = target.scrollHeight - target.scrollTop - target.clientHeight < 20;  // 10px tolerans
        if (bottom && !isScrolledToBottom) {
            setIsScrolledToBottom(true);
            console.log('Aşağı indin');
            if (historyNum * 10 < allHistory.length && currentTab === "history") {
                console.log("if içi");
                setHistoryNum(historyNum + 1);
                console.log("historyNum -> ", historyNum)
                console.log("hist leng -> ", hist.length);
                console.log("---------");
            } else {
                console.log("else içi");
                console.log("allHistory length -> ", allHistory.length)
                console.log("historyNum -> ", historyNum);
                console.log("hist leng -> ", hist.length);
                console.log("---------");
            }

        } else if (!bottom && isScrolledToBottom) {
            setIsScrolledToBottom(false);
        }
    };


    return (

        <Dialog
            open={open}
            onClose={() => { onClose(); setMyTask(null); setHistoryNum(1) }}
            maxWidth="lg"
            fullWidth
            PaperProps={{ style: { height: "100vh", width: "100vw", margin: 10 } }}
        >
            <DialogActions style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <DialogTitle style={{ margin: 0 }}>
                    <div>
                        {t('task.task_id')}: <span>{myTask?.taskID}</span>
                    </div>
                    <div>
                        {t('task.ref_num')}: {myTask?.refNum}
                    </div>
                </DialogTitle>
                <IconButton onClick={() => { onClose(); setMyTask(null); setHistoryNum(1); }}>
                    <CloseIcon />
                </IconButton>
            </DialogActions>
            <DialogContent
                style={{
                    maxHeight: "70vh",
                    overflowY: "auto",
                    scrollbarWidth: "thin",
                    scrollbarColor: "#6b6b6b #f1f1f1",
                }}
                onScroll={(e) => handleScroll(e)}
            >
                <Grid container spacing={6} columns={12}>
                    <Grid container spacing={6} size={12}>

                        <Grid container spacing={3} size={6}>
                            <Grid size={12}>
                                {myTask?.taskPermission.every((taskPerm) => taskPerm.permissions.status === "UnvisibleF") && myTask?.taskPermission.every((taskPerm) => taskPerm.permissions.priority === "UnvisibleF") ? (null) : (
                                    <Card elevation={7} square>
                                        <CardContent>
                                            <Grid container spacing={2}>
                                                <Grid size={6}>
                                                    {myTask?.taskPermission.every((taskPerm) => taskPerm.permissions.status !== "UnvisibleF") ? (
                                                        <div>
                                                            <InputLabel>{t('task.status')}</InputLabel>
                                                            <TaskStatusField
                                                                item={myTask?.status || ""}
                                                                onStatusChange={(newStatus) => { handleFieldChange(newStatus, "status") }}
                                                                allStatus={myTask?.possibleNextStatus?.map((status) => status) || []}
                                                                permission={!myTask?.taskPermission.some((taskPerm) => taskPerm.permissions.status === "Editable")}
                                                            />
                                                        </div>
                                                    ) : null
                                                    }
                                                </Grid>
                                                <Grid size={6}>
                                                    {myTask?.taskPermission.every((taskPerm) => taskPerm.permissions.priority !== "UnvisibleF") ? (
                                                        <div>
                                                            <InputLabel>{t('task.priority')}</InputLabel>
                                                            <TaskPriorityField
                                                                item={myTask?.priority || ""}
                                                                onPriorityChange={(newPriority) => { handleFieldChange(newPriority, "priority") }}
                                                                priorityPermission={!myTask?.taskPermission.some((taskPerm) => taskPerm.permissions.priority === "Editable")}
                                                            />
                                                        </div>
                                                    ) : null}

                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                )}
                            </Grid>
                            <Grid size={12}>
                                {myTask?.taskPermission.every((taskPerm) => taskPerm.permissions.regulationRef !== "UnvisibleF") ? (
                                    <Card elevation={7} square>
                                        <CardContent>
                                            <TaskCombinedField
                                                item={myTask?.regulationRef || ""}
                                                multiline={true}
                                                rows={3}
                                                label={true}
                                                disabled={!myTask?.taskPermission.some((taskPerm) => taskPerm.permissions.regulationRef === "Editable")}
                                                onTaskCombinedFieldChange={(newCombinedField) => { handleFieldChange(newCombinedField, "regulationRef") }}
                                                onBlurCallBack={(isBlurred) => {
                                                    if (myTask) {
                                                        updateTaskIsBlur(isBlurred, myTask);
                                                    }
                                                }}
                                                type='detail'
                                                taskId={myTask.taskID}
                                                regulationRefDetail={myTask?.regulationRefDetail || ""}
                                                paragraph={myTask?.paragraph}
                                                article={myTask?.article}
                                            />
                                        </CardContent>
                                    </Card>
                                ) : null}
                            </Grid>
                            <Grid size={12}>
                                {myTask?.taskPermission.every((taskPerm) => taskPerm.permissions.task !== "UnvisibleF") ? (
                                    <Card elevation={7} square>
                                        <CardContent>
                                            <TaskNameField
                                                item={myTask?.task || ""}
                                                multiline={true}
                                                rows={3}
                                                onTaskNameChange={(newValue) => { handleFieldChange(newValue, "task") }}
                                                onBlurCallBack={(isBlurred) => {
                                                    if (myTask) {
                                                        updateTaskIsBlur(isBlurred, myTask);
                                                    }
                                                }}
                                                label={false}
                                                permission={!myTask?.taskPermission.some((taskPerm) => taskPerm.permissions.task === "Editable")}
                                            />
                                        </CardContent>
                                    </Card>
                                ) : null}
                            </Grid>
                        </Grid>
                        <Grid container size={6}>
                            <Grid sx={{ marginTop: 3 }} size={12}>
                                <Card square sx={{ backgroundColor: "whitesmoke" }} elevation={7}>
                                    <CardContent>
                                        <TaskUserDetail
                                            selectedTicketOwner={myTask?.responsible || ""}
                                            selectedTicketOwnerManager={myTask?.approver || ""}
                                            selectedInternalAuditor={myTask?.internalAuditor || ""}
                                            selectedIndependentAuditor={currentProjectIndependentAuditor}
                                            targetDate={myTask?.targetDate || null}
                                            onTicketOwnerChange={(newTicketOwner) => { handleFieldChange(newTicketOwner, "responsible") }}
                                            onTicketOwnerManagerChange={(newTicketOwnerManager) => { handleFieldChange(newTicketOwnerManager, "approver") }}
                                            onInternalAuditorChange={(newInternalAuditor) => { handleFieldChange(newInternalAuditor, "internalAuditor") }}
                                            onIndependentAuditorChange={(newIndependentAuditor) => { handleFieldChange(newIndependentAuditor, "independentAuditor") }}
                                            onTargetDateChange={(newTargetDate) => { handleFieldChange(newTargetDate, "targetDate") }}
                                            disabled={false}
                                            requestDate={myTask?.requestDate || null}
                                            creationDate={myTask?.creationDate || null}
                                            lastUpdate={myTask?.lastUpdate || null}
                                            responsiblePermission={!myTask?.taskPermission.some((taskPerm) => taskPerm.permissions.responsible === "Editable")}
                                            approverPermission={!myTask?.taskPermission.some((taskPerm) => taskPerm.permissions.approver === "Editable")}
                                            internalAuditorPermission={!myTask?.taskPermission.some((taskPerm) => taskPerm.permissions.internalAuditor === "Editable")}
                                            independentAuditorPermission={true}
                                            targetDatePermission={!myTask?.taskPermission.some((taskPerm) => taskPerm.permissions.targetDate === "Editable")}
                                            visibleResponsible={myTask?.taskPermission.every((taskPerm) => taskPerm.permissions.responsible !== "UnvisibleF") ? false : true}
                                            visibleApprover={myTask?.taskPermission.every((taskPerm) => taskPerm.permissions.approver !== "UnvisibleF") ? false : true}
                                            visibleInternalAuditor={myTask?.taskPermission.every((taskPerm) => taskPerm.permissions.internalAuditor !== "UnvisibleF") ? false : true}
                                            visibleIndependentAuditor={myTask?.taskPermission.every((taskPerm) => taskPerm.permissions.independentAuditor !== "UnvisibleF") ? false : true}
                                            visibleTargetDate={myTask?.taskPermission.every((taskPerm) => taskPerm.permissions.targetDate !== "UnvisibleF") ? false : true}
                                            visibleRequestDate={myTask?.taskPermission.every((taskPerm) => taskPerm.permissions.requestDate !== "UnvisibleF") ? false : true}
                                            visibleCreationDate={myTask?.taskPermission.every((taskPerm) => taskPerm.permissions.creationDate !== "UnvisibleF") ? false : true}
                                            visibleLastUpdate={myTask?.taskPermission.every((taskPerm) => taskPerm.permissions.lastUpdateDate !== "UnvisibleF") ? false : true}
                                            taskLabels={myTask?.taskLabels || []}
                                            projectLabels={projectLabels}
                                            includeIDA={includeIDA}
                                        />
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container size={12} >
                        <Grid size={6}>
                            {myTask?.taskPermission.every((taskPerm) => taskPerm.permissions.response !== "UnvisibleF") ? (
                                <Card elevation={7} square>
                                    <CardContent>
                                        {!refresh ? (
                                            <div>
                                                <div style={{ marginBottom: 55 }}></div>

                                                <TaskAnswerField
                                                    item={myTask?.taskPermission.every((taskPerm) => taskPerm.permissions.response !== "UnvisibleC") ? (myTask?.response || "") : ""}
                                                    multiline={true}
                                                    rows={3}
                                                    onTaskAnswerChange={(newValue) => { handleFieldChange(newValue, "response") }}
                                                    onBlurCallBack={(isBlurred) => {
                                                        if (myTask) {
                                                            updateTaskIsBlur(isBlurred, myTask);
                                                        }
                                                    }}
                                                    label={false}
                                                    permission={!myTask?.taskPermission.some((taskPerm) => taskPerm.permissions.response === "Editable")}
                                                    maxRows={20}
                                                />
                                            </div>
                                        ) : null}

                                    </CardContent>
                                </Card>
                            ) : null}
                        </Grid>

                        <Grid size={6}>
                            <Card elevation={7} square>
                                <CardContent>
                                    <AIResponseSuggestion onSaveResponse={(response) => { handleFieldChange(response, "aiResponse") }} task={myTask?.task || ""} />
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>

                    <Grid container size={12}>
                        <Grid size={6}>
                            {myTask?.taskPermission.every((taskPerm) => taskPerm.permissions.documents !== "UnvisibleF") ? (
                                <Card square elevation={7}>
                                    <CardContent>
                                        <div>
                                            <TaskFileUploadField
                                                type='detail'
                                                allDocuments={myTask?.documents || []}
                                                onFileChange={(newFile) => { fileUpload(newFile) }}
                                                permission={!myTask?.taskPermission.some((taskPerm) => taskPerm.permissions.documents === "Editable")}
                                            />
                                            <TaskFileTable
                                                documents={myTask?.documents || []}
                                                onFileChange={(newFiles) => { handleFieldChange(newFiles, "documents") }}
                                                filePermission={!myTask?.taskPermission.some((taskPerm) => taskPerm.permissions.documents === "Editable")}

                                            />
                                        </div>
                                    </CardContent>
                                </Card>
                            ) : null}
                        </Grid>

                        <Grid size={6}>
                            <Card square elevation={7}>
                                <CardContent>
                                    <div>
                                        <IconButton>
                                            <SmartToyIcon style={{ color: "darkslateblue" }} />
                                        </IconButton>
                                        <AIDocumentSuggestion />
                                    </div>
                                </CardContent>
                            </Card>

                        </Grid>

                    </Grid>

                    <Grid container size={12}>
                        <Grid size={10}>
                            {myTask?.taskPermission.every((taskPerm) => taskPerm.permissions.subtaskName === "UnvisibleF") && myTask?.taskPermission.every((taskPerm) => taskPerm.permissions.subtaskStatus === "UnvisibleF") ? (null) : (
                                <ParentTaskDetailSubtaskTable
                                    subtasks={myTask?.subtasks || []}
                                    parentTask={myTask || null}
                                    statusPermission={!myTask?.taskPermission.some((taskPerm) => taskPerm.permissions.subtaskStatus === "Editable")}
                                    onRefresh={(refresh) => {
                                        if (refresh) {
                                            getMyTask();
                                        }
                                    }}
                                />
                            )}
                        </Grid>
                    </Grid>
                    <Grid container size={12} sx={{ marginTop: "40px" }}>
                        <Card square elevation={7}>
                            <CardContent>
                                <Grid size={12}>
                                    <TaskCommentAndHistory
                                        taskComment={myTask?.comments || []}
                                        onCommentAndHistoryChange={(newComments) => { handleFieldChange(newComments, "comments") }}
                                        visibleInternalComment={myTask?.taskPermission.every((taskPerm) => taskPerm.permissions.commentInternal === "UnvisibleF") ? true : false}
                                        visibleExternalComment={myTask?.taskPermission.every((taskPerm) => taskPerm.permissions.commentExternal === "UnvisibleF") ? true : false}
                                        visibleInternalHistory={myTask?.taskPermission.every((taskPerm) => taskPerm.permissions.historyInternal === "UnvisibleF") ? true : false}
                                        visibleExternalHistory={myTask?.taskPermission.every((taskPerm) => taskPerm.permissions.historyExternal === "UnvisibleF") ? true : false}
                                        internalCommentPermission={!myTask?.taskPermission.some((taskPerm) => taskPerm.permissions.commentInternal === "Editable")}
                                        externalCommentPermission={!myTask?.taskPermission.some((taskPerm) => taskPerm.permissions.commentExternal === "Editable")}
                                        internalHistoryPermmission={!myTask?.taskPermission.some((taskPerm) => taskPerm.permissions.historyInternal === "Editable")}
                                        externalHistoryPermission={"Editable"}
                                        type='parent'
                                        taskID={myTask?.taskID || ""}
                                        allHistory={hist}
                                        onChangeTab={(tab) => {
                                            if (tab === "comment") {
                                                setHistoryNum(1);
                                            }
                                            setCurrentTab(tab)
                                        }}
                                        allLabel={projectLabels}
                                    />
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>

            </DialogContent>


        </Dialog>

    );
};

export default ParentTaskDetail;