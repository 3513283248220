import React, { useState, useEffect } from 'react';
import { Select, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';

// Define the interface for props
interface TaskPriorityFieldProps {
    item: string; // Prop to receive the task name
    onPriorityChange: (newPriority: string) => void;
    priorityPermission: boolean;
}

const TaskPriorityField: React.FC<TaskPriorityFieldProps> = ({ item, onPriorityChange, priorityPermission }) => {
    const [taskPriority, setTaskpriority] = useState<string>('');
    const {t} = useTranslation('global')

    useEffect(() => {
        setTaskpriority(item || '');
    }, [item]);


    const getColor = (value: string): { backgroundColor: string; color: string } | undefined => {
        if (value === "Critical") {
            return { backgroundColor: "#3b198e", color: "white" };
        } else if (value === "High") {
            return { backgroundColor: "#3a83bb", color: "white" };
        } else if (value === "Medium") {
            return { backgroundColor: "#5558d5", color: "white" };
        } else if (value === "Low") {
            return { backgroundColor: "#6799f5", color: "white" };
        }else if(value === "N/A"){
            return { backgroundColor: "#FFFFFF", color: "white" };
        }
        return undefined;
    };

    const language: Record<string,string> = {
        "Low": t('task.low'),
        "Medium": t('task.medium'),
        "High":  t('task.high'),
        "Critical":  t('task.critical'),
    };

    return (

        <div>
            {!priorityPermission ? (
                <Select
                    fullWidth
                    value={taskPriority}
                    label="Task Priority"
                    onChange={(e) => {
                        setTaskpriority(e?.target.value);
                        onPriorityChange(e?.target.value);
                    }}
                    style={getColor(taskPriority)}
                    size="small"

                >
                    <MenuItem value="Critical" style={getColor("Critical")}>{language["Critical"]}</MenuItem>
                    <MenuItem value="High" style={getColor("High")}>{language["High"]}</MenuItem>
                    <MenuItem value="Medium" style={getColor("Medium")}>{language["Medium"]}</MenuItem>
                    <MenuItem value="Low" style={getColor("Low")}>{language["Low"]}</MenuItem>

                </Select>
            ) : (
                <div>
                    {
                        taskPriority !== "" ? (
                            <MenuItem value={taskPriority} style={getColor(taskPriority)}  sx={{borderRadius: "5px"}}>{language[taskPriority]}</MenuItem>
                        ) : (
                            <MenuItem value="N/A" style={getColor("N/A")}  sx={{borderRadius: "5px", height:"40px",border:"1px solid black"}}></MenuItem>
                        )
                    }
                </div>
            )}
        </div>
    );
};

export default TaskPriorityField;