import React, { useEffect, useState } from "react";
import { SwipeableDrawer, Typography, Button, IconButton, Select, MenuItem, FormControl, InputLabel, Box, TextField, List, ListItem, ListItemText, ListItemSecondaryAction } from "@mui/material";
import Grid from "@mui/material/Grid2";
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from "react-i18next";
import { WDocument } from "../../../models/Wizebuddy/Document";
import { WizebuddyService } from "../../../services/api";

interface AITask {
    id: number;
    taskName: string;
    type: string;
    dataFile: string;
    rulesFile: string;
    status: string;
}

interface AITaskDrawerProps {
    open: boolean;
    onClose: () => void;
}

const AITaskDrawer: React.FC<AITaskDrawerProps> = ({ open, onClose }) => {
    const { t } = useTranslation('global');
    const [taskName, setTaskName] = useState<string>('');
    const [type, setType] = useState<string>('firewall');
    const [dataFile, setDataFile] = useState<string>('');
    const [rulesFile, setRulesFile] = useState<string>('');
    const [allDocument, setAllDocument] = useState<WDocument[]>([]);
    const [tasks, setTasks] = useState<AITask[]>([]);
    const [editingTaskId, setEditingTaskId] = useState<number | null>(null);

    // Fetch all documents
    const getAllDocument = async () => {
        try {
            const response = await WizebuddyService.get('document', "json");
            setAllDocument(response as WDocument[]);
        } catch (error) {
            console.log("get document error -> ", error);
        }
    };

    // Fetch all tasks
    const fetchTasks = async () => {
        try {
            const response = await WizebuddyService.getAll("aitask");
            setTasks(response);
        } catch (error) {
            console.log("Error fetching tasks -> ", error);
        }
    };

    useEffect(() => {
        if (open) {
            getAllDocument();
            fetchTasks();
        }
    }, [open]);

    const resetValue = () => {
        setTaskName('');
        setType('firewall');
        setDataFile('');
        setRulesFile('');
        setEditingTaskId(null);
    };

    const handleSubmit = async () => {
        try {
            const taskData = { taskName, type, dataFile, rulesFile };
            if (editingTaskId) {
                // Update existing task
                await WizebuddyService.update(editingTaskId.toString(), "pending", "aitask"); // You might want to update more fields
            } else {
                // Create new task
                await WizebuddyService.create(taskData, "aitask");
            }
            resetValue();
            fetchTasks();
            onClose();
        } catch (error) {
            console.log("Task operation error -> ", error);
        }
    };

    const handleEdit = (task: AITask) => {
        setTaskName(task.taskName);
        setType(task.type);
        setDataFile(task.dataFile);
        setRulesFile(task.rulesFile);
        setEditingTaskId(task.id);
    };

    const handleDelete = async (taskId: number) => {
        try {
            await WizebuddyService.delete(taskId.toString(), "aitask");
            fetchTasks();
        } catch (error) {
            console.log("Delete task error -> ", error);
        }
    };

    return (
        <SwipeableDrawer
            open={open}
            onClose={() => { onClose(); resetValue(); }}
            anchor="right"
            onOpen={() => { }}
            PaperProps={{
                style: {
                    width: "60vh",
                    padding: 20,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    overflowY: "auto",
                },
                sx: {
                    "&::-webkit-scrollbar": {
                        width: "8px",
                    },
                    "&::-webkit-scrollbar-track": {
                        backgroundColor: "#f0f0f0",
                    },
                    "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "#c0c0c0",
                        borderRadius: "4px",
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                        backgroundColor: "#a0a0a0",
                    },
                },
            }}
        >
            <div style={{ flex: 1 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 15 }}>
                    <Typography variant="h5" sx={{ color: "#0b2343" }}>
                        {editingTaskId ? "Edit AI Task" : "Add AI Task"}
                    </Typography>
                    <IconButton onClick={() => { onClose(); resetValue(); }}>
                        <CloseIcon />
                    </IconButton>
                </div>

                {/* Task Form */}
                <Grid container spacing={3} justifyContent="center" sx={{ marginTop: "5%" }}>
                    <Grid size={12}>
                        <TextField
                            label="Task Name"
                            value={taskName}
                            onChange={(e) => setTaskName(e.target.value)}
                            fullWidth
                        />
                    </Grid>

                    <Grid size={12}>
                        <FormControl fullWidth>
                            <InputLabel>{"Data File"}</InputLabel>
                            <Select
                                value={dataFile}
                                onChange={(e) => setDataFile(e.target.value)}
                            >
                                {allDocument.map((doc, index) => (
                                    <MenuItem key={index} value={doc.name}>{doc.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid size={12}>
                        <FormControl fullWidth>
                            <InputLabel>{"Rules File"}</InputLabel>
                            <Select
                                value={rulesFile}
                                onChange={(e) => setRulesFile(e.target.value)}
                            >
                                {allDocument.map((doc, index) => (
                                    <MenuItem key={index} value={doc.name}>{doc.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid size={12}>
                        <FormControl fullWidth>
                            <InputLabel>{"Task Type"}</InputLabel>
                            <Select
                                value={type}
                                onChange={(e) => setType(e.target.value)}
                                label="Task Type"
                            >
                                <MenuItem value="firewall">FireWall</MenuItem>
                                {/* Add more task types as needed */}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>

                {/* Task List */}
                <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
                    Existing Tasks
                </Typography>
                <List>
                    {tasks.map((task) => (
                        <ListItem key={task.id}>
                            <ListItemText
                                primary={task.taskName}
                                secondary={`Type: ${task.type} | Status: ${task.status}`}
                            />
                            <ListItemSecondaryAction>
                                <IconButton edge="end" onClick={() => handleEdit(task)}>
                                    <EditIcon />
                                </IconButton>
                                <IconButton edge="end" onClick={() => handleDelete(task.id)}>
                                    <DeleteIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>
            </div>

            <Box mt={3} display="flex" justifyContent="space-between">
                <Button
                    variant="outlined"
                    size="medium"
                    onClick={() => {
                        onClose();
                        resetValue();
                    }}
                    style={{ backgroundColor: "#c10101", color: "white", textTransform: "none" }}
                >
                    {t('general.cancel')}
                </Button>

                <Button
                    variant="contained"
                    size="medium"
                    sx={{
                        backgroundColor: "#0b2343",
                        "&.Mui-disabled": {
                            backgroundColor: "grey",
                            color: "white",
                        },
                        textTransform: "none"
                    }}
                    onClick={handleSubmit}
                    disabled={taskName === "" || dataFile === "" || rulesFile === ""}
                >
                    {editingTaskId ? "Update Task" : "Save And Run"}
                </Button>
            </Box>
        </SwipeableDrawer>
    );
};

export default AITaskDrawer;
