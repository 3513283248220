
import React from 'react';
import {Box, Button, Card, CardContent, Typography} from "@mui/material";
import {Delete, Folder} from "@mui/icons-material";
import { useTranslation } from 'react-i18next';

import { keyframes } from '@mui/system';

export interface PathItem {
    id: string;
    name: string;
    path: string;
}

interface PathsListProps {
    paths: PathItem[];
    onDeletePath: (id: string) => void;
}

const PathsList: React.FC<PathsListProps> = ({ paths, onDeletePath }) => {
    const confirmDelete = (id: string, name: string) => {
        /*if (alert(`Are you sure you want to delete "${name}"?`)) {
            onDeletePath(id);
            alert("Path deleted successfully");
        }*/
        onDeletePath(id);
        alert("Path deleted successfully");
    };

    if (paths.length === 0) {
        return (
            <Card className="w-full mt-6 border-dashed animate-fade-in">
                <CardContent className="flex flex-col items-center justify-center py-10 text-center">
                    <div className="rounded-full bg-muted p-4 mb-4">
                        <Folder className="h-6 w-6 text-muted-foreground" />
                    </div>
                    <h3 className="text-lg font-semibold tracking-tight">No paths added yet</h3>
                    <p className="text-sm text-muted-foreground mt-1 mb-4 max-w-md">
                        Add file paths to specify where documents will be uploaded from
                    </p>
                </CardContent>
            </Card>
        );
    }



// Create the slide-in animation
    const slideIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;



    return (
        <div className="space-y-6 mt-6 animate-fade-in">
            <div className="flex items-center justify-between">
                <h2 className="text-xl font-semibold tracking-tight">Your Saved Paths</h2>
                <p className="text-sm text-muted-foreground">{paths.length} {paths.length === 1 ? 'path' : 'paths'}</p>
            </div>

            <div className="grid gap-4">
                {paths.map((item, index) => (
                    <Card
                        key={item.id}
                        sx={{
                            overflow: 'hidden',
                            transition: 'all 0.2s',
                            '&:hover': { boxShadow: 6 },
                            animation: `${slideIn} 0.3s ease forwards`,
                            animationDelay: `${index * 50}ms`
                        }}
                    >
                        <CardContent sx={{ padding: 0 }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                    justifyContent: 'space-between',
                                    p: 3
                                }}
                            >
                                <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }}>
                                    <Box
                                        sx={{
                                            borderRadius: 1,
                                            bgcolor: 'primary.light',
                                            opacity: 0.1,
                                            p: 1,
                                            color: 'primary.main'
                                        }}
                                    >
                                        <Folder sx={{ height: 20, width: 20 }} />
                                    </Box>
                                    <Box>
                                        <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                                            {item.name}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="text.secondary"
                                            sx={{ mt: 0.5, wordBreak: 'break-all' }}
                                        >
                                            {item.path}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box sx={{ display: 'flex', gap: 1 }}>
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        sx={{
                                            minWidth: 0,
                                            width: 32,
                                            height: 32,
                                            p: 0
                                        }}
                                        onClick={() => confirmDelete(item.id, item.name)}
                                    >
                                        <Delete sx={{ height: 16, width: 16, color: 'error.main' }} />
                                        <Box component="span" sx={{ position: 'absolute', width: 1, height: 1, overflow: 'hidden', clip: 'rect(0 0 0 0)' }}>
                                            Delete
                                        </Box>
                                    </Button>
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>
                ))}
            </div>
        </div>
    );
};

export default PathsList;
