import { Paper, Typography, Table, TableBody, TableHead, TableRow, TableCell, Button, Box, IconButton, TextField, InputAdornment } from "@mui/material";
import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid2";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import DomainsDrawer from "./DomainsDrawer";
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from "react-i18next";
import { WizebuddyService } from "../../../services/api";
import { Domain } from "../../../models/Wizebuddy/Domain";


const DomainsComponent: React.FC = () => {


    const [open, setOpen] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');
    const { t } = useTranslation('global');
    const [allDomain, setAllDomain] = useState<Domain[]>([]);


    const getAllDomain = async () => {
        try {

            const response = await WizebuddyService.get('domain', "json");
            setAllDomain(response.rows as Domain[]);

        } catch (error) {
            console.log("Domain get error -> ", error);
        }
    };

    useEffect(() => {
        getAllDomain();
    }, [])

    return (
        <Paper>

            <Grid container size={12}>

                <Grid size={12}>

                    <DomainsDrawer open={open} onClose={() => { 
                        setOpen(!open);
                         getAllDomain(); 
                         }} />

                </Grid>

                <Grid container size={12} sx={{ margin: 5 }}>
                    <Typography variant="h4" sx={{ color: "#0b2343", marginTop: "15px" }}>
                        {t('wizebuddy_domains.domains')}
                    </Typography>
                </Grid>


                <Grid size={12} sx={{ marginRight: 5, marginLeft: 5 }} container>

                    <Grid size={6}>

                        <TextField
                            label={t('task.search')}
                            fullWidth
                            value={search}
                            onChange={(e) => { setSearch(e.target.value) }}
                            slotProps={{
                                input: {
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                edge="end"
                                            >
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                },
                            }}
                        />
                    </Grid>

                    <Grid size={6} display="flex" justifyContent="flex-end">
                        <Button onClick={() => { setOpen(!open) }} variant="contained" style={{ backgroundColor: "#0b2343", color: "white", textTransform: "none", height: "5vh" }}>{t('wizebuddy_domains.create_domains')}</Button>
                    </Grid>

                </Grid>

                <Grid size={12} sx={{ margin: 5 }}>
                    <Table sx={{ border: "10px solid whitesmoke", marginBottom: 5 }}>
                        <TableHead style={{ backgroundColor: "whitesmoke" }}>
                            <TableCell sx={{ fontWeight: "bold" }}>
                                {t('wizebuddy_domains.domain_name')}
                            </TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>
                                {t('wizebuddy_domains.create_date')}
                            </TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>
                                {t('wizebuddy_categories.actions')}
                            </TableCell>
                        </TableHead>
                        <TableBody>
                            {allDomain.map((domain, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell>{domain.name}</TableCell>
                                        <TableCell>{domain.createdAt}</TableCell>
                                        <TableCell>
                                            <Box>
                                                <IconButton onClick={() => { }}>
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton onClick={() => { }}>
                                                    <DeleteIcon style={{ color: "red" }} />
                                                </IconButton>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                            }

                        </TableBody>
                    </Table>

                </Grid>

            </Grid>

        </Paper>
    )
}

export default DomainsComponent;