import React, { useEffect, useRef, useState } from "react";
import { SwipeableDrawer, Typography, Button, IconButton, Select, MenuItem, FormControl, InputLabel, FormControlLabel, Checkbox, Box } from "@mui/material";
import Grid from "@mui/material/Grid2";
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from "react-i18next";
import { TaskManagementService, WizebuddyService } from "../../../services/api";
import { Category } from "../../../models/Wizebuddy/Category";
import { PathItem } from "../../../models/Wizebuddy/PathItem";

interface DocumentDrawerProps {
    open: boolean;
    onClose: () => void;
}

const DocumentDrawer: React.FC<DocumentDrawerProps> = ({ open, onClose }) => {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [selectedCategory, setSelectedCategory] = useState<string>('');
    const [qaPair, setQaPair] = useState<boolean>(false);
    const { t } = useTranslation('global');
    const [allCategory, setAllCategory] = useState<Category[]>([]);

    const [paths, setPaths] = useState<PathItem[]>([]);
    const [selectedPath, setSelectedPath] = useState<string>('');

    const getPaths = async () => {
        console.log("getPaths");
        try {
            const response = await TaskManagementService.get("document-paths", "json");
            const savedPaths = response as PathItem[];
            if (Array.isArray(savedPaths)) {
                console.log("Get Paths Response", savedPaths);
                setPaths(savedPaths);
            } else {
                console.log('No saved paths found or response is not an array.');
                setPaths([]);
            }
        } catch (error) {
            console.error('Failed to parse saved paths:', error);
            setPaths([]);
        }
    };

    useEffect(() => {
        getPaths();
    }, []);

    const getAllCategory = async () => {
        try {
            const response = await WizebuddyService.get('category', "json");
            setAllCategory(response.rows as Category[]);
        } catch (error) {
            console.log("get all category error -> ", error);
        }
    };

    useEffect(() => {
        getAllCategory();
    }, []);

    const handleButtonClick = () => {
        fileInputRef.current?.click();
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setSelectedFile(file);
            console.log("Uploaded file:", file.name);
        }
    };

    const resetValue = () => {
        setSelectedFile(null);
        setQaPair(false);
        setSelectedCategory("");
        setSelectedPath("");
    };

    const uploadFile = async () => {
        try {
            const formData = new FormData();
            if (selectedFile) {
                formData.append('files', selectedFile, selectedFile.name);
                let metadata = { [selectedFile.name]: { chain: '', is_qa_pair: false, file_name: selectedFile.name } };
                formData.append('metadata', JSON.stringify(metadata));
                console.log("selectedPath", selectedPath)
                formData.append('filepath', selectedPath);

                console.log("form data -> ", formData);

                const response = await WizebuddyService.create(formData, 'document/upload');
                console.log("file upload response -> ", response);
                resetValue();
                onClose(); // Close the drawer after successful upload
            }
        } catch (error) {
            console.log("upload file error -> ", error);
        }
    };

    return (
        <SwipeableDrawer
            open={open}
            onClose={() => { onClose(); resetValue(); }}
            anchor="right"
            onOpen={() => { }}
            PaperProps={{
                style: {
                    width: "60vh",
                    padding: 20,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    overflowY: "auto",
                },
                sx: {
                    "&::-webkit-scrollbar": {
                        width: "8px",
                    },
                    "&::-webkit-scrollbar-track": {
                        backgroundColor: "#f0f0f0",
                    },
                    "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "#c0c0c0",
                        borderRadius: "4px",
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                        backgroundColor: "#a0a0a0",
                    },
                },
            }}
        >
            <div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 15 }}>
                    <Typography variant="h5" sx={{ color: "#0b2343" }}>
                        {t('wizebuddy_document.add_document')}
                    </Typography>
                    <IconButton onClick={() => { onClose(); resetValue(); }}>
                        <CloseIcon />
                    </IconButton>
                </div>

                <Grid container spacing={3} justifyContent="center" sx={{ marginTop: "5%" }}>

                    <Grid container display="flex" justifyContent="center" sx={{ marginTop: 3 }} size={12}>
                        <Button fullWidth variant="contained" style={{ backgroundColor: "#0b2343", color: "white" }} onClick={handleButtonClick}>
                            {t('wizebuddy_document.upload_file')}
                        </Button>
                        <input
                            type="file"
                            ref={fileInputRef}
                            style={{ display: "none" }}
                            onChange={handleFileChange}
                        />
                        {selectedFile && (
                            <Typography variant="body2" style={{ color: "#0b2343", fontWeight: "bold" }}>
                                {t('wizebuddy_document.uploaded_file')}: {selectedFile.name}
                            </Typography>
                        )}
                    </Grid>

                    <Grid container display="flex" justifyContent="center" sx={{ marginTop: 3 }} size={12}>
                        <FormControl fullWidth>
                            <InputLabel>{t('wizebuddy_document.category')}</InputLabel>
                            <Select
                                value={selectedCategory || ""}
                                onChange={(e) => setSelectedCategory(e.target.value)}
                                label="Category"
                            >
                                {allCategory.length > 0 ? (
                                    allCategory.map((category, index) => {
                                        return (
                                            <MenuItem key={index} value={category.id}>{category.name}</MenuItem>
                                        )
                                    })
                                ) : null}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid container display="flex" justifyContent="center" sx={{ marginTop: 3 }} size={12}>
                        <FormControl fullWidth>
                            <InputLabel>{t('wizebuddy_document.path')}</InputLabel>
                            <Select
                                value={selectedPath || ""}
                                onChange={(e) => setSelectedPath(e.target.value)}
                                label="File Path"
                            >
                                {paths.length > 0 ? (
                                    paths.map((path, index) => {
                                        return (
                                            <MenuItem key={index} value={path.path}>{path.name}</MenuItem>
                                        )
                                    })
                                ) : null}
                            </Select>
                        </FormControl>
                    </Grid>

                    {/*<Grid size={12}>
                        <FormControlLabel
                            checked={qaPair}
                            onChange={() => { setQaPair(!qaPair) }}
                            control={<Checkbox />}
                            label="Is QA Pair"
                        />
                    </Grid>*/}
                </Grid>
            </div>

            <Box mt={3} display="flex" justifyContent="space-between">
                <Button
                    variant="outlined"
                    size="medium"
                    onClick={() => {
                        onClose();
                        resetValue();
                    }}
                    style={{ backgroundColor: "#c10101", color: "white", textTransform: "none" }}
                >
                    {t('general.cancel')}
                </Button>

                <Button
                    variant="contained"
                    size="medium"
                    sx={{
                        backgroundColor: "#0b2343",
                        "&.Mui-disabled": {
                            backgroundColor: "grey",
                            color: "white",
                        },
                        textTransform: "none"
                    }}
                    onClick={() => { uploadFile(); }}
                    disabled={selectedCategory === "" || selectedFile === null || selectedPath === ""}
                >
                    {t('general.save')}
                </Button>
            </Box>
        </SwipeableDrawer>
    );
};

export default DocumentDrawer;
