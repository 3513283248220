import { Paper, Typography, Table, TableBody, TableHead, TableRow, TableCell, Button, Box, IconButton, TextField, InputAdornment } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactMarkdown from 'react-markdown';
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import Grid from "@mui/material/Grid2";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useTranslation } from "react-i18next";
import {TaskManagementService, WizebuddyService} from "../../../services/api";
import AITaskDrawer from "./AITasksDrawer";
import { AITask } from "../../../models/Wizebuddy/AITask";
import DeleteModal from "../../Common/DeleteModal";



const AITaskManagement: React.FC = () => {
    const [open, setOpen] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');
    const [tasks, setTasks] = useState<AITask[]>([]);
    const [selectedTaskId, setSelectedTaskId] = useState<number | null>(null);
    const [deletedItem, setDeletedItem] = useState<string>("");
    // New states for markdown modal
    const [markdownContent, setMarkdownContent] = useState<string>('');
    const [markdownModalOpen, setMarkdownModalOpen] = useState<boolean>(false);

    const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
    const { t } = useTranslation('global');

    // Fetch all tasks
    const fetchTasks = async () => {
        try {
            const response = await WizebuddyService.getAll('aitask');
            console.log("get all tasks -> ", response);
            setTasks(response as AITask[]);


        } catch (error) {
            console.error("Error fetching tasks:", error);
        }
    };

    // Search tasks
    const handleSearch = async () => {
        try {
            // const response = await WizebuddyService.get({ taskName: search });
            // setTasks(response);
            console.log("handle search");
        } catch (error) {
            console.error("Error searching tasks:", error);
        }
    };

    // Delete task
    const handleDelete = async (deletedItem: string) => {
        try {
            const response = await WizebuddyService.delete(deletedItem, "aitask");
            console.log("task delete response -> ", response);
            await fetchTasks();
            setDeletedItem("");
            setDeleteModalOpen(!deleteModalOpen);

        } catch (error) {
            console.error("Error deleting task:", error);
        }
    };


    const fetchMarkdownContent = async (id: string) => {
        try {

            const url = "aitask/" + id;
            const response = await WizebuddyService.getAll(url);
            const task = response as AITask
            setMarkdownContent(task.report);
            setMarkdownModalOpen(true);
        } catch (error) {
            console.error("Error fetching markdown content:", error);
        }
    };

    // Open drawer for editing
    const handleEdit = (id: number) => {
        setSelectedTaskId(id);
        setOpen(true);
    };

    useEffect(() => {
        fetchTasks();
    }, []);

    // Handle search when Enter is pressed or search button is clicked
    const handleSearchKeyPress = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    };

    return (
        <Paper>
            <Grid container size={12}>
                <Grid size={12}>
                    <AITaskDrawer
                        open={open}
                        onClose={() => {
                            setOpen(false);
                            setSelectedTaskId(null);
                            fetchTasks(); // Refresh tasks after drawer closes
                        }}
                    />
                </Grid>

                <Grid container size={12} sx={{ margin: 5 }}>
                    <Typography variant="h4" sx={{ color: "#0b2343", marginTop: "15px" }}>
                        {"AI Tasks"}
                    </Typography>
                </Grid>

                <Grid size={12} sx={{ marginRight: 5, marginLeft: 5 }} container>
                    <Grid size={6}>
                        <TextField
                            label={t('task.search')}
                            fullWidth
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            onKeyPress={handleSearchKeyPress}
                            slotProps={{
                                input: {
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton edge="end" onClick={handleSearch}>
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                },
                            }}
                        />
                    </Grid>

                    <Grid size={6} display="flex" justifyContent="flex-end">
                        <Button
                            onClick={() => {
                                setSelectedTaskId(null); // Ensure we're in create mode
                                setOpen(true);
                            }}
                            variant="contained"
                            style={{ backgroundColor: "#0b2343", color: "white", textTransform: "none", height: "5vh" }}
                        >
                            {"Add Task"}
                        </Button>
                    </Grid>
                </Grid>

                <Grid size={12} sx={{ margin: 5 }}>
                    <Table sx={{ border: "10px solid whitesmoke", marginBottom: 5 }}>
                        <TableHead style={{ backgroundColor: "whitesmoke" }}>
                            <TableRow>
                                <TableCell sx={{ fontWeight: "bold" }}>Task Name</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>Task Type</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>Status</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>Start Date</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>Data File</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>Rules File</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>Result File</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tasks.map((task) => (
                                <TableRow key={task.id}>
                                    <TableCell>{task.taskName}</TableCell>
                                    <TableCell>{task.type}</TableCell>
                                    <TableCell>{task.status}</TableCell>
                                    <TableCell>{new Date(task.createdAt).toLocaleDateString()}</TableCell>
                                    <TableCell>{task.dataFile}</TableCell>
                                    <TableCell>{task.rulesFile}</TableCell>
                                    <TableCell>{task.resultFile || "N/A"}</TableCell>
                                    <TableCell>
                                        <Box>
                                            <IconButton onClick={() => handleEdit(task.id)}>
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton onClick={() => handleDelete(task.id.toString())}>
                                                <DeleteIcon style={{ color: "red" }} />
                                            </IconButton>
                                            <IconButton onClick={() => fetchMarkdownContent(task.id.toString())}>
                                                <VisibilityIcon style={{ color: "gray" }} />
                                            </IconButton>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))}
                            {tasks.length === 0 && (
                                <TableRow>
                                    <TableCell colSpan={8} align="center">
                                        No tasks found
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>

            {/* Markdown Content Modal */}
            <Dialog open={markdownModalOpen} onClose={setMarkdownModalOpen}>

                    {/*<DialogTitle>Markdown Content</DialogTitle>
*/}
                <DialogContent>
                    <div className="prose min-w-[720px]">
                        <ReactMarkdown>{markdownContent}</ReactMarkdown>
                    </div>
                </DialogContent>
            </Dialog>

            {/*<DeleteModal
                open={deleteModalOpen}
                onClose={() => { setDeleteModalOpen(!deleteModalOpen) }}
                onResponse={(response) => {
                    if (response) {
                        deleteTask();
                    } else {
                        setDeletedItem("");
                        setDeleteModalOpen(!deleteModalOpen);
                    }
                }}

            />*/}
        </Paper>
    );
};

export default AITaskManagement;
