import React, { useEffect, useState } from "react";
import LabelIcon from '@mui/icons-material/Label';
import { Tooltip } from "@mui/material";
import { TaskLabelModel } from "../../../models/Task/Pano";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';


interface TaskLabelDetailProps {
    taskLabels: string[];
    projectLabels: TaskLabelModel[];
}

const TaskLabelDetail: React.FC<TaskLabelDetailProps> = ({ taskLabels, projectLabels }) => {
    const [taskLabel, setTaskLabel] = useState<string[]>([]);
    const [projectLabel, setProjectLabel] = useState<TaskLabelModel[]>([]);

    useEffect(() => {
        setTaskLabel(taskLabels);
        setProjectLabel(projectLabels);
        console.log("task label detail ", taskLabels);
        console.log("project label detail ", projectLabels);

    }, [taskLabels, projectLabels]);

    const filteredLabels = projectLabel.filter(label => taskLabel.includes(label._id));
    const visibleLabels = filteredLabels.slice(0, 5);
    const remainingLabels = filteredLabels.slice(5);

    return (
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            {visibleLabels.map((label) => (
                <Tooltip key={label._id} title={label.name}>
                    <LabelIcon style={{ color: label.color, fontSize: "30px" }} />
                </Tooltip>
            ))}

            {remainingLabels.length > 0 && (
                <Tooltip title={remainingLabels.map(l => l.name).join(", ")}>
                    <MoreHorizIcon style={{ fontSize: "30px" }} />
                </Tooltip>
            )}
        </div>
    );
}

export default TaskLabelDetail;