import React, { useEffect, useRef, useState } from "react";
import { SwipeableDrawer, Typography, Button, IconButton, Select, MenuItem, FormControl, InputLabel, FormControlLabel, Checkbox, Box, TextField } from "@mui/material";
import Grid from "@mui/material/Grid2";
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from "react-i18next";
import { Domain } from "../../../models/Wizebuddy/Domain";
import { WizebuddyService } from "../../../services/api";

interface CategoryDrawerProps {
    open: boolean;
    onClose: () => void;
    allDomain: Domain[];
}

const CategoryDrawer: React.FC<CategoryDrawerProps> = ({ open, onClose, allDomain }) => {
    const [selectedDomain, setSelectedDomain] = useState<string>('');
    const [categoryName, setCategoryName] = useState<string>('');
    const { t } = useTranslation('global');
    const [domains, setDomains] = useState<Domain[]>([]);


    useEffect(() => {
        setDomains(allDomain);
    }, [allDomain])


    const resetValue = () => {
        setSelectedDomain("");
        setCategoryName('');
    };


    const createCategory = async () => {
        try {

            let body = {
                name: categoryName,
                domain_id: selectedDomain
            }


            const response = await WizebuddyService.create(body, 'category');
            console.log("category create response -> ", response);
            resetValue();
            onClose();

        } catch (error) {
            console.log("category create error -> ", error);
        }
    }

    return (
        <SwipeableDrawer
            open={open}
            onClose={() => { onClose(); resetValue(); }}
            anchor="right"
            onOpen={() => { }}
            PaperProps={{
                style: {
                    width: "60vh",
                    padding: 20,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    overflowY: "auto",
                },
                sx: {
                    "&::-webkit-scrollbar": {
                        width: "8px",
                    },
                    "&::-webkit-scrollbar-track": {
                        backgroundColor: "#f0f0f0",
                    },
                    "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "#c0c0c0",
                        borderRadius: "4px",
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                        backgroundColor: "#a0a0a0",
                    },
                },
            }}
        >
            <div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 15 }}>
                    <Typography variant="h5" sx={{ color: "#0b2343" }}>
                        {t('wizebuddy_categories.add_category')}
                    </Typography>
                    <IconButton onClick={() => { onClose(); resetValue(); }}>
                        <CloseIcon />
                    </IconButton>
                </div>

                <Grid container spacing={3} justifyContent="center" sx={{ marginTop: "5%" }}>


                    <Grid size={12}>
                        <TextField
                            label={t('wizebuddy_categories.category_name')}
                            value={categoryName}
                            onChange={(e) => { setCategoryName(e.target.value) }}
                            fullWidth
                        />
                    </Grid>

                    <Grid container display="flex" justifyContent="center" sx={{ marginTop: 3 }} size={12}>
                        <FormControl fullWidth>
                            <InputLabel>{t('wizebuddy_categories.domain')}</InputLabel>
                            <Select
                                value={selectedDomain}
                                onChange={(e) => setSelectedDomain(e.target.value)}
                                label={t('wizebuddy_categories.domain')}
                            >
                                {domains.length > 0 ? (
                                    domains.map((domain,index) => {
                                        return (
                                            <MenuItem key={index} value={domain.id}>{domain.name}</MenuItem>
                                        )
                                    })
                                ) : null}
                            </Select>
                        </FormControl>
                    </Grid>

                </Grid>
            </div>



            <Box mt={3} display="flex" justifyContent="space-between">
                <Button
                    variant="outlined"
                    size="medium"
                    onClick={() => {
                        onClose();
                        resetValue();
                    }}
                    style={{ backgroundColor: "#c10101", color: "white", textTransform: "none" }}
                >
                    {t('general.cancel')}
                </Button>

                <Button
                    variant="contained"
                    size="medium"
                    sx={{
                        backgroundColor: "#0b2343",
                        "&.Mui-disabled": {
                            backgroundColor: "grey",
                            color: "white",
                        },
                        textTransform: "none"
                    }}
                    onClick={() => { createCategory();}}
                    disabled={categoryName === "" || selectedDomain === ""}
                >
                    {t('general.save')}
                </Button>
            </Box>
        </SwipeableDrawer>
    );
};

export default CategoryDrawer;