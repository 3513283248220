import React, { useEffect, useState } from "react";
import { Dialog, Box, Typography, DialogActions, DialogContent, IconButton, DialogTitle } from "@mui/material";
import { ConversationHistory } from "../../../models/Wizebuddy/ConversationHistory";
import CloseIcon from '@mui/icons-material/Close';
import { RiRobot3Line } from "react-icons/ri";
import { FaRegUser } from "react-icons/fa";
import { useTranslation } from "react-i18next";


interface ConversationHistoryModelProps {
    open: boolean;
    onClose: () => void;
    selectedHistory: ConversationHistory[];
}

const ConversationHistoryModel: React.FC<ConversationHistoryModelProps> = ({ open, onClose,selectedHistory }) => {
    const {t} = useTranslation('global');

    const [history, setHistory] = useState<ConversationHistory[]>([]);

    useEffect(()=>{
        setHistory(selectedHistory);
    },[selectedHistory])

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="lg"
            fullWidth
            PaperProps={{ style: { height: "100vh", width: "100vw", margin: 10 } }}
        >
            <DialogActions style={{ display: "flex", justifyContent: "space-between", alignItems: "center", backgroundColor: "whitesmoke" }}>

                <DialogTitle>
                    <Typography style={{ color: "#0b2343" }} variant="h5">{t('wizebuddy_conversation.conversation_detail')}</Typography>
                </DialogTitle>

                <IconButton onClick={onClose}>
                    <CloseIcon />
                </IconButton>

            </DialogActions>

            <DialogContent
                style={{
                    overflowY: "auto",
                    scrollbarWidth: "thin",
                    scrollbarColor: "lightgrey #f1f1f1",
                    backgroundColor: "whitesmoke"
                }}
            >

                {history.length > 0 ? (
                    history.map((item, index) => (
                        <Box
                            key={index}
                            sx={{
                                display: "flex",
                                justifyContent: item.role === "user" ? "flex-end" : "flex-start",
                                marginBottom: "10px",
                            }}
                        >
                            <Box sx={{display: "flex", justifyContent: item.role === "user" ? "flex-end" : "flex-start", alignItems: "center", margin: 0.5}} >
                                {item.role === "user" ? (<FaRegUser size={21} />) : (<RiRobot3Line size={24} />)}
                            </Box>
                            <Box
                                sx={{
                                    maxWidth: "70%",
                                    padding: "10px",
                                    borderRadius: "20px",
                                    backgroundColor: item.role === "user" ? "#6abc45" : "#0b2343",
                                    color: "white",
                                    wordWrap: "break-word",
                                    margin: 0.5
                                }}
                            >
                                <Typography variant="body2">{item.message}</Typography>
                            </Box>
                        </Box>
                    ))
                ) : (
                    <Typography variant="body2" sx={{ color: "gray" }}>
                        No past conversations were found.
                    </Typography>
                )}
            </DialogContent>

        </Dialog>
    );
};

export default ConversationHistoryModel;
