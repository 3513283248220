import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Dashboard from '../pages/Dashboard';
import Login from '../pages/Authentication/Login';
import Logout from '../pages/Authentication/Logout';
import Register from '../pages/Authentication/Register';
import UserManagement from '../pages/Accounts/UserManagement';
import RoleManagement from '../pages/Accounts/RoleManagement';
import PermissionManagement from '../pages/Accounts/PermissionManagement';
import Actions from '../pages/DataIntegration/Actions';
import Config from '../pages/DataIntegration/ThemeConfig';
import Settings from '../pages/Settings';
import Rules from '../pages/AccessAnalytics/Rules';
import EventsNotifications from '../pages/AccessAnalytics/EventsNotifications';  // Updated for Events & Notifications
import TaskPano from '../pages/TaskManagement/TaskPano';
import ProtectedRoute from './ProtectedRoute';
import TaskProject from '../pages/TaskManagement/TaskProject';
import Profile from '../pages/Profile';
import { Project } from '../models/Task/Pano';
import Tenant from '../pages/Tenant';
import ProjectSettings from '../pages/TaskManagement/ProjectSettings';
import AuditLogs from '../pages/AuditResponse/AuditLogs';
import ForgotPassword from '../pages/Authentication/ForgotPassword';
import CreateOwnPassword from '../pages/Authentication/CreateOwnPassword';
import Categories from '../pages/WizeBuddy/Categories';
import Conversations from '../pages/WizeBuddy/Conversations';
import Document from '../pages/WizeBuddy/Document';
import Domains from '../pages/WizeBuddy/Domains';
import Prompts from '../pages/WizeBuddy/Prompts';
import UnknownConversations from '../pages/WizeBuddy/UnKnownConversations';
import FilePathSettings from "../components/Settings/FilePathSettings";
import GeneralSettings from "../components/Settings/GeneralSettings";
import EmailConfiguration from "../components/Settings/EmailConfiguration";
import SettingsLayout from "../components/Settings/SettingsLayout";
import AITasks from '../pages/WizeBuddy/AITasks';

interface AppRoutesProps {
  onProjectListChange: (allProject: Project[]) => void;
}


const AppRoutes: React.FC<AppRoutesProps> = ({onProjectListChange}) => {

  const handleProject = (allProject: Project[]) =>{
    onProjectListChange(allProject)
  }
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/register" element={<Register />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/create-password/:tenantID/:uuid" element={<CreateOwnPassword />} />


      {/* Dashboard Route */}
      <Route path="/dashboard" element={<ProtectedRoute element={<Dashboard />} requiredRole={['admin', 'superadmin',"user"]}/>} />

      {/*Tenant Route */}
      <Route path="/tenant" element={<ProtectedRoute element={<Tenant />} requiredRole={['superadmin']}/>} />


      {/* Account Management Routes */}
      <Route path="/accounts" element={<Navigate to="/accounts/users" />} />
      <Route path="/accounts/users" element={<ProtectedRoute element={<UserManagement />} requiredRole={['admin', 'superadmin']}/>} />
      <Route path="/accounts/roles" element={<ProtectedRoute element={<RoleManagement />} requiredRole={['admin', 'superadmin']}/>} />
      <Route path="/accounts/permissions" element={<ProtectedRoute element={<PermissionManagement />} requiredRole={['admin', 'superadmin']}/>} />

      {/* Data Integration Routes */}
      <Route path="/data-integration" element={<Navigate to="/data-integration/config" />} />
      <Route path="/data-integration/config" element={<ProtectedRoute element={<Config />} requiredRole={['admin', 'superadmin']}/>} />
      <Route path="/data-integration/actions" element={<ProtectedRoute element={<Actions />} requiredRole={['admin', 'superadmin']}/>} />

      {/* Access Analytics Routes */}
      <Route path="/access-analytics" element={<Navigate to="/access-analytics/rules" />} />
      <Route path="/access-analytics/rules" element={<ProtectedRoute element={<Rules />} />} />
      <Route path="/access-analytics/events-notifications" element={<ProtectedRoute element={<EventsNotifications />} requiredRole={['admin', 'superadmin']}/>} />

      {/* Audit Response Routes */}
      <Route path="/audit-response" element={<Navigate to="/audit-response/audit-logs" />} />
      <Route path="/audit-response/audit-logs" element={<ProtectedRoute element={<AuditLogs />} requiredRole={['admin', 'superadmin']}/>} />

      {/* Task Management Routes */}
      <Route path="/task-management" element={<Navigate to="/task-management/pano" />} />
      <Route path="/task-management/project" element={<ProtectedRoute element={<TaskProject onProjectListChange={(allProject)=>{handleProject(allProject)}} />} requiredRole={['admin', 'superadmin',"user"]}/>} />
      <Route path="/task-management/project-tasks/:code" element={<ProtectedRoute element={<TaskPano />} requiredRole={['admin', 'superadmin', "user"]}/>} />
      <Route path="/task-management/project-settings/:code" element={<ProtectedRoute element={<ProjectSettings />} requiredRole={['admin', 'superadmin', "user"]}/>} />


      <Route path="/wizebuddy/category-management/categories" element={<ProtectedRoute element={<Categories />} requiredRole={['admin', 'superadmin']}/>} />
      <Route path="/wizebuddy/category-management/domains" element={<ProtectedRoute element={<Domains />} requiredRole={['admin', 'superadmin']}/>} />
      <Route path="/wizebuddy/category-management/prompts" element={<ProtectedRoute element={<Prompts />} requiredRole={['admin', 'superadmin']}/>} />
      <Route path="/wizebuddy/document-management" element={<ProtectedRoute element={<Document />} requiredRole={['admin', 'superadmin']}/>} />
      <Route path="/wizebuddy/conversation-management/conversations" element={<ProtectedRoute element={<Conversations />} requiredRole={['admin', 'superadmin']}/>} />
      <Route path="/wizebuddy/conversation-management/unknown-conversations" element={<ProtectedRoute element={<UnknownConversations />} requiredRole={['admin', 'superadmin']}/>} />
      <Route path="/wizebuddy/ai-tasks" element={<ProtectedRoute element={<AITasks />} requiredRole={['admin', 'superadmin']}/>} />

      {/* Settings Route */}
        {/*<Route path="/settings" element={<ProtectedRoute element={<Settings />} requiredRole={['admin', 'superadmin']}/>} />
        <Route path="/settings/general" element={<ProtectedRoute element={<GeneralSettings />} requiredRole={['admin', 'superadmin']}/>} />
        <Route path="/settings/file-paths" element={<ProtectedRoute element={<FilePathSettings />} requiredRole={['admin', 'superadmin']}/>} />
        <Route path="/settings/email-settings" element={<ProtectedRoute element={<EmailConfiguration />} requiredRole={['admin', 'superadmin']}/>} />*/}
        <Route path="/settings" element={<Settings />}>
            {/*<Route index element={<GeneralSettings />} />*/}
            <Route index path="file-paths" element={<FilePathSettings />} />
            <Route path="email-settings" element={<EmailConfiguration />} />
        </Route>

      <Route path="/profile" element={<ProtectedRoute element={<Profile />} requiredRole={['admin', 'superadmin',"user"]}/>} />


      {/* Default Route */}
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
};

export default AppRoutes;
