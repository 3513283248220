import React, { useEffect, useState } from "react";
import { FormGroup, FormControlLabel, Switch } from "@mui/material";
import Grid from '@mui/material/Grid2';
import { useTranslation } from "react-i18next";
import { Project } from "../../../models/Task/Pano";


interface ProjectNotifacationProps {
    project?: Project;
};


const ProjectRole: React.FC<ProjectNotifacationProps> = ({ project }) => {
    const { t } = useTranslation('global');
    const [independentAuditor,setIndependentAuditor] = useState<boolean>(false);


    useEffect(()=>{
        setIndependentAuditor(project?.includeIndependentAuditor ?? false);
    },[project])

    return (

        <Grid container>
            <Grid size={8} >
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={true}
                            />
                        }
                        label={t('project.include_responsible')}
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={true}
                            />
                        }
                        label={t('project.include_approver')}
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={true}
                            />
                        }
                        label={t('project.include_internal_auditor')}
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={independentAuditor}
                            />
                        }
                        label={t('project.include_independent_auditor')}
                    />
                </FormGroup>

            </Grid>
        </Grid>
    )

}

export default ProjectRole