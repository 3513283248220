import { Paper, Typography, Table, TableBody, TableHead, TableRow, TableCell, Button, Box, IconButton, TextField, InputAdornment } from "@mui/material";
import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid2";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CategoryDrawer from "./CategoryDrawer";
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from "react-i18next";
import { WizebuddyService } from "../../../services/api";
import { Category } from "../../../models/Wizebuddy/Category";
import { Domain } from "../../../models/Wizebuddy/Domain";


const CategoryComponent: React.FC = () => {

    const [open, setOpen] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');
    const { t } = useTranslation('global');
    const [allCategories, setAllCategories] = useState<Category[]>([]);
    const [allDomain, setAllDomain] = useState<Domain[]>([]);


    const getAllCategories = async () => {
        try {

            const response = await WizebuddyService.get('category', "json");
            console.log("get all category -> ", response);
            setAllCategories(response.rows as Category[]);

        } catch (error) {
            console.log("get all category -> ", error);
        }
    };

    
    const getAllDomain = async () => {
        try {

            const response = await WizebuddyService.get('domain', "json");
            console.log("get all domain -> ", response);
            setAllDomain(response.rows as Domain[]);

        } catch (error) {
            console.log("get all domain -> ", error);
        }
    };

    const deleteCategory = async (id: string) => {
        try{

            const response = await WizebuddyService.delete(id,'category');

            console.log("category delete response -> ", response);

            getAllCategories();

        }catch(error){
            console.log('category delete error ->',error);
        }
    }


    useEffect(() => {
        getAllCategories();
        getAllDomain();
    }, []);


    const getDomainName = (id: string) => {
        if (allDomain) {
            return allDomain.find((domain) => domain.id === id)?.name || "-";
        }
        return "-";
    };
    

    return (
        <Paper>

            <Grid container size={12}>

                <Grid size={12}>

                    <CategoryDrawer
                        open={open}
                        onClose={() => { 
                            setOpen(!open); 
                            getAllCategories();
                            getAllDomain();
                         }}
                        allDomain={allDomain}
                    />

                </Grid>

                <Grid container size={12} sx={{ margin: 5 }}>
                    <Typography variant="h4" sx={{ color: "#0b2343", marginTop: "15px" }}>
                        {t('wizebuddy_categories.categories')}
                    </Typography>
                </Grid>

                <Grid size={12} sx={{ marginRight: 5, marginLeft: 5 }} container>

                    <Grid size={6}>

                        <TextField
                            label={t('task.search')}
                            fullWidth
                            value={search}
                            onChange={(e) => { setSearch(e.target.value) }}
                            slotProps={{
                                input: {
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                edge="end"
                                            >
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                },
                            }}
                        />
                    </Grid>

                    <Grid size={6} display="flex" justifyContent="flex-end">
                        <Button onClick={() => { setOpen(!open) }} variant="contained" style={{ backgroundColor: "#0b2343", color: "white", textTransform: "none", height: "5vh" }}>{t('wizebuddy_categories.create_category')}</Button>
                    </Grid>

                </Grid>

                <Grid size={12} sx={{ margin: 5 }}>
                    <Table sx={{ border: "10px solid whitesmoke", marginBottom: 5 }}>
                        <TableHead style={{ backgroundColor: "whitesmoke" }}>
                            <TableCell sx={{ fontWeight: "bold" }}>
                                {t('wizebuddy_categories.category_name')}
                            </TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>
                                {t('wizebuddy_categories.domain')}
                            </TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>
                                {t('wizebuddy_categories.create_date')}
                            </TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>
                                {t('wizebuddy_categories.actions')}
                            </TableCell>
                        </TableHead>
                        <TableBody>
                            {allCategories.map((category, index) => {

                                return (
                                    <TableRow key={index}>
                                        <TableCell>{category.name}</TableCell>
                                        <TableCell>{getDomainName(category.domain_id)}</TableCell>
                                        <TableCell>{category.createdAt.split('T')[0]}</TableCell>
                                        <TableCell>
                                            <Box>
                                                <IconButton onClick={() => { }}>
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton onClick={() => {}}>
                                                    <DeleteIcon style={{ color: "red" }} />
                                                </IconButton>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                )

                            })}

                        </TableBody>
                    </Table>

                </Grid>

            </Grid>

        </Paper>
    )
}

export default CategoryComponent;