import React, { ReactNode } from 'react';
import { Link, useLocation, Outlet } from 'react-router-dom';
import {
    Box,
    Container,
    Typography,
    Paper,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    styled
} from '@mui/material';
import {Email, Folder, Settings} from '@mui/icons-material';
import { keyframes } from '@mui/system';

// Create animations
const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const slideIn = keyframes`
  from { 
    opacity: 0;
    transform: translateY(10px);
  }
  to { 
    opacity: 1;
    transform: translateY(0);
  }
`;

// Styled components for animations
const FadeInBox = styled(Box)({
    animation: `${fadeIn} 0.3s ease-out`
});

const SlideInBox = styled(Box)({
    animation: `${slideIn} 0.3s ease-out`
});

// Custom Link component
const StyledLink = styled(Link)(({ theme }) => ({
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1.5),
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1, 1.5),
    fontSize: '0.875rem',
    transition: 'all 0.2s',
    '&:focus': {
        outline: `2px solid ${theme.palette.primary.main}`,
        outlineOffset: '2px'
    },
    width: '100%'
}));

interface SettingsSidebarItemProps {
    to: string;
    icon: React.ElementType;
    children: ReactNode;
}

const SettingsSidebarItem: React.FC<SettingsSidebarItemProps> = ({
                                                                     to,
                                                                     icon: Icon,
                                                                     children
                                                                 }) => {
    const location = useLocation();
    const isActive = location.pathname === to;

    return (
        <ListItem disablePadding sx={{ mb: 0.5 }}>
            <StyledLink
                to={to}
                sx={(theme) => ({
                    color: isActive
                        ? theme.palette.primary.main
                        : theme.palette.text.secondary,
                    backgroundColor: isActive
                        ? `${theme.palette.primary.main}10` // 10% opacity
                        : 'transparent',
                    fontWeight: isActive ? 500 : 400,
                    '&:hover': {
                        backgroundColor: isActive
                            ? `${theme.palette.primary.main}15`
                            : theme.palette.action.hover,
                        color: isActive
                            ? theme.palette.primary.main
                            : theme.palette.text.primary
                    }
                })}
            >
                <Icon sx={{ fontSize: 18 }} />
                <Typography variant="body2">{children}</Typography>
            </StyledLink>
        </ListItem>
    );
};

interface SettingsLayoutProps {
    children: ReactNode;
}

const SettingsLayout: React.FC<SettingsLayoutProps> = ({ children }) => {
    return (
        <Container maxWidth="lg" sx={{ py: { xs: 3, md: 5 } }}>
            <FadeInBox>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        gap: { xs: 3, md: 5 }
                    }}
                >
                    <Box
                        component="aside"
                        sx={{
                            width: { xs: '100%', md: 256 },
                            flexShrink: 0
                        }}
                    >
                        <Paper
                            elevation={1}
                            sx={{
                                p: 2,
                                borderRadius: 1,
                                position: 'sticky',
                                top: 96
                            }}
                        >
                            <Box sx={{ mb: 2, px: 1.5 }}>
                                <Typography variant="h6" sx={{ fontWeight: 600, mb: 0.5 }}>
                                    Settings
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Manage your preferences
                                </Typography>
                            </Box>
                            <List sx={{ p: 0}}>
                                {/*<SettingsSidebarItem to="/settings" icon={Settings}>
                                    General
                                </SettingsSidebarItem>*/}
                                <SettingsSidebarItem to="/settings/file-paths" icon={Folder}>
                                    File Paths
                                </SettingsSidebarItem>
                                <SettingsSidebarItem to="/settings/email-settings" icon={Email}>
                                    Email Settings
                                </SettingsSidebarItem>
                            </List>
                        </Paper>
                    </Box>
                    <Box
                        component="main"
                        sx={{
                            flexGrow: 1,
                            minWidth: 0
                        }}
                    >
                        <SlideInBox>
                            <Outlet />
                        </SlideInBox>
                    </Box>
                </Box>
            </FadeInBox>
        </Container>
    );
};

export default SettingsLayout;
