import React, { useState, useEffect } from 'react';
import { Box, Tabs, Tab, Card, CardContent, Avatar, TextField, Tooltip, IconButton, Button } from '@mui/material';
import Grid from '@mui/material/Grid2';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';

import { Comment, History } from '../../../models/Task/Pano';
import { useAuth } from '../../../context/AuthContext';
import TaskHistory from './TaskHistory';
import { useTranslation } from 'react-i18next';
import { UserService } from '../../../services/api';
import { User } from '../../../models/Accounts/User';
import { TaskLabelModel } from '../../../models/Task/Pano';

interface TaskCommentAndHistoryProps {
    taskComment: Comment[];
    onCommentAndHistoryChange: (newComments: Comment[]) => void;
    internalCommentPermission: boolean;
    externalCommentPermission: boolean;
    internalHistoryPermmission: boolean;
    externalHistoryPermission: string;
    type: string;
    visibleInternalComment: boolean;
    visibleExternalComment: boolean;
    visibleInternalHistory: boolean;
    visibleExternalHistory: boolean;
    taskID?: string;
    allHistory: History[];
    onChangeTab: (tab: string) => void;
    allLabel: TaskLabelModel[];
}

const TaskCommentAndHistory: React.FC<TaskCommentAndHistoryProps> = ({
    taskComment,
    onCommentAndHistoryChange,
    internalCommentPermission,
    externalCommentPermission,
    internalHistoryPermmission,
    externalHistoryPermission,
    type,
    visibleInternalComment,
    visibleExternalComment,
    visibleInternalHistory,
    visibleExternalHistory,
    taskID,
    allHistory,
    onChangeTab,
    allLabel
}) => {

    const [mainTab, setMainTab] = useState("comment");
    const [commentTab, setCommentTab] = useState("internal");
    const [historyTab, setHistoryTab] = useState("internal");
    const { user } = useAuth();
    const [comments, setComments] = useState<Comment[]>();
    const [history, setHistory] = useState<History[]>();
    const [filteredComment, setFilteredComments] = useState<Comment[]>([]);
    const [filteredHistory, setFilteredHistory] = useState<History[]>([]);
    const [addComment, setAddComment] = useState(false);
    const [newComment, setNewComment] = useState("");
    const { t } = useTranslation('global');
    const [userList, setUserList] = useState<User[]>([]);


    useEffect(() => {
        setComments(taskComment || []);
        setHistory(allHistory || []);
    }, [taskComment, allHistory]);

    useEffect(() => {
        setFilteredComments(comments ? comments.filter((comm) => comm.type === commentTab) : []);
        setFilteredHistory(history ? history.filter((hist) => hist.historyType === historyTab) : []);
    }, [comments, commentTab, history, historyTab]);

    useEffect(() => {
        setCommentTab(visibleInternalComment ? "external" : visibleExternalComment ? "internal" : (visibleExternalComment && visibleInternalComment) ? "" : commentTab);
        setHistoryTab(visibleInternalHistory ? "external" : visibleExternalHistory ? "internal" : (visibleExternalHistory && visibleInternalHistory) ? "" : historyTab);
    }, [visibleInternalComment, visibleExternalComment, commentTab, visibleInternalHistory, visibleExternalHistory, historyTab, taskComment]);


    const handleMainTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setMainTab(newValue);
        onChangeTab(newValue);
    };

    const handleCommentTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setCommentTab(newValue);
    };

    const handleHistoryTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setHistoryTab(newValue);
    };

    const handleNewCommentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setNewComment(value);
    };

    const getAllUsers = async () => {
        if (mainTab === "comment") {
            try {
                const myFilter = {
                    page: 1,
                    limit: 1000,
                    sort: "firstname",
                    order: "asc",
                };
                const users = await UserService.getAllWithParam(myFilter, "filter");
                setUserList(users.users || []);
            } catch (error) {
                console.log("get all user error -> ", error);
            }
        }
    }

    useEffect(() => {
        getAllUsers();
    }, [])

    const saveComment = () => {
        const updatedComments = [
            ...(comments || []),
            {
                comment: newComment,
                owner: user?._id,
                type: commentTab,
                createDate: new Date(),
            },
        ];

        setComments(updatedComments);
        onCommentAndHistoryChange(updatedComments);
        setNewComment("");
    };

    const deleteComment = (index: number) => {

        if (comments) {
            const newCommand = comments.filter((comment) => comment.type !== commentTab);

            filteredComment.splice(index, 1);

            const allCom = [...newCommand, ...filteredComment];

            setComments(allCom);
            onCommentAndHistoryChange(allCom);
        } else {
            console.log("found any comments");
        }
    };

    const getUserColor = (id: string) => {

        const user = userList.find((user) => user._id === id);

        if (user) {
            return user.initialsColor;
        }

        return "grey"
    }

    const getUserName = (id: string) => {
        const user = userList.find((user) => user._id === id);

        if (user) {
            return user.firstname + " " + user.lastname;
        }

        return "-";
    }

    const getUserInitialsText = (id: string) => {
        const user = userList.find((user) => user._id === id);

        if (user) {
            return user.initialsText;
        }

        return "-";
    }

    return (

        <Box sx={{ minWidth: "50vw" }}>

            <Grid container size={12} sx={{ marginBottom: "10px" }}>
                <Tabs
                    value={mainTab}
                    onChange={handleMainTabChange}
                    textColor="primary"
                    indicatorColor="primary"
                    aria-label="main tabs"
                >
                    <Tab
                        value="comment"
                        label={t('task.comments')}
                        sx={{
                            fontSize: "20px",
                            "&.Mui-selected": {
                                color: "#0b2343",
                            }
                        }}
                    />
                    <Tab
                        value="history"
                        label={t('task.history')}
                        sx={{
                            fontSize: "20px",
                            "&.Mui-selected": {
                                color: "#0b2343",
                            },
                        }}
                    />
                </Tabs>

            </Grid>


            <Grid container size={12} sx={{ marginBottom: "10px" }}>

                {mainTab === "comment" ? (

                    <Grid container>
                        <Grid size={12}>
                            <Tabs
                                value={commentTab}
                                onChange={handleCommentTabChange}
                                textColor="primary"
                                indicatorColor="primary"
                                aria-label="main tabs"

                            >
                                {!visibleInternalComment ? <Tab value="internal" label={t('task.internal')} sx={{ fontSize: "12px" }} /> : null}
                                {(type === "parent" && !visibleExternalComment) ? (<Tab value="external" label={t('task.external')} sx={{ fontSize: "12px" }} />) : null}
                            </Tabs>
                        </Grid>

                        <Grid size={10} sx={{ margin: 3 }}>
                            {filteredComment && filteredComment.length > 0 ? (
                                filteredComment.map((comm, index) => (

                                    <Box display="flex" alignItems="flex-start" sx={{ marginBottom: 4 }}>
                                        <Tooltip title={getUserName(comm.owner)}>
                                            <Avatar
                                                style={{
                                                    backgroundColor: getUserColor(comm.owner),
                                                    fontSize: getUserInitialsText(comm.owner).length > 2 ? 14 : 16,
                                                }}
                                            >
                                                {getUserInitialsText(comm.owner)}
                                            </Avatar>
                                        </Tooltip>
                                        <Box display="flex" flexDirection="column" alignItems="flex-start" style={{ marginLeft: "10px", width: "auto" }}>
                                            <Box position="relative" sx={{ marginTop: 1.5 }}>
                                                <span style={{ wordWrap: "break-word", maxWidth: "100%" }}>
                                                    {comm.comment}
                                                </span>
                                            </Box>
                                            <Box display="flex" justifyContent="flex-start" position="relative" sx={{ marginTop: 1 }}>
                                                <span
                                                    onClick={() => {
                                                        if (user?._id === comm.owner) {
                                                            deleteComment(index);
                                                        }
                                                    }}
                                                    style={{
                                                        fontWeight: "bold",
                                                        color: user?._id !== comm.owner ? "grey" : "#0b2343",
                                                        cursor: user?._id === comm.owner ? "pointer" : "default",
                                                        marginRight: 10,
                                                    }}
                                                >
                                                    Delete
                                                </span>

                                                <span
                                                    style={{
                                                        color: "gray",
                                                    }}
                                                >
                                                    {comm.createDate
                                                        ? new Intl.DateTimeFormat("tr-TR", {
                                                            day: "2-digit",
                                                            month: "2-digit",
                                                            year: "2-digit",
                                                            hour: "2-digit",
                                                            minute: "2-digit",
                                                        }).format(new Date(comm.createDate))
                                                        : "-"}
                                                </span>
                                            </Box>

                                        </Box>

                                    </Box>


                                ))
                            ) : (
                                null
                            )}
                        </Grid>
                    </Grid>



                ) : mainTab === "history" ? (

                    <Grid container>
                        <Grid size={12}>
                            <Tabs
                                value={historyTab}
                                onChange={handleHistoryTabChange}
                                textColor="primary"
                                indicatorColor="primary"
                                aria-label="main tabs"

                            >
                                {!visibleInternalHistory ? <Tab value="internal" label={t('task.internal')} sx={{ fontSize: "12px" }} /> : null}
                                {(type === "parent" && !visibleExternalHistory) ? (<Tab value="external" label={t('task.external')} sx={{ fontSize: "12px" }} />) : null}
                            </Tabs>
                        </Grid>
                        <Grid size={12}>
                            <TaskHistory taskHistory={filteredHistory || []} projectLabels={allLabel} />
                        </Grid>
                    </Grid>

                ) : null}
            </Grid>

            <Grid container size={12}>
                {addComment ? (

                    <Grid size={10} display="flex">
                        <Card>
                            <CardContent style={{ display: 'flex', alignItems: 'flex-end' }}>
                                <TextField
                                    value={newComment}
                                    onChange={handleNewCommentChange}
                                    style={{ minWidth: "30vw", marginRight: "8px" }} // Sağ tarafta biraz boşluk bırakmak için marginRight ekledik
                                />

                                <IconButton onClick={() => {
                                    saveComment();
                                    setAddComment(!addComment);
                                    setNewComment("");
                                }}>
                                    <SendIcon />
                                </IconButton>
                            </CardContent>
                        </Card>
                    </Grid>



                ) : null}

                <Grid container size={12} justifyContent="flex-end" alignItems="flex-end" sx={{ minWidth: "30vw" }}>
                    {mainTab === "comment" ? (
                        <Grid>
                            <Button
                                variant="contained"
                                size="small"
                                style={{
                                    backgroundColor:
                                        ((commentTab === "internal" && internalCommentPermission) ? true : false)
                                            ||
                                            ((commentTab === "external" && externalCommentPermission) ? true : false) ?
                                            "lightgray" :
                                            "#0b2343",
                                    marginLeft: "10px"
                                }}
                                onClick={() => {
                                    setAddComment(!addComment);
                                    setNewComment("");
                                }}

                                disabled={((commentTab === "internal" && internalCommentPermission) ? true : false) || ((commentTab === "external" && externalCommentPermission) ? true : false)}

                            >
                                {t('task.add_comment')}
                            </Button>
                        </Grid>

                    ) : null
                    }
                </Grid>


            </Grid>
        </Box>
    );
};

export default TaskCommentAndHistory;