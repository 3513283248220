import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

interface AuthContextType {
  isAuthenticated: boolean;
  authantication_token: string | null;
  role: string;
  tenantCompany: string;
  tenantName: string;
  user: Record<string, any> | null;
  login: (email: string, password: string, tenantID: string) => Promise<void>;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [authantication_token, setToken] = useState<string | null>(null);
  const [role, setRole] = useState<string>('');
  const [user, setUser] = useState<Record<string, any> | null>(null);
  const [loading, setLoading] = useState(true);
  const [tenantCompany, setTenantCompany] = useState<string>("");
  const [tenantName, setTenantName] = useState<string>("");
  const { i18n } = useTranslation();

  useEffect(() => {
    const storedToken = localStorage.getItem('authantication_token');
    const storedRole = localStorage.getItem('role');
    const storedUser = localStorage.getItem('user');
    const storedCompany = localStorage.getItem('tenantCompany');
    const storedTenantName = localStorage.getItem('tenantName');



    if (storedToken && storedRole && storedUser && storedCompany && storedTenantName) {
      setToken(storedToken);
      setRole(storedRole);
      setTenantCompany(storedCompany)
      setTenantName(storedTenantName);
      const lang = navigator.language;
      i18n.changeLanguage(lang === "tr" ? "tr" : "en") 

      try {
        const parsedUser = JSON.parse(storedUser);
        setUser(parsedUser);
        console.log("user success")
      } catch (error) {
        console.log("user error")
        console.error('Error parsing user data:', error);
        setUser(null);
        logout();
      }

      setIsAuthenticated(true);
    }
    setLoading(false);
  }, []);

  const login = async (email: string, password: string, tenantID: string) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/api/login`, { email, password, tenantID });
      console.log("login response -> ",response.data.user);
      if (response.data.token && response.data.user.role) {
        setToken(response.data.token);
        setRole(response.data.user.role);
        setUser(response.data.user);
        setIsAuthenticated(true);
        setTenantCompany(response.data.user.tenantCompany);
        setTenantName(response.data.user.tenant);


        localStorage.setItem('authantication_token', response.data.token);
        localStorage.setItem('role', response.data.user.role);
        localStorage.setItem('user', JSON.stringify(response.data.user));
        localStorage.setItem('tenantCompany', response.data.user.tenantCompany);
        localStorage.setItem('tenantName', response.data.user.tenant);


      }
    } catch (error) {
      console.error('Login failed:', error);
      throw new Error('Login failed. Please check your credentials.');
    }
  };

  const logout = () => {
    setToken(null);
    setRole('');
    setIsAuthenticated(false);
    setUser(null);
    setTenantCompany('');
    setTenantName("");
    localStorage.removeItem('authantication_token');
    localStorage.removeItem('role');
    localStorage.removeItem('user');
    localStorage.removeItem('tenantCompany');
    localStorage.removeItem('tenantName');
  };

  return loading ? (
    <div>Loading...</div>
  ) : (
    <AuthContext.Provider value={{ isAuthenticated, authantication_token, role, tenantCompany, tenantName ,user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
