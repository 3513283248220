import React, { useState, useEffect } from "react";
import { Drawer, List, ListItemButton, ListItemIcon, ListItemText, Toolbar, Collapse } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import SettingsIcon from '@mui/icons-material/Settings';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// import ExitToAppIcon from '@mui/icons-material/ExitToApp';
// import DataUsageIcon from '@mui/icons-material/DataUsage';
// import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
// import BuildIcon from '@mui/icons-material/Build';
// import TaskIcon from '@mui/icons-material/Task';
// import SecurityIcon from '@mui/icons-material/Security'; // For Access Analytics
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import LockIcon from '@mui/icons-material/Lock';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import BusinessIcon from '@mui/icons-material/Business';
import { Project } from "../../models/Task/Pano";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../context/AuthContext";
import AssessmentIcon from '@mui/icons-material/Assessment';
import SummarizeIcon from '@mui/icons-material/Summarize';
import CategoryIcon from '@mui/icons-material/Category';
import ArticleIcon from '@mui/icons-material/Article';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import ListAltIcon from '@mui/icons-material/ListAlt';
import PsychologyIcon from '@mui/icons-material/Psychology';
import { BiCategory } from "react-icons/bi";
import { GrDomain } from "react-icons/gr";
import { BiMessageSquareDots } from "react-icons/bi";

import CheckIcon from '@mui/icons-material/Check';
import PendingIcon from '@mui/icons-material/Pending';

interface SidebarProps {
  allProject: Project[];
}


const Sidebar: React.FC<SidebarProps> = ({ allProject }) => {
  const [openAccount, setOpenAccount] = React.useState(false);
  // const [openDataIntegration, setOpenDataIntegration] = React.useState(false);
  const [openTaskManagement, setOpenTaskManagement] = React.useState(false);
  // const [openAccessAnalytics, setOpenAccessAnalytics] = React.useState(false); // access analytics
  const [openAuditResponse, setOpenAuditResponse] = React.useState(false);
  const [openWizebuddy, setOpenWizeBuddy] = React.useState(false);
  const [openCategoryManagement, setOpenCategoryManagement] = React.useState(false);
  const [openConversation, setOpenConversation] = React.useState(false);


  const navigate = useNavigate();
  const [projectList, setProjectList] = useState<Project[]>([]);
  const { user } = useAuth();

  const { t } = useTranslation('global');

  useEffect(() => {
    setProjectList(allProject);
  }, [allProject]);



  const handleAccountClick = () => {
    setOpenAccount(!openAccount);
  };

  // const handleDataIntegrationClick = () => {
  //   setOpenDataIntegration(!openDataIntegration);
  // };

  const handleTaskManagementClick = () => {
    setOpenTaskManagement(!openTaskManagement);
  };

  const handleAuditResponseClick = () => {
    setOpenAuditResponse(!openAuditResponse);
  };

  const handleWizeBuddyClick = () => {
    setOpenWizeBuddy(!openWizebuddy);
  };

  const handleCategoryManagementClick = () => {
    setOpenCategoryManagement(!openCategoryManagement);
  };

  const handleConversationClick = () => {
    setOpenConversation(!openConversation);
  }

  // use for the access analytics
  // const handleAccessAnalyticsClick = () => {
  //   setOpenAccessAnalytics(!openAccessAnalytics);
  // };

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: 240,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: 240,
          boxSizing: 'border-box',
          backgroundColor: 'whitesmoke',
          '&::-webkit-scrollbar': {
            width: '6px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#888',
            borderRadius: '10px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#555',
          },
        },
      }}
    >


      <Toolbar style={{ backgroundColor: "#0b2343" }} >
        <img src="/assets/images/logo-menu.png" alt="Planitsecure-Logo" onClick={() => { navigate('/dashboard') }} style={{ height: 40, marginBottom: 8, cursor: "pointer" }} />
      </Toolbar>
      <List>
        {/* Dashboard */}
        <ListItemButton component={Link} to="/dashboard">
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary={t('sidebar.dashboard')} />
        </ListItemButton>

        {user?.role === "superadmin" && (
          <ListItemButton component={Link} to="/tenant">
            <ListItemIcon>
              <BusinessIcon />
            </ListItemIcon>
            <ListItemText primary={t('sidebar.tenant')} />
          </ListItemButton>
        )}



        {user?.role !== "user" ? (
          <div>
            <ListItemButton onClick={handleAccountClick}>
              <ListItemIcon>
                <AccountCircleIcon />
              </ListItemIcon>
              <ListItemText primary={t('sidebar.account_management')} />
              {openAccount ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openAccount} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 4 }} component={Link} to="/accounts/users">
                  <ListItemIcon>
                    <PeopleIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('sidebar.users')} />
                </ListItemButton>
                <ListItemButton sx={{ pl: 4 }} component={Link} to="/accounts/roles">
                  <ListItemIcon>
                    <SupervisorAccountIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('sidebar.sub_roles')} />
                </ListItemButton>
                <ListItemButton sx={{ pl: 4 }} component={Link} to="/accounts/permissions">
                  <ListItemIcon>
                    <LockIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('sidebar.permissions')} />
                </ListItemButton>
              </List>
            </Collapse>
          </div>) : null}

        {/* Data Integration Section */}
        {/* <ListItemButton onClick={handleDataIntegrationClick}>
          <ListItemIcon>
            <DataUsageIcon />
          </ListItemIcon>
          <ListItemText primary="Data Integration" />
          {openDataIntegration ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openDataIntegration} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton sx={{ pl: 4 }} component={Link} to="/data-integration/config">
              <ListItemIcon>
                <SettingsApplicationsIcon />
              </ListItemIcon>
              <ListItemText primary="Config" />
            </ListItemButton>
            <ListItemButton sx={{ pl: 4 }} component={Link} to="/data-integration/actions">
              <ListItemIcon>
                <BuildIcon />
              </ListItemIcon>
              <ListItemText primary="Actions" />
            </ListItemButton>
          </List>
        </Collapse> */}

        {user?.role !== "user" ? (
          <div>
            <ListItemButton onClick={handleAuditResponseClick}>
              <ListItemIcon>
                <SummarizeIcon />
              </ListItemIcon>
              <ListItemText primary={t('sidebar.audit_response')} />
              {openAuditResponse ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openAuditResponse} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 4 }} component={Link} to="/audit-response/audit-logs">
                  <ListItemIcon>
                    <AssessmentIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('sidebar.audit_log')} />
                </ListItemButton>
              </List>
            </Collapse>
          </div>
        ) : null}

        <ListItemButton onClick={handleWizeBuddyClick}>
          <ListItemIcon>
            <ListAltIcon />
          </ListItemIcon>
          <ListItemText primary={"Wizebuddy"} />
          {openWizebuddy ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openWizebuddy} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton sx={{ pl: 4 }} onClick={handleCategoryManagementClick}>
              <ListItemIcon>
                <CategoryIcon />
              </ListItemIcon>
              <ListItemText primary={t('sidebar.category_management')} />
              {openCategoryManagement ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openCategoryManagement} timeout="auto" unmountOnExit>
              <List component="div" disablePadding style={{ marginLeft: 10 }}>
                <ListItemButton sx={{ pl: 4 }} component={Link} to="/wizebuddy/category-management/categories">
                  <ListItemIcon>
                    <BiCategory size={24} />
                  </ListItemIcon>
                  <ListItemText primary={t('sidebar.categories')} />
                </ListItemButton>
                <ListItemButton sx={{ pl: 4 }} component={Link} to="/wizebuddy/category-management/domains">
                  <ListItemIcon>
                    <GrDomain size={24} />
                  </ListItemIcon>
                  <ListItemText primary={t('sidebar.domains')} />
                </ListItemButton>
                <ListItemButton sx={{ pl: 4 }} component={Link} to="/wizebuddy/category-management/prompts">
                  <ListItemIcon>
                    <BiMessageSquareDots size={24} />
                  </ListItemIcon>
                  <ListItemText primary={t('sidebar.prompts')} />
                </ListItemButton>
              </List>
            </Collapse>

            <ListItemButton sx={{ pl: 4 }} component={Link} to="/wizebuddy/document-management">
              <ListItemIcon>
                <ArticleIcon />
              </ListItemIcon>
              <ListItemText primary={t('sidebar.document_management')} />
            </ListItemButton>

            <ListItemButton sx={{ pl: 4 }} onClick={handleConversationClick}>
              <ListItemIcon>
                <QuestionAnswerIcon />
              </ListItemIcon>
              <ListItemText primary={t('sidebar.conversation_management')} />
              {openConversation ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openConversation} timeout="auto" unmountOnExit>
              <List component="div" disablePadding style={{ marginLeft: 10 }}>
                <ListItemButton sx={{ pl: 4 }} component={Link} to="/wizebuddy/conversation-management/conversations">
                  <ListItemIcon>
                    <CheckIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('sidebar.conversations')} />
                </ListItemButton>
                <ListItemButton sx={{ pl: 4 }} component={Link} to="/wizebuddy/conversation-management/unknown-conversations">
                  <ListItemIcon>
                    <PendingIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('sidebar.unknown_conversation')} />
                </ListItemButton>
              </List>
            </Collapse>

            <ListItemButton sx={{ pl: 4 }} component={Link} to="/wizebuddy/ai-tasks">
              <ListItemIcon>
                <PsychologyIcon />
              </ListItemIcon>
              <ListItemText primary={"AI Tasks"} />
            </ListItemButton>

          </List>
        </Collapse>


        {/* Task Management Section */}
        <ListItemButton onClick={handleTaskManagementClick} component={Link} to="/task-management/project">
          <ListItemIcon>
            <AutoAwesomeMotionIcon />
          </ListItemIcon>
          <ListItemText primary={t('sidebar.project')} />
          {openTaskManagement ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openTaskManagement} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {projectList && projectList.length > 0 ? (
              <>
                {projectList.slice(0, 5).map((project, index) => (
                  <ListItemButton
                    sx={{ pl: 4 }}
                    component={Link}
                    to={`/task-management/project-tasks/${project.code}`}
                    key={index}
                  >
                    <ListItemIcon>
                      <AssignmentIcon />
                    </ListItemIcon>
                    <ListItemText primary={project.name} />
                  </ListItemButton>
                ))}

                {projectList.length > 5 && (
                  <ListItemButton
                    sx={{ pl: 4 }}
                    component={Link}
                    to="/task-management/project"
                  >
                    <ListItemIcon>
                      <AssignmentIcon />
                    </ListItemIcon>
                    <ListItemText primary={`More +${projectList.length - 5} Project`} />
                  </ListItemButton>
                )}
              </>
            ) : null}
          </List>
        </Collapse>


        {/* Access Analytics Section */}
        {/* <ListItemButton onClick={handleAccessAnalyticsClick}>
          <ListItemIcon>
            <SecurityIcon />
          </ListItemIcon>
          <ListItemText primary="Access Analytics" />
          {openAccessAnalytics ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openAccessAnalytics} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton sx={{ pl: 4 }} component={Link} to="/access-analytics/rules">
              <ListItemIcon>
                <SecurityIcon />
              </ListItemIcon>
              <ListItemText primary="Rules" />
            </ListItemButton>
            <ListItemButton sx={{ pl: 4 }} component={Link} to="/access-analytics/events-notifications">
              <ListItemIcon>
                <SecurityIcon />
              </ListItemIcon>
              <ListItemText primary="Events & Notifications" />
            </ListItemButton>
          </List>
        </Collapse> */}

        {user?.role !== "user" ? (
          <ListItemButton component={Link} to="/settings">
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary={t('sidebar.settings')} />
          </ListItemButton>
        ) : null}
      </List>
    </Drawer>
  );
};

export default Sidebar;