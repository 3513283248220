import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid2';
import { useParams } from 'react-router-dom';
import MailError from "./mail-component/MailError";
import SuccessfulApproved from "./mail-component/SuccessfulApproved";
import SuccessfulReject from "./mail-component/SuccessfulReject";
import { TaskMail } from "../../models/Task/Pano";
import { TaskManagementService } from "../../services/api";
import GeneralError from "./mail-component/GeneralError";

const TaskMailPage: React.FC = () => {
    const navigate = useNavigate();
    const { uuid, answer, tenant } = useParams();
    const [taskMail, setTaskMail] = useState<TaskMail>();
    const [errorMessage, setErrorMessage] = useState<string>("");

    const redirectProjectPage = () => {
        navigate("/task-management/project");
    };

    const request = async (newUUID: string, newAnswer: string, newTenant: string) => {

        try {
            const response = await TaskManagementService.putNoData(`task/status-changer/${newUUID}/${newTenant}/${newAnswer}`);
            const taskMailResponse: TaskMail = response as TaskMail;
            setTaskMail(taskMailResponse);
        } catch (error: any) {
            setErrorMessage(error?.response.data.error);
        }
    }

    useEffect(() => {
        if (uuid && answer && tenant) {
            request(uuid, answer, tenant);
        }
    }, [uuid, answer]);
    return (
        <Grid container sx={{ marginTop: "50px", justifyContent: "center", textAlign: "center" }}>
            <Grid size={12}>
                {taskMail?.status === "success-approve" ? (
                    <SuccessfulApproved projectName={taskMail.projectName} taskID={taskMail.taskID} taskName={taskMail.taskName} taskStatus={taskMail.taskStatus} />
                ) : taskMail?.status === "success-reject" ? (
                    <SuccessfulReject projectName={taskMail.projectName} taskID={taskMail.taskID} taskName={taskMail.taskName} taskStatus={taskMail.taskStatus} />
                ) : errorMessage !== "" ? (
                    <MailError error={errorMessage} />
                ) : (
                    <GeneralError/>
                )}
            </Grid>
            <Grid size={12} sx={{ marginTop: "10px" }}>
                <Button onClick={redirectProjectPage}
                    variant="outlined"
                    size="medium"
                    sx={{ backgroundColor: "#102240", color: "white" }}
                >
                    Go To Project Page
                </Button>
            </Grid>
        </Grid>
    );
};

export default TaskMailPage;
