import React, { useEffect, useState } from "react";
import { Card, CardContent, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import BusinessIcon from "@mui/icons-material/Business";
import { useLocation, useNavigate } from "react-router-dom";
import axios from 'axios';
import { useAuth } from "../../context/AuthContext";
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from "react-i18next";


const TenantLogin: React.FC = () => {
    const location = useLocation();
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [allTenants, setAllTenants] = useState<{ tenantID: string, tenantName: string }[]>([]);
    const { login } = useAuth();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(true);
    const { t } = useTranslation('global');


    useEffect(() => {

        const permission = localStorage.getItem('tenant-login-permission');

        if (permission === "true") {
            const newEmail = location.state?.email || "";
            const newPassword = location.state?.password || "";

            if (newEmail !== "" && newPassword !== "") {
                setEmail(newEmail);
                setPassword(newPassword);
                handleTenantLogin(newEmail, newPassword);
            }
        } else {
            localStorage.setItem('tenant-login-permission', "false");
            navigate("/login");
        }


    }, [location.state, navigate]);



    const handleTenantLogin = async (email: string, password: string) => {
        const tenantLoginBody = {
            email,
            password
        };
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/api/tenant-login`, tenantLoginBody);

            const tenant = response.data.data as { tenantID: string, tenantName: string }[];
            if (tenant.length === 1) {
                const id = tenant[0].tenantID;
                handleLogin(id, email, password);
            } else {
                setAllTenants(response.data.data);
                setLoading(false);
            }


        } catch (error) {
            console.log(error);
        }
    };

    const backToLoginPage = () => {
        navigate("/login");
        localStorage.setItem('tenant-login-permission', "false");
    };


    const handleLogin = async (tenantID: string, userEmail = "", userPassword = "") => {
        try {
            await login(userEmail === "" ? email : userEmail, userPassword === "" ? password : userPassword, tenantID);
            setTimeout(() => {
                navigate('/dashboard');
                localStorage.setItem('tenant-login-permission', "false");
            }, 500)

        } catch (error) {
            setTimeout(() => {
                backToLoginPage();
            }, 500);
        }
    };

    return (
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100vh" }}>
            <Card sx={{ width: "50vw", height: "50vh", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)" }}>
                <CardContent
                    sx={{
                        maxHeight: "50vh",
                        overflow: "auto",
                        scrollbarWidth: "thin",
                        scrollbarColor: "#e5e5e5 transparent",
                    }}
                >                    <Grid container spacing={3}>
                        <Grid size={12} textAlign="center">
                            <Typography variant="h6" sx={{ color: "#0b2343", fontWeight: "bold" }}>
                                {t("login.available_tenants")}
                            </Typography>
                            <Typography variant="body2" sx={{ color: "#0b2343" }}>
                                {t("login.click_to_tenant")}
                            </Typography>
                        </Grid>
                        {allTenants && allTenants.length > 0 ? allTenants.map((tenant) => (
                            <Grid size={4} key={tenant.tenantID}>
                                <Card onClick={() => { handleLogin(tenant.tenantID) }} sx={{ display: "flex", alignItems: "center", padding: 1, cursor: "pointer", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)" }}>
                                    <CardContent sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                        <BusinessIcon sx={{ color: "#0b2343", fontSize: 30, marginRight: 1.5 }} />
                                        <Typography variant="body1" sx={{ color: "#0b2343", fontWeight: "bold" }}>{tenant.tenantName}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        )) : (
                            <Grid size={12} container textAlign="center">
                                <Grid size={12} display="flex">
                                    {loading ? (
                                        <Grid size={12} justifyContent="center" alignItems="center">
                                            <CircularProgress sx={{ color: "#0b2343" }} />
                                        </Grid>
                                    ) : (
                                        <Grid container spacing={3} size={12}>
                                            <Grid size={12}>
                                                <Typography variant="body1" sx={{ color: "#0b2343", fontWeight: "bold" }}>{t("login.no_available_tenants")}</Typography>
                                            </Grid>
                                            <Grid size={12}>
                                                <Typography onClick={() => { backToLoginPage(); }} variant="body2" sx={{ color: "#0b2343", fontWeight: "bold", cursor: "pointer" }}>{t("login.return_login")}</Typography>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    );
};

export default TenantLogin;
