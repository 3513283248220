import React, { useState } from "react";
import { TextField, Button, Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';



const ForgotPassword: React.FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation('global');
    const [email, setEmail] = useState<string>("");


    const redirectToLogin = () => {
        navigate("/login");
    };

    
  const handleTenantForgotPassword = async () => {
    localStorage.setItem('tenant-forgot-password-permission', "true");
    navigate("/forgot-password-tenant", { state: { email} });
  };


    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
                backgroundColor: "whitesmoke",
                padding: 2,
            }}
        >

            <Box
                sx={{
                    backgroundColor: 'white',
                    padding: 4,
                    borderRadius: 2,
                    boxShadow: 3,
                    width: '100%',
                    maxWidth: 400,
                }}
            >

                <Box
                    sx={{
                        textAlign: 'center',
                        marginBottom: 3,
                    }}
                >
                    <img src="/assets/images/logo-light.png" alt="Planitsecure-Logo" style={{ height: 40, marginBottom: 8 }} />
                    <Typography variant="body2" color="#0b2343">
                        {t('forgot_password.info')}
                    </Typography>

                    <TextField
                        label={t('login.email')}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        onChange={(e) => { setEmail(e.target.value) }}

                    />

                    <Button
                        variant="contained"
                        size="medium"
                        disabled={email === ""}
                        onClick={()=>{handleTenantForgotPassword()}}
                        sx={{
                            backgroundColor: "#102240",
                            width: "70%",
                            margin: 2
                        }}
                    >
                        {t('forgot_password.reset_password')}
                    </Button>

                    <Typography variant="body2">
                        <span style={{ color: "blue", cursor: "pointer", textDecoration: "underline"}} onClick={() => { redirectToLogin() }}>{t('forgot_password.click_to_sign')}</span>
                    </Typography>
                </Box>


            </Box>

        </Box>


    )
}

export default ForgotPassword;
