import React, { useState } from 'react';
import {
    Card,
    CardContent,
    CardHeader,
    Typography,
    TextField,
    Button,
    Box,
    InputAdornment,
    CardActions
} from '@mui/material';
import { Delete, Save, Folder } from '@mui/icons-material';
import { keyframes } from '@mui/system';


// Create the scale-in animation
const scaleIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

interface FilePathProps {
    onSavePath: (path: string, name: string) => void;
}

const FilePath: React.FC<FilePathProps> = ({ onSavePath }) => {
    const [path, setPath] = useState('');
    const [name, setName] = useState('');
    const [inputFocused, setInputFocused] = useState(false);

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        if (!path.trim()) {
            // toast.error("Please enter a valid file path");
            return;
        }

        if (!name.trim()) {
            //toast.error("Please enter a name for this path");
            return;
        }

        onSavePath(path, name);
        // toast.success("Path saved successfully");
        setPath('');
        setName('');
    };

    return (
        <Card
            sx={{
                width: '100%',
                animation: `${scaleIn} 0.3s ease-out`,
                boxShadow: 2
            }}
        >
            <form onSubmit={handleSubmit}>
                <CardHeader
                    title={
                        <Typography variant="h5" sx={{ fontWeight: 500 }}>
                            Add New File Path
                        </Typography>
                    }
                    subheader={
                        <Typography variant="body2" color="text.secondary" sx={{ mt: 0.5 }}>
                            Specify where documents will be uploaded from
                        </Typography>
                    }
                />
                <CardContent sx={{ pt: 0 }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                            <Typography
                                component="label"
                                htmlFor="path-name"
                                variant="body2"
                                sx={{ fontWeight: 500 }}
                            >
                                Path Name
                            </Typography>
                            <TextField
                                id="path-name"
                                fullWidth
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                placeholder="Documents Folder"
                                size="small"
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '&.Mui-focused fieldset': {
                                            borderColor: 'primary.main',
                                            borderWidth: 2,
                                        }
                                    }
                                }}
                                onFocus={() => setInputFocused(true)}
                                onBlur={() => setInputFocused(false)}
                            />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                            <Typography
                                component="label"
                                htmlFor="file-path"
                                variant="body2"
                                sx={{ fontWeight: 500 }}
                            >
                                File Path
                            </Typography>
                            <TextField
                                id="file-path"
                                fullWidth
                                value={path}
                                onChange={(e) => setPath(e.target.value)}
                                placeholder="C:/Users/Documents"
                                size="small"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Folder sx={{ height: 16, width: 16, color: 'text.secondary' }} />
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '&.Mui-focused fieldset': {
                                            borderColor: 'primary.main',
                                            borderWidth: 2,
                                        }
                                    }
                                }}
                                onFocus={() => setInputFocused(true)}
                                onBlur={() => setInputFocused(false)}
                            />
                            <Typography variant="caption" color="text.secondary">
                                Enter the full path to the folder where files will be uploaded from
                            </Typography>
                        </Box>
                    </Box>
                </CardContent>
                <CardActions sx={{ padding: 2, justifyContent: 'flex-end', gap: 1 }}>
                    <Button
                        variant="outlined"
                        onClick={() => {
                            setPath('');
                            setName('');
                        }}
                        startIcon={<Delete sx={{ height: 16, width: 16 }} />}
                    >
                        Clear
                    </Button>
                    <Button
                        type="submit"
                        variant="contained"
                        startIcon={<Save sx={{ height: 16, width: 16 }} />}
                    >
                        Save Path
                    </Button>
                </CardActions>
            </form>
        </Card>
    );
};

export default FilePath;
