import React, { useEffect, useState } from "react";
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import { Box, IconButton, Select, MenuItem, TextField, Button, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import CloseIcon from '@mui/icons-material/Close';
import TaskUserSelect from "./TaskUserSelect";
import TaskStatusField from "./TaskStatusField";
import { TaskManagementService } from "../../../services/api";
import TaskPriorityField from "./TaskPriorityField";
import BulkTaskDeleteModal from "./BulkTaskDeleteModal";
import { SelectedStatus } from "../../../models/Task/Pano";
import { useTranslation } from "react-i18next";


interface BulkOperationProps {
    selectedAllTask: string[];
    onRefresh: (refresh: boolean) => void;
    onChangeSelectedTask: (list: string[]) => void;
    allList: SelectedStatus[];
}

const BulkOperation: React.FC<BulkOperationProps> = ({ selectedAllTask, onRefresh, onChangeSelectedTask, allList }) => {

    const [show, setShow] = useState<boolean>(false);

    const [selectedTasks, setSelectedTasks] = useState<string[]>([]);

    const [operation, setOperation] = useState<string>("");

    //role assign
    const [selectedRole, setSelectedRole] = useState<string>("responsible")
    const [selectedUser, setSelectedUser] = useState<string>("");

    //field update
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const [selectedPriority, setSelectedPriority] = useState<string>("Low");


    //delete modal
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

    //status
    const [selectedAllStatusList, setSelectedAllStatusList] = useState<SelectedStatus[]>([]);
    const [statusPermission, setStatusPermission] = useState<boolean>(true);
    const [possibleList, setPossibleList] = useState<string[]>([]);
    const [status, setStatus] = useState<string>("To Do");

    const { t } = useTranslation('global');


    useEffect(() => {
        setSelectedTasks(selectedAllTask);
        setSelectedAllStatusList(allList);
        findStatusList(allList);
    }, [selectedAllTask, allList])

    const findStatusList = (myList: SelectedStatus[]) => {
        console.log("my list -> ", myList);


        if (myList.length === 1) {
            const first = myList[0];
            setStatusPermission(false);
            setStatus(first.currentStatus);
            setPossibleList(first.possibleStatus);
        } else if (myList.length > 1) {
            const first = myList[0];

            const isMatch = myList.every((task) =>
                task.currentStatus === first.currentStatus &&
                JSON.stringify(task.possibleStatus) === JSON.stringify(first.possibleStatus)
            );

            if (isMatch) {
                setStatusPermission(false);
                setStatus(first.currentStatus);
                setPossibleList(first.possibleStatus);
            } else {
                setStatusPermission(true);
                setStatus("Not Assigned");
                setPossibleList([]);
            }

        } else {
            setStatusPermission(true);
            setStatus("Not Assigned");
            setPossibleList([]);
        }
    };




    const handleStartDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value ? new Date(event.target.value) : null;


        if (value) {
            setSelectedDate(value);
        } else {
            setSelectedDate(null)
        }
    };


    const bulkDelete = async () => {
        const data = { data: selectedAllTask }

        try {
            const response = await TaskManagementService.create(data, "task/bulk/delete");
            console.log("delete bulk response -> ", response);

            setTimeout(() => {
                setSelectedTasks([]);
                onRefresh(true);
                onChangeSelectedTask([]);
                console.log("refrsh ediliyor");
            }, 500)

        } catch (error) {
            console.log("bulk delete error -> ", error);
        }
    }


    const bulkAssignUser = async () => {
        try {
            const response = await TaskManagementService.create({ allTaskID: selectedAllTask, updateBody: { [selectedRole]: selectedUser } }, "task/bulk/update");
            console.log("update bulk response -> ", response);

            setTimeout(() => {
                setSelectedTasks([]);
                onRefresh(true);
                onChangeSelectedTask([]);
                console.log("refrsh ediliyor");
            }, 500)

        } catch (error) {
            console.log("bulk update error -> ", error);
        }

    }

    const bulkFieldUpdate = async (field: string) => {
        try {
            const response = await TaskManagementService.create({ allTaskID: selectedAllTask, updateBody: { [field]: field === "targetDate" ? selectedDate : field === "priority" ? selectedPriority : field === "status" ? status : "" } }, "task/bulk/update");
            console.log("update bulk response -> ", response);

            setTimeout(() => {
                setSelectedTasks([]);
                onRefresh(true);
                onChangeSelectedTask([]);
                setPossibleList([]);
                setStatus("Not Assigned");
                setStatusPermission(true);
                console.log("refrsh ediliyor");
            }, 500)

            console.log("status perm -> ", statusPermission);

        } catch (error) {
            console.log("bulk update error -> ", error);
        }
    }


    return (
        <div style={{ position: 'relative' }}>
            <BulkTaskDeleteModal
                open={showDeleteModal}
                onClose={() => { setShowDeleteModal(!showDeleteModal) }}
                onResponse={(response) => {

                    if (response) {
                        bulkDelete();
                        setShowDeleteModal(false);
                    } else {
                        setShowDeleteModal(false);
                    }

                }}
                taskNum={selectedAllTask.length}

            />
            <IconButton onClick={() => { setShow(!show) }}>
                <DynamicFeedIcon />
            </IconButton>

            {show && (
                <Box
                    sx={{
                        minWidth: '40vw',
                        minHeight: '28vw',
                        backgroundColor: 'white',
                        border: '1px solid',
                        borderColor: 'grey.500',
                        display: 'flex',
                        position: 'absolute',
                        top: '50px',
                        zIndex: 1,
                        padding: '16px',
                        borderRadius: "10px",
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)'
                    }}
                >

                    <Typography variant="h6" sx={{ fontWeight: "bold", position: "absolute", top: 10, left: 30, color: "#0b2343" }}>{t('task.bulk_operation')}</Typography>

                    <IconButton
                        onClick={() => { setShow(!show) }}
                        sx={{
                            position: 'absolute',
                            top: 0,
                            right: 10,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>

                    <Grid container columnSpacing={5} sx={{ marginLeft: 5, marginRight: 4, marginTop: 7 }}>

                        <Grid container size={6}>

                            <Grid size={12}>
                                <Button disabled={selectedAllTask.length === 0} onClick={() => { setShowDeleteModal(!showDeleteModal) }} sx={{ backgroundColor: "#0b2343", color: "white", textTransform: "none" }} fullWidth variant="contained">{t('task.delete')}</Button>
                            </Grid>

                            <Grid size={12}>
                                <Button onClick={() => { setOperation("rol") }} sx={{ backgroundColor: "#0b2343", color: "white", textTransform: "none" }} fullWidth variant="contained">{t('task.user_assign')}</Button>
                            </Grid>

                            <Grid size={12}>
                                <Button onClick={() => { setOperation("status") }} sx={{ backgroundColor: "#0b2343", color: "white", textTransform: "none" }} fullWidth variant="contained">{t('task.status_update')}</Button>
                            </Grid>

                            <Grid size={12}>
                                <Button onClick={() => { setOperation("targetDate") }} sx={{ backgroundColor: "#0b2343", color: "white", textTransform: "none" }} fullWidth variant="contained">{t('task.target_date_update')}</Button>
                            </Grid>

                            <Grid size={12}>
                                <Button onClick={() => { setOperation("priority") }} sx={{ backgroundColor: "#0b2343", color: "white", textTransform: "none" }} fullWidth variant="contained">{t('task.priority_update')}</Button>
                            </Grid>
                        </Grid>

                        <Grid container size={6}>

                            {operation === "rol" ? (
                                <Grid container size={12}>
                                    <Grid size={12}>
                                        <Select value={selectedRole} onChange={(e) => { setSelectedRole(e.target.value) }} fullWidth>
                                            <MenuItem value="responsible">{t('task.responsible')}</MenuItem>
                                            <MenuItem value="approver">{t('task.approver')}</MenuItem>
                                            <MenuItem value="internalAuditor">{t('task.internal_auditor')}</MenuItem>
                                        </Select>
                                    </Grid>
                                    <Grid size={12}>
                                        <TaskUserSelect user={selectedUser} onTaskUserChange={(newUser) => { setSelectedUser(newUser) }} permission={false} show={true} filter="Participant" />
                                    </Grid>

                                    <Grid size={12} justifyContent="center">
                                        <Button fullWidth onClick={() => { bulkAssignUser(); }} disabled={selectedUser === "" || selectedAllTask.length === 0} sx={{ backgroundColor: "#6abc45", color: "white", textTransform: "none" }} size="small" variant="contained">{t('task.apply')}</Button>
                                    </Grid>
                                </Grid>
                            ) : operation === "status" ? (
                                <Grid container size={12}>
                                    <Grid size={12}>
                                        <TaskStatusField item={status} allStatus={possibleList} permission={statusPermission} onStatusChange={(newSt) => { setStatus(newSt) }} />
                                        <Button fullWidth disabled={selectedAllTask.length === 0} onClick={() => { bulkFieldUpdate("status"); }} sx={{ backgroundColor: "#6abc45", color: "white", textTransform: "none", mt: 3 }} size="small" variant="contained">{t('task.update')}</Button>
                                    </Grid>
                                </Grid>
                            ) : operation === "targetDate" ? (

                                <Grid container size={12}>

                                    <Grid size={12}>
                                        <TextField
                                            type="date"
                                            fullWidth
                                            value={selectedDate ? selectedDate.toISOString().split('T')[0] : ''}
                                            onChange={handleStartDateChange}
                                        />
                                        <Button fullWidth disabled={selectedAllTask.length === 0} onClick={() => { bulkFieldUpdate("targetDate"); }} sx={{ backgroundColor: "#6abc45", color: "white", textTransform: "none", mt: 3 }} size="small" variant="contained">{t('task.update')}</Button>

                                    </Grid>

                                </Grid>

                            ) : operation === "priority" ? (
                                <Grid size={12} container>

                                    <Grid size={12}>
                                        <TaskPriorityField
                                            item={selectedPriority}
                                            onPriorityChange={(newPriority) => { setSelectedPriority(newPriority) }}
                                            priorityPermission={false}
                                        />
                                        <Button fullWidth disabled={selectedAllTask.length === 0} onClick={() => { bulkFieldUpdate("priority"); }} sx={{ backgroundColor: "#6abc45", color: "white", textTransform: "none", mt: 3 }} size="small" variant="contained">{t('task.update')}</Button>

                                    </Grid>

                                </Grid>
                            ) : null}

                        </Grid>
                    </Grid>
                </Box>
            )}
        </div>
    )
}
export default BulkOperation;
