import React, { useState } from "react";
import { SwipeableDrawer, Typography, Button, IconButton, Box, TextField } from "@mui/material";
import Grid from "@mui/material/Grid2";
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from "react-i18next";
import { WizebuddyService } from "../../../services/api";

interface PromptsDrawerProps {
    open: boolean;
    onClose: () => void;
}

const PromptsDrawer: React.FC<PromptsDrawerProps> = ({ open, onClose }) => {
    const [title, setTitle] = useState<string>('');
    const [prompt, setPrompt] = useState<string>('');
    const { t } = useTranslation('global')


    const resetValue = () => {
        setTitle('');
        setPrompt('');
    };


    const createPrompt = async () => {
        try{

            let body = {
                title,
                prompt
            }

            const response = await WizebuddyService.create(body,'prompt');

            console.log("prompt create -> ", response);

            resetValue();
            onClose();

        }catch(error){
            console.log("prompt create error -> ", error);
        }
    };

    return (
        <SwipeableDrawer
            open={open}
            onClose={() => { onClose(); resetValue(); }}
            anchor="right"
            onOpen={() => { }}
            PaperProps={{
                style: {
                    width: "60vh",
                    padding: 20,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    overflowY: "auto",
                },
                sx: {
                    "&::-webkit-scrollbar": {
                        width: "8px",
                    },
                    "&::-webkit-scrollbar-track": {
                        backgroundColor: "#f0f0f0",
                    },
                    "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "#c0c0c0",
                        borderRadius: "4px",
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                        backgroundColor: "#a0a0a0",
                    },
                },
            }}
        >
            <div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 15 }}>
                    <Typography variant="h5" sx={{ color: "#0b2343" }}>
                        {t('wizebuddy_prompts.add_prompts')}
                    </Typography>
                    <IconButton onClick={() => { onClose(); resetValue(); }}>
                        <CloseIcon />
                    </IconButton>
                </div>

                <Grid container spacing={3} justifyContent="center" sx={{ marginTop: "5%" }}>

                    <Grid size={12}>
                        <TextField
                            label={t('wizebuddy_prompts.title')}
                            value={title}
                            onChange={(e) => { setTitle(e.target.value) }}
                            fullWidth
                        />
                    </Grid>

                    <Grid size={12}>
                        <TextField
                            label={t('wizebuddy_prompts.prompt')}
                            value={prompt}
                            onChange={(e) => { setPrompt(e.target.value) }}
                            fullWidth
                            multiline
                            rows={5}
                        />
                    </Grid>
                </Grid>
            </div>



            <Box mt={3} display="flex" justifyContent="space-between">
                <Button
                    variant="outlined"
                    size="medium"
                    onClick={() => {
                        onClose();
                        resetValue();
                    }}
                    style={{ backgroundColor: "#c10101", color: "white", textTransform: "none" }}
                >
                    {t('general.cancel')}
                </Button>

                <Button
                    variant="contained"
                    size="medium"
                    sx={{
                        backgroundColor: "#0b2343",
                        "&.Mui-disabled": {
                            backgroundColor: "grey",
                            color: "white",
                        },
                        textTransform: "none"
                    }}
                    onClick={() => {createPrompt();}}
                    disabled={title === "" || prompt === ""}
                >
                    {t('general.save')}
                </Button>
            </Box>
        </SwipeableDrawer>
    );
};

export default PromptsDrawer;