import { Typography, CardContent, Button } from "@mui/material";
import Grid from '@mui/material/Grid2';
import { useEffect, useState } from "react";
import { useAuth } from "../../context/AuthContext";
import { useTranslation } from "react-i18next";
import { UserService } from "../../services/api";
import UserAvatar from "../Common/UserAvatar";

const UserInformation: React.FC = () => {

    const { user, role, tenantName } = useAuth();
    const [avatarText, setAvatarText] = useState<string>("");
    const [avatarColor, setAvatarColor] = useState<string>("");
    const { t } = useTranslation('global');
    const [userId, setUserId] = useState<string>('');


    useEffect(() => {
        setUserId(user?._id || "");
    },)

    const getProfileInformation = async () => {

        try {

            const currentUser = await UserService.get('profile/' + userId, "json")

            setAvatarText(currentUser?.initialsText || "NA");
            setAvatarColor(currentUser?.initialsColor || "#abcedf");


        } catch (error) {
            console.log("Get user profile error : ", error)
        }

    };

    useEffect(() => {
        if (userId) {
            getProfileInformation();
        }
    }, [userId]);


    const updateProfile = async () => {
        try{

            let body = {
                "initialsText": avatarText,
                "initialsColor": avatarColor
            }

            const response = await UserService.update(userId,body,'profile-update');

            console.log("profile update response -> ",response);

        }catch(error){
            console.log("Profile update error : ",error);
        }
    }


    return (
        <CardContent>
            <Grid container size={12} spacing={1} sx={{ alignItems: 'center' }}>
                <Grid container size={12} columnSpacing={1} direction="row" alignItems="center">
                    <Grid size={2}>
                        <Typography variant="body1" sx={{ color: "#0b2343", fontWeight: "bold", fontSize: "18px" }}>
                            {t('users.firstname')}
                        </Typography>
                    </Grid>
                    <Grid size={3}>{user?.firstname}</Grid>
                </Grid>
                <Grid container size={12} columnSpacing={1} direction="row" alignItems="center">
                    <Grid size={2}>
                        <Typography variant="body1" sx={{ color: "#0b2343", fontWeight: "bold", fontSize: "18px" }}>
                            {t('users.lastname')}
                        </Typography>
                    </Grid>
                    <Grid size={3}>{user?.lastname}</Grid>
                </Grid>
                <Grid container size={12} columnSpacing={1} direction="row" alignItems="center">
                    <Grid size={2}>
                        <Typography variant="body1" sx={{ color: "#0b2343", fontWeight: "bold", fontSize: "18px" }}>
                            {t('users.email')}
                        </Typography>
                    </Grid>
                    <Grid size={3}>{user?.email}</Grid>
                </Grid>
                <Grid container size={12} columnSpacing={1} direction="row" alignItems="center">
                    <Grid size={2}>
                        <Typography variant="body1" sx={{ color: "#0b2343", fontWeight: "bold", fontSize: "18px" }}>
                            {t('users.role')}
                        </Typography>
                    </Grid>
                    <Grid size={3}>{role}</Grid>
                </Grid>
                <Grid container size={12} columnSpacing={1} direction="row" alignItems="center">
                    <Grid size={2}>
                        <Typography variant="body1" sx={{ color: "#0b2343", fontWeight: "bold", fontSize: "18px" }}>
                            {t('tenant.name')}
                        </Typography>
                    </Grid>
                    <Grid size={3}>{tenantName}</Grid>
                </Grid>

                <Grid container size={12} columnSpacing={1} direction="row" alignItems="center">
                    <Grid size={2}>
                        <Typography variant="body1" sx={{ color: "#0b2343", fontWeight: "bold", fontSize: "18px" }}>
                            {t('users.initials')}
                        </Typography>
                    </Grid>
                    <Grid container spacing={3} size={6}>
                        <UserAvatar initialsColor={avatarColor} initialsText={avatarText} onColorChange={(color) => { setAvatarColor(color) }} onTextChange={(text) => { setAvatarText(text) }} editable={true} />
                        <Button variant="contained" style={{textTransform: "none", backgroundColor: "#0b2343", color: "white"}} onClick={()=>{updateProfile()}} >Save</Button>
                    </Grid>
                </Grid>
            </Grid>
        </CardContent>
    )
}


export default UserInformation;
