import React, { useEffect, useState } from "react";
import { Card, CardContent, Typography, Alert, AlertColor } from "@mui/material";
import Grid from "@mui/material/Grid2";
import BusinessIcon from "@mui/icons-material/Business";
import { useLocation, useNavigate } from "react-router-dom";
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';

const ForgotPasswordTenant: React.FC = () => {
    const location = useLocation();
    const [allTenants, setAllTenants] = useState<{ tenantID: string, tenantName: string, email: string }[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const navigate = useNavigate();

    const [message, setMessage] = useState<string>("");
    const [messageColor, setMessageColor] = useState<AlertColor>('success');


    useEffect(() => {
        const permission = localStorage.getItem('tenant-forgot-password-permission');

        if (permission === "true") {
            const newEmail = location.state?.email || "";
            getTenantForgotPassword(newEmail);
        } else {
            localStorage.setItem('tenant-forgot-password-permission', "false");
            navigate('/login');
        }
    }, [])


    const getTenantForgotPassword = async (email: string) => {

        let body = { email }
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/api/forgot-password-tenant`, body);
            setAllTenants(response.data);
            setLoading(false);

        } catch (error) {
            console.log(error);
            setMessage('Something went wrong.')
            setMessageColor('error');
        }

    }

    const backToLoginPage = () => {
        navigate('/login')
        localStorage.setItem('tenant-forgot-password-permission', "false");
    };


    const sendMail = async (email: string, tenantID: string) => {
        let body = {
            email,
            tenantID
        }
        try {

            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/api/forgot-password-mail`, body);
            console.log(response);
            setMessage('Mail sent.')
            setMessageColor('success')


        } catch (error) {
            console.log(error);
            setMessage('Something went wrong.')
            setMessageColor('error');
        }

        setTimeout(() => {
            backToLoginPage();
            setMessage('');
            setMessageColor('success');
        }, 2000)
    }


    return (
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100vh" }}>
            <Card sx={{ width: "50vw", height: "50vh", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)" }}>
                <CardContent
                    sx={{
                        maxHeight: "50vh",
                        overflow: "auto",
                        scrollbarWidth: "thin",
                        scrollbarColor: "#e5e5e5 transparent",
                    }}
                >
                    <Grid container spacing={3}>
                        <Grid size={12} textAlign="center">
                            <Typography variant="h6" sx={{ color: "#0b2343", fontWeight: "bold" }}>
                                Choose your tenant for send mail.
                            </Typography>
                        </Grid>

                        <Grid size={12} textAlign="center">
                            <Typography onClick={() => { backToLoginPage(); }} variant="body1" sx={{ color: "#0b2343", fontWeight: "bold", cursor: "pointer" }}>Back To Login</Typography>
                        </Grid>

                        {message !== "" ? (
                            <Grid size={12}>
                                <Alert style={{width: "30%"}} severity={messageColor}>{message}</Alert>
                            </Grid>
                        ) : null}
                        {allTenants && allTenants.length > 0 ? allTenants.map((tenant) => (
                            <Grid size={4} key={tenant.tenantID}>
                                <Card onClick={() => { sendMail(tenant.email, tenant.tenantID) }} sx={{ display: "flex", alignItems: "center", padding: 1, cursor: "pointer", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)" }}>
                                    <CardContent sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                        <BusinessIcon sx={{ color: "#0b2343", fontSize: 30, marginRight: 1.5 }} />
                                        <Typography variant="body1" sx={{ color: "#0b2343", fontWeight: "bold" }}>{tenant.tenantName}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        )) : (
                            <Grid size={12} container textAlign="center">
                                <Grid size={12} display="flex">
                                    {loading ? (
                                        <Grid size={12} justifyContent="center" alignItems="center">
                                            <CircularProgress sx={{ color: "#0b2343" }} />
                                        </Grid>
                                    ) : (
                                        <Grid container spacing={3} size={12}>
                                            <Grid size={12}>
                                                <Typography variant="body1" sx={{ color: "#0b2343", fontWeight: "bold" }}>Tenant not found</Typography>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    )
}

export default ForgotPasswordTenant;