import React, { useEffect, useState } from "react";
import { TextField, Checkbox, Typography, Button, IconButton, InputAdornment, Alert, AlertColor, Box, Grid } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import EmailPreview from "./EmailPreview";
import { EmailSettings } from "../../models/Settings/EmailSettings";
import { TaskManagementService } from "../../services/api";
import { useTranslation } from "react-i18next";

const EmailConfiguration: React.FC = () => {
    const [host, setHost] = useState("");
    const [user, setUser] = useState("");
    const [password, setPassword] = useState("");
    const [port, setPort] = useState(587);
    const [security, setSecurity] = useState(false);
    const [rejectUnauthorized, setRejectUnauthorized] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [mailPreview, setMailPreview] = useState(false);

    const [message, setMessage] = useState<string>("");
    const [showMessage, setShowMessage] = useState(false);
    const [messageType, setMessageType] = useState<AlertColor>('success');
    const [mySettings, setMySettings] = useState<EmailSettings | null>();

    const { t } = useTranslation('global');

    const getEmailConfiguration = async () => {
        try {
            const response = await TaskManagementService.get("smtp-config", "json");
            const myConf = response as EmailSettings;
            setHost(myConf.host);
            setPort(myConf.port);
            setSecurity(myConf.secure);
            setUser(myConf.auth.user);
            setPassword(myConf.auth.pass);
            setRejectUnauthorized(myConf.tls.rejectUnauthorized);
            setMySettings(myConf);
        } catch {
            setShowMessage(true);
            setMessage(t('settings.email_conf_not_found'));
            setMessageType('error');
        }
    };

    useEffect(() => {
        getEmailConfiguration();
    }, []);

    const handleValueChange = (field: string, newValue: any) => {
        if (field === "host") setHost(newValue);
        else if (field === "user") setUser(newValue);
        else if (field === "password") setPassword(newValue);
        else if (field === "port") setPort(Number(newValue));
        else if (field === "security") setSecurity(newValue);
        else if (field === "rejectUnauthorized") setRejectUnauthorized(newValue);
        else console.log("error");
    };

    const clearValue = () => {
        setHost("");
        setUser("");
        setPassword("");
        setPort(587);
        setSecurity(false);
        setRejectUnauthorized(false);
        setMySettings(null);
    };

    const passwordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const onClose = () => {
        setMailPreview(!mailPreview);
    };

    const saveField = async () => {
        const newSettings: Partial<EmailSettings> = {
            host: host,
            port: port,
            secure: security,
            auth: {
                user: user,
                pass: password
            },
            tls: {
                rejectUnauthorized: rejectUnauthorized
            }
        };

        try {
            if (!mySettings) {
                await TaskManagementService.create(newSettings, "smtp-config");
                setMessage(t('settings.email_conf_created'));
            } else {
                await TaskManagementService.update(mySettings._id, newSettings, "smtp-config");
                setMessage(t('settings.email_conf_saved'));
            }
            setShowMessage(true);
            setMessageType('success');
            getEmailConfiguration();
        } catch {
            setMessage(t('settings.email_conf_not_saved'));
            setShowMessage(true);
            setMessageType('error');
        }

        setTimeout(() => {
            setShowMessage(false);
        }, 3500);
    };

    const deleteEmailSettings = async () => {
        try {
            if (mySettings?._id) {
                await TaskManagementService.delete(mySettings._id, "smtp-config");
                setMessage(t('settings.email_conf_deleted'));
                setShowMessage(true);
                setMessageType('success');
                clearValue();
            }
        } catch {
            setMessage(t('settings.email_conf_not_deleted'));
            setShowMessage(true);
            setMessageType('error');
        }

        setTimeout(() => {
            setShowMessage(false);
        }, 3500);
    };

    const getMessage = () => {
        return (
            <Alert severity={messageType} sx={{ width: '100%', mb: 2 }}>
                {message}
            </Alert>
        );
    };

    return (
        <Box sx={{maxWidth: 800, mx: 'auto', p: 4}}>
            <div>
                <h1 className="text-3xl font-semibold tracking-tight">Email/SMTP Settings</h1>
                <p className="text-muted-foreground mt-2">
                    Configure smtp settings for sending emails
                </p>
            </div>

            {mailPreview && (
                <EmailPreview
                    open={mailPreview}
                    onClose={onClose}
                    mail={user}
                />
            )}

            {showMessage && getMessage()}

            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        value={host}
                        onChange={(e) => handleValueChange("host", e.target.value)}
                        label={t('settings.host')}
                        size="medium"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        value={user}
                        onChange={(e) => handleValueChange("user", e.target.value)}
                        label={t('settings.user')}
                        size="medium"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        value={password}
                        onChange={(e) => handleValueChange("password", e.target.value)}
                        label={t('settings.password')}
                        size="medium"
                        type={showPassword ? "text" : "password"}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={passwordVisibility}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff/> : <Visibility/>}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        value={port}
                        onChange={(e) => handleValueChange("port", e.target.value)}
                        label="Port"
                        size="medium"
                        type="number"
                    />
                </Grid>
                <Grid item xs={12} sm={6} display="flex" alignItems="center">
                    <Checkbox
                        checked={security}
                        onChange={(e) => handleValueChange("security", e.target.checked)}
                    />
                    <Typography variant="body1">
                        {t('settings.security')}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} display="flex" alignItems="center">
                    <Checkbox
                        checked={rejectUnauthorized}
                        onChange={(e) => handleValueChange("rejectUnauthorized", e.target.checked)}
                    />
                    <Typography variant="body1">
                        {t('settings.reject_unauthorized')}
                    </Typography>
                </Grid>
                <Grid item xs={12} display="flex" justifyContent="space-between" sx={{mt: 3}}>
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{backgroundColor: "#0b2343", mr: 2}}
                        disabled={!host || !user || !password}
                        onClick={saveField}
                    >
                        {t('general.save')}
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        sx={{backgroundColor: "#c10101", mr: 2}}
                        disabled={!mySettings}
                        onClick={deleteEmailSettings}
                    >
                        {t('settings.clear')}
                    </Button>
                    <Button
                        variant="contained"
                        color="success"
                        sx={{backgroundColor: "#006400"}}
                        disabled={!host || !user || !password}
                        onClick={onClose}
                    >
                        {t('settings.send_test_mail')}
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};

export default EmailConfiguration;
